import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { deleteUserData } from "../../api/commonApi/deletUserData";

class DeleteProfilePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profileDeleted: false,
            inProgress: false,
        };
    }

    handleClick = () => {
        this.setState({
            inProgress: true,
        });
        deleteUserData().then((res) => {
            console.log(res);
            if (res.status === 200) {
                this.setState({
                    profileDeleted: true,
                });
            } else {
                alert("Something went wrong in deletion");
            }
            this.setState({
                inProgress: false,
            });
            localStorage.clear();
        });
    };

    handleClickRedirect = () => {
        localStorage.clear();
        window.location.reload();
    };

    render() {
        const { profileDeleted, inProgress } = this.state;
        return (
            <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggle}
                className="new-post-modal confirm-logout-modal delete-profile-modal"
            >
                {!profileDeleted ? (
                    <>
                        <ModalHeader
                            toggle={this.props.toggle}
                            className="modal-head d-block"
                        >
                            Are you sure you want to delete your profile?
                        </ModalHeader>
                        <ModalBody className="">
                            <div className="confirm-logout-modal__button-wrapper d-flex">
                                <Button
                                    className="landing-btn confirm-logout-modal__button"
                                    onClick={this.handleClick}
                                    disabled={inProgress}
                                >
                                    Yes
                                </Button>
                                <Button
                                    className="confirm-logout-modal__button confirm-logout-modal__secondary-button"
                                    onClick={() => this.props.toggle()}
                                >
                                    No
                                </Button>
                            </div>
                        </ModalBody>
                    </>
                ) : (
                    <>
                        <ModalHeader
                            toggle={this.props.toggle}
                            className="modal-head modal-head-redirect"
                        >
                            Your profile was successfully deleted
                        </ModalHeader>
                        <ModalBody>
                            <div className="confirm-logout-modal__button-wrapper d-flex">
                                <Button
                                    className="landing-btn confirm-logout-modal__button"
                                    onClick={this.handleClickRedirect}
                                >
                                    Okay
                                </Button>
                            </div>
                        </ModalBody>
                    </>
                )}
            </Modal>
        );
    }
}

export default DeleteProfilePopup;
