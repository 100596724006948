import React, { Component } from "react";
import { Button } from 'reactstrap';
import InfiniteScroll from "react-infinite-scroll-component";
import favourite from '../../commons/images/favourite_icon.svg';
import favourite_select from '../../commons/images/favourite-select.svg';
import UserCard from '../userCard/UserCard';
import FilterModal from '../filterModel/FilterModal';
import ReactTooltip from 'react-tooltip';
import FavouriteModal from '../../containers/FavouriteModal';
import FavouriteTimelineItem from '../../containers/FavouriteTimelineItem';
import UserConversation from '../userConversation/UserConversation';
import NoUsers from '../noUsers/NoUsers';
import cx from 'classnames';
import _ from 'lodash';
import { Link } from "react-router-dom";
// import UserConversation from "../userConversation/UserConversation";

const HEADER_OFFSET_HEIGHT = 155;
const INITIAL_PAGE = 1;

class FilterUsers extends Component {
    constructor(props){
        super(props);
        this.state = {
            modal: false,
            favouriteModal: false,
            messageBlock: false,
            userType: '',
            favoriteUserId: '',
            favoriteUserName: '',
            favUnfavModalLabel: '',
            favoriteUsersIdList: '',
            favoriteUsersIdListPaged: '',
            isFavorite: '',
            userDetail: '',
            page: INITIAL_PAGE,
            perPage: 20,
            hasMore: true,
            totalPages: 0,
            filteredTimeLineFeedList: [],
            unFilteredTimeLineFeedList: [],
            allTotalRecords: 0,
            filteredTotalRecords: 0,
        };
        this.toggle = this.toggle.bind(this);
        this.favouriteModalToggle = this.favouriteModalToggle.bind(this);
        this.toggleMessage = this.toggleMessage.bind(this);
        this.fetchMoreData = this.fetchMoreData.bind(this);

        this.setFilteredTimeLineFeedList = this.setFilteredTimeLineFeedList.bind(this);
        this.setHasMore = this.setHasMore.bind(this);
        this.clearFeedLists = this.clearFeedLists.bind(this);
    }

    componentDidMount() {
        const { getFavoriteList } = this.props;
        this.props.getTimelineFeedListWithFilter({ page: this.state.page, per_page: this.state.perPage, interest_ids: this.props.selectedFilterList}, this.controller.signal);
        this.props.getFavoriteList();
        if (getFavoriteList) {
            this.props.getFavoriteList();
        }
        this.setFavouriteUsersIdList(this.props.favoriteUsersList);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        })
    }

    favouriteModalToggle(favoriteUserId, favoriteUserName, favUnfavModalLabel, isFavorite){
        this.setState({
            favoriteUserId,
            favoriteUserName,
            favUnfavModalLabel,
            isFavorite,
            favouriteModal: !this.state.favouriteModal
        })
    }

    toggleMessage(userDetail, isClose){
        if(!this.state.messageBlock){
            this.setState({
                messageBlock: true,
                userDetail,
            })
        }
        else if(this.state.messageBlock && isClose===true){
            this.setState({
                messageBlock: false,
            })
        }
    }

    controller = new AbortController();

    componentWillReceiveProps(nextProps){
        if (nextProps.userData !== this.props.userData) {
            this.setState({
                userType: nextProps.userData.attributes['user-type']
            })
        }

        let clearCalled = false;

        if (!_.isEqual(_.sortBy(nextProps.selectedFilterList), _.sortBy(this.props.selectedFilterList))) {
            this.props.clearTimelineFeedFilteredList();

            this.controller.abort();
            this.controller = new AbortController()

            this.props.getTimelineFeedListWithFilter({ page: INITIAL_PAGE, per_page: this.state.perPage, interest_ids: nextProps.selectedFilterList }, this.controller.signal);
            clearCalled = true;

            this.clearFeedLists();
            this.setState({ page: INITIAL_PAGE });
        }

        if (nextProps.favoriteUsersList.length !== this.props.favoriteUsersList.length) {
            this.setFavouriteUsersIdList(nextProps.favoriteUsersList);
        }

        if (nextProps.favRefreshRequired && nextProps.favRefreshRequired !== this.props.favRefreshRequired) {
            this.props.getFavoriteList();
            this.props.setfavRefreshRequired();
        }

        if (this.props.filterUsersFor === 'favorite' && nextProps.filterUsersFor === 'favorite') {
            return;
        }

        this.setFilteredTimeLineFeedList(nextProps, clearCalled);
    }

    setFavouriteUsersIdList(favoriteUsersList) {
        let favoriteUsersIdList = favoriteUsersList.map(favoriteUser =>
            favoriteUser.userId
        );
        let favoriteUsersIdListPaged = favoriteUsersIdList.slice(INITIAL_PAGE - 1, this.state.perPage);
        this.setState({
            favoriteUsersIdList,
            favoriteUsersIdListPaged,
        });
        if (this.props.filterUsersFor === 'favorite') {
            this.setState({
                page: INITIAL_PAGE,
                totalPages: Math.ceil(favoriteUsersIdList / this.state.perPage),
            });
        }
    }

    clearFeedLists() {
        this.setState({
            filteredTimeLineFeedList: [],
            unFilteredTimeLineFeedList: [],
        });
    }

    setInitialList(nextProps) {
        this.setState({
            filteredTimeLineFeedList: _.shuffle(nextProps.timelineFeedFilteredList)
        });
    }

    setNextPageOfList(nextProps) {
        this.setState({
            filteredTimeLineFeedList: this.state.filteredTimeLineFeedList.concat(_.shuffle(nextProps.timelineFeedFilteredList))
        });
    }

    clearList() {
        this.setState({
            filteredTimeLineFeedList: []
        });
    }

    setFilteredTimeLineFeedList(nextProps, clearCalled) {

        const isTimelineFeedFilteredListEmpty =
            nextProps.timelineFeedFilteredList &&
            !this.props.timelineFeedFilteredList;
        const isNewTimelineFeedFilteredListCame =
            nextProps.timelineFeedFilteredList &&
            nextProps.timelineFeedFilteredList[0] &&
            this.props.timelineFeedFilteredList &&
            this.props.timelineFeedFilteredList[0] &&
            nextProps.timelineFeedFilteredList[0].id !== this.props.timelineFeedFilteredList[0].id;
        const isNextPropsNeed = this.state.filteredTimeLineFeedList.length === 0 && nextProps.timelineFeedFilteredList.length > 0;

        switch (true) {
            case isTimelineFeedFilteredListEmpty:
                this.setInitialList(nextProps);
                break;
            case isNewTimelineFeedFilteredListCame:
                this.setNextPageOfList(nextProps);
                break;
            case clearCalled && this.state.filteredTimeLineFeedList.length > 0:
                this.clearList();
                break;
            case isNextPropsNeed && !clearCalled:
                this.setNextPageOfList(nextProps);
                break;
            default:
                break;
        }

        this.setHasMore(
            clearCalled ? INITIAL_PAGE : nextProps.timelineFeedFilteredListCurrentPage,
            nextProps.timelineFeedFilteredListTotalPages
        );

        this.setShowingParameters(nextProps);
    }

    setHasMore(page, totalPages) {
        this.setState({
            page,
            hasMore: page < totalPages,
            totalPages
        });
    }

    setShowingParameters(nextProps) {
        const allTotalRecords = nextProps.selectAll ? nextProps.timelineFeedFilteredListLength : this.state.allTotalRecords;
        const filteredTotalRecords = nextProps.selectAll ? this.state.allTotalRecords : nextProps.timelineFeedFilteredListLength;

        this.setState({
            allTotalRecords,
            filteredTotalRecords,
        });
    }

    fetchMoreData() {
        const nextPage = this.state.page + 1;

        if (this.props.filterUsersFor === 'favorite') {

            const favoriteUsersIdListPaged = this.state.favoriteUsersIdListPaged
                .concat(
                    this.state.favoriteUsersIdList
                        .slice((nextPage - 1) * this.state.perPage, nextPage * this.state.perPage)
                );

            this.setState({
                favoriteUsersIdListPaged,
                hasMore: nextPage < this.state.totalPages,
            });

        } else {
            this.props.getTimelineFeedListWithFilter(
                {
                    page: nextPage,
                    per_page: this.state.perPage,
                    interest_ids: this.props.selectedFilterList
                },
                this.controller.signal
            );
        }

        this.setState({ page: nextPage });
    }

    render() {
        const {
            favoriteUsersList,
            filterUsersFor,
            selectAll,
        } = this.props;
        const {
            allTotalRecords,
            filteredTotalRecords,
            favoriteUserId,
            favoriteUserName,
            favUnfavModalLabel,
            favoriteUsersIdList,
            isFavorite,
            userDetail,
            hasMore,
            filteredTimeLineFeedList,
            favoriteUsersIdListPaged,
        } = this.state;

        // let noUserFor;
        // if (userType === 'alumni') {
        //     noUserFor = 'student';
        // } else if (userType === 'student' || userType === 'advisor') {
        //     noUserFor = 'alumni';
        // }

        const filterUserSectionHeight = window.innerHeight - HEADER_OFFSET_HEIGHT;
        const feedList = filteredTimeLineFeedList;
        const dataLength = filterUsersFor === 'favorite' ? favoriteUsersIdListPaged.length : feedList.length;
        const showingX = selectAll ? allTotalRecords : filteredTotalRecords;
        const showingY = allTotalRecords;

        let timelineFeedListArr = feedList.length > 0 ?
            feedList.map((userDetail, index) => (
                <div key={index} className="filter-user-container selected-filter-user">
                    <div className="filter-user-basic-deatils">
                        <UserCard
                            userType={userDetail.attributes["user-type"]}
                            userDetail={userDetail}
                        />
                        <div
                            className="favourite-profile"
                            data-tip data-for='favourites'
                            onClick={() => {this.favouriteModalToggle(
                                userDetail.id,
                                `${userDetail.attributes['first-name']} ${userDetail.attributes['last-name']}`,
                                'You are favoriting',
                                favoriteUsersIdList.includes(userDetail.id)
                            )}}
                        >
                            <img src={favoriteUsersIdList.includes(userDetail.id) ? favourite_select : favourite} alt="no_image"/>
                        </div>
                        <ReactTooltip id='favourites' type="warning" effect="solid" disable={window.screen.width>600?false:true}>
                            <span>Add to Favorites</span>
                        </ReactTooltip>
                    </div>
                    <Button
                        className="message-btn"
                        onClick={()=> {this.toggleMessage(userDetail)}}
                    >Send a Message</Button>
                </div>
            )) : [];

        let favTimelineFeedListArr = favoriteUsersIdListPaged.length > 0 ?
            favoriteUsersIdListPaged.map((userId, index) => (
                <FavouriteTimelineItem
                    key={userId}
                    userId={userId}
                    favoriteUsersIdList={favoriteUsersIdList}
                    favoriteUsersList={favoriteUsersList}
                    toggleUserMessage={this.toggleMessage}
                />
            )) : [];

        favTimelineFeedListArr = favTimelineFeedListArr.filter(arr => { return arr !== '' });
        const arrToRender = filterUsersFor === 'favorite' ? favTimelineFeedListArr : timelineFeedListArr;

        return (
            <React.Fragment>
                <div className="filter-user-wrapper">
                    <div
                        className={
                            cx("pagination-container", "home-page-wrapper", {"pagination-container-only-filter" : arrToRender.length === 0 })
                        }
                    >
                        <>
                    {
                        arrToRender.length > 0 &&
                            <span className='custom-pagination'>
                                {filterUsersFor === 'favorite' ?
                                    favoriteUsersList.length > 0 ? (
                                        `Showing ${favTimelineFeedListArr.length} of ${favoriteUsersIdList.length}`
                                        ) : null
                                    : feedList.length > 0 ? (
                                        `Showing ${showingX} of ${showingY}`
                                    ) : null
                                }
                            </span>
                        }
                        </>
                        <Button className="filter-btn" onClick={this.toggle}>Filters</Button>
                        <>
                    {
                        arrToRender.length > 0 &&
                            <Link to={ filterUsersFor === 'favorite' ? '/homepage' : "/favorites"} className='custom-pagination favorites-link'>
                                {filterUsersFor === 'favorite' ?
                                    favoriteUsersList.length > 0 ? (
                                        `Show All Members`
                                        ) : null
                                    : feedList.length > 0 ? (
                                        `Show Favorites Only`
                                    ) : null
                                }
                            </Link>
                        }
                        </>
                    </div>
                    {arrToRender.length === 0 ?
                        /* <NoUsers for={noUserFor} className="no-users-home" /> */ null :
                        <div
                            style={{ height: filterUserSectionHeight }}
                            className={cx("filter-user-section", {'filter-user-section-only-two' : arrToRender.length <= 2})}
                        >
                            <InfiniteScroll
                                dataLength={dataLength}
                                next={this.fetchMoreData}
                                hasMore={hasMore}
                                loader={<h4>Loading...</h4>}
                                height={filterUserSectionHeight}
                            >
                                {
                                    arrToRender
                                }
                            </InfiniteScroll>
                        </div>
                    }
                </div>
                <FilterModal isOpen={this.state.modal} toggle={this.toggle}/>
                <FavouriteModal
                    isFavorite={isFavorite}
                    favoriteUserId={favoriteUserId}
                    favoriteUserName={favoriteUserName}
                    favUnfavModalLabel={favUnfavModalLabel}
                    isOpen={this.state.favouriteModal}
                    toggle={this.favouriteModalToggle}
                    favouriteModalToggle={this.favouriteModalToggle}
                    favoriteUsersList={favoriteUsersList}
                    getFavoriteList={this.props.getFavoriteList}
                />
                {this.state.messageBlock ?
                    <UserConversation
                        userDetail={userDetail}
                        toggleMessage={this.toggleMessage}
                    /> : null}
            </React.Fragment>
        );
    }
}

export default FilterUsers;