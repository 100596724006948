import React, { Component } from "react";
import { Activity, Title, ReactionToggleIcon } from "react-activity-feed";
import { LIMIT_POST_TEXT } from "../../constants/newsFeed";

class NewsFeedPostCard extends Component {
    handleReactionPress(activityProps) {
        activityProps.onToggleReaction("like", activityProps.activity, {}, {});
    }

    render() {
        const { activityProps } = this.props;
        let postText = activityProps.activity.text;
        let showMore = false;

        if (activityProps.activity.text.length > LIMIT_POST_TEXT) {
            postText = activityProps.activity.text.substring(0, LIMIT_POST_TEXT) + "...";
            showMore = true;
        }

        return (
            <div className="post-card card-wrapper">
                <Activity
                    Header={() => (
                        <div className="post-title-wrapper hidden">
                            <Title>
                                <h2 className="raf-title-text">{activityProps.activity.title}</h2>
                                {/* <div className="raf-title-favorites"></div> */}
                            </Title>
                            <div
                                className={`reaction-icon-wrapper ${
                                    activityProps.activity.own_reactions.like &&
                                    activityProps.activity.own_reactions.like.length
                                        ? "active"
                                        : ""
                                }`}
                            >
                                <ReactionToggleIcon
                                    kind="like"
                                    onPress={() => this.handleReactionPress(activityProps)}
                                />
                            </div>
                        </div>
                    )}
                    Content={() => (
                        <div className="raf-activity__content">
                            <div style={{ padding: "8px 16px" }}>
                                <p>
                                    {postText}
                                    <a
                                        onClick={() => {
                                            this.props.onClickComment(activityProps.activity);
                                        }}
                                        className={`show-more-btn ${showMore ? "" : "hidden"}`}
                                    >
                                        &nbsp;more
                                    </a>
                                </p>
                            </div>
                        </div>
                    )}
                    Footer={() => (
                        <div className="raf-footer">
                            <button
                                onClick={() => {
                                    this.props.onClickComment(activityProps.activity);
                                }}
                                className="raf-comment-button"
                            >
                                <span>
                                    {activityProps.activity.reaction_counts.comment
                                        ? `${activityProps.activity.reaction_counts.comment}`
                                        : `+`}
                                </span>
                            </button>
                        </div>
                    )}
                />
            </div>
        );
    }
}

export default NewsFeedPostCard;
