import { connect } from 'react-redux';
import { getUserData } from '../../api/commonApi/getUserData'
import HomePage from '../../components/homePage/HomePage'
import { createNamespacer } from '../../utils/reducers';
import { USERFORMDATA } from '../../constants/namespacer'

const userFormDataNamespacer = createNamespacer(USERFORMDATA);

const mapStateToProps = (state) => {
    return {
        isTokenValid: state.redirectAfterLogin.isTokenValid,
    }
};

const mapDispatchToProps = (dispatch) => {
    return{
        getUserData: async() => {
            try{
                let response  = await getUserData();
                if(response.status===200){
                    const { attributes } = response.data.data
                
                    const profilePic = attributes['image-url'];
        
                    dispatch({
                        type: userFormDataNamespacer('SET_PROFILE_PIC_URL'),
                        profilePicUrl: profilePic
                    })
                } 
            }
            catch(error){
                console.log(error)   
            }
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);

