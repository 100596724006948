import axiosInstance from '..';

export const getUserDataById = (userId) => {
    const axios = axiosInstance();

    return axios({
        method: 'GET',
        url: `/users/${userId}`,
    })
};
