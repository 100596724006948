import { connect } from 'react-redux';
import { getUserDataById } from '../api/commonApi/getUserDataById';
import Profile from '../components/profile/Profile';
import { createNamespacer } from '../utils/reducers';
import {USERFORMDATA, PROFILEUSER, HOMEPAGE} from '../constants/namespacer';

const userFormDataNamespacer = createNamespacer(USERFORMDATA);
const profileUserNamespacer = createNamespacer(PROFILEUSER);
const homePageNamespacer = createNamespacer(HOMEPAGE);

const mapStateToProps = (state) => {
    return {
        profileUserInfo: state.profile.profileUserInfo,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserDataById: async(id) => {
            try {
                dispatch({
                    type: userFormDataNamespacer('SET_PROFILE_LOADER_VALUE'),
                    profileLoading: true
                });

                let response = await getUserDataById(id);
                if (response.status === 200) {
                    const { attributes } = response.data.data;

                    dispatch({
                        type: profileUserNamespacer('SET_PROFILE_USER_INFO'),
                        profileUserInfo: attributes,
                    });
                }
            }
            catch (error) {
                console.error(error);
            }
            finally {
                dispatch({
                    type: userFormDataNamespacer('SET_PROFILE_LOADER_VALUE'),
                    profileLoading: false
                });
            }
        },
        setActiveScreen: (value) => {
            dispatch({
                type: homePageNamespacer('SET_ACTIVE_SCREEN_VALUE'),
                activeScreen: value
            });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
