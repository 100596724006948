import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, Button, Form, FormGroup, Label } from 'reactstrap';
import { Redirect } from 'react-router-dom'

class ApproveModal extends Component {
    state = { 
        rejectionReason: '',
        redirectTo: null,
    }

    handleRemarks = (event) => {
        this.setState({rejectionReason: event.target.value})
    }

    handleApproveUser = (pendingUserType, pendingUserId) => {
        this.props.setStatusToApprove(pendingUserId);
        this.props.getPendingUserData('pending', pendingUserType)
        this.props.approveModalToggle();
        pendingUserType==='alumni' ? this.setState({redirectTo: '/admin/pendingAlumni'})
        : pendingUserType==='student' ? this.setState({redirectTo: '/admin/pendingStudents'}) : this.setState({redirectTo: '/admin/pendingAdvisors'})
    }

    handleRejectUser = async(pendingUserType, pendingUserId) => {
        const { rejectionReason } = this.state;
        await this.props.setStatusToReject(pendingUserId, rejectionReason);
        this.props.getPendingUserData('pending', pendingUserType)
        this.props.approveModalToggle();
        pendingUserType==='alumni' ? this.setState({redirectTo: '/admin/pendingAlumni'})
        : pendingUserType==='student' ? this.setState({redirectTo: '/admin/pendingStudents'}) : this.setState({redirectTo: '/admin/pendingAdvisors'})
    }

    render() {
        const { isReject, pendingUserId, pendingUserName, approveRejectLabel, isOpen, toggle, pendingUserType } = this.props;
        const { redirectTo } = this.state;
        return (
            <div>
            <Modal isOpen={isOpen} toggle={toggle} className="favourite-modal">
                <ModalHeader toggle={this.props.toggle} className="modal-head">{approveRejectLabel}</ModalHeader>
                <ModalBody className="favourite-details">
                   <span className="favourite-name">{pendingUserName}</span>
                   <Form className="favourite-form">
                        {
                            isReject ? 
                            <>
                                <FormGroup>
                                    <Label 
                                        for="exampleEmail"
                                    >Add Remarks (optional)</Label>
                                    <textarea onChange={this.handleRemarks}/>
                                </FormGroup>
                                <Button onClick={() => this.handleRejectUser(pendingUserType, pendingUserId)}>Confirm</Button>
                            </>
                            : <Button onClick={() => this.handleApproveUser(pendingUserType, pendingUserId)}>Confirm</Button>
                        }
                    </Form>
                </ModalBody>
            </Modal>
            {redirectTo ? <Redirect to={redirectTo} /> : null}
        </div>
        );
    }
}

export default ApproveModal;