import React, { Component } from 'react'
import cx from 'classnames';

export default class ToastErrorMsg extends Component {
    render() {
        const { fieldsMissing, customClass } = this.props;
        return (
            fieldsMissing  ?
            <div className={cx("toastErrorMsg-wrapper", customClass)}>

                <span className="list-inline">
                    Please fill {fieldsMissing} !!
                </span>
            </div>
            : ''
        )
    }
}
