import { createReducer, createNamespacer } from '../../utils/reducers';
import { USERFORMTAB } from '../../constants/namespacer';

const initialState = {
    currentFormTab: '1',
}

const namespacer = createNamespacer(USERFORMTAB);

const handlers = {
    [namespacer('SET_CURRENT_FORM_TAB')]: (state, action) => {
        return{
            ...state,
            currentFormTab: action.currentFormTab,
        }
    }
    
}

const userFormTabReducer = createReducer(initialState, handlers, [USERFORMTAB]);

export default userFormTabReducer;