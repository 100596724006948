import { connect } from 'react-redux';
import { createNamespacer } from '../../utils/reducers';
import { USERFORMTAB, VALIDATIONERROR } from '../../constants/namespacer';
import UserFormTab from '../../components/userForms/userFormTab/UserFormTab';

const userFormTabReducer = createNamespacer(USERFORMTAB);
const toastErrorMsgNamespacer = createNamespacer(VALIDATIONERROR);

const mapStateToProps = (state) => {
    return{
        currentFormTab: state.userFormTab.currentFormTab,
        userType: state.userType.userType,
        provider: state.yourInfoForm.provider
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        setCurrentFormTab: (value) => {
            dispatch({
                type: userFormTabReducer('SET_CURRENT_FORM_TAB'),
                currentFormTab: value
            })
        },
        setFieldsMissing: (value) => {
            dispatch({
                type: toastErrorMsgNamespacer('SET_FIELDMISSING'),
                fieldsMissing: value
            })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserFormTab);