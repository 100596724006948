import React, { Component, Fragment } from "react";
import { Input, Button } from "reactstrap";
import moment from "moment";
import ChatViewProfile from "../../../userChat/ChatViewProfile";

const getTimeOfMsg = (timestamp) => {
    return moment(timestamp).format("LT");
};

const getDateOfMsg = (timestamp) => {
    return moment(timestamp).format("ddd, MMM Do");
};

class MessageBlockChatSection extends Component {
    constructor(props) {
        super(props);
        this.messagesEndRef = React.createRef();
    }

    componentDidMount() {
        this.scrollToBottom();
    }
    componentDidUpdate() {
        this.scrollToBottom();
    }
    scrollToBottom = () => {
        this.messagesEndRef.current.scrollIntoView({ behavior: "auto" });
    };

    handleMessage = (event) => {
        this.props.setSendMessageText(event.target.value);
    };

    handleEnterKey = (event) => {
        if (event.keyCode === 13) {
            this.handleSendMessage();
        }
    };

    handleSendMessage = () => {
        const { tcCurrentChannel, sendMessageText } = this.props;
        const trimmedMsgText = sendMessageText.replace(/^\s+|\s+$/g, "");
        if (trimmedMsgText.length) {
            this.props.sendMessageToChannel(tcCurrentChannel, trimmedMsgText);
        }
        this.props.setSendMessageText("");
    };

    render() {
        const { openProfileSection, messages, twilioToken, secondUserData, sendMessageText } =
            this.props;

        let oldDay;
        let latestDay;

        return (
            <>
                <div className="chat-section-container direct-message-wrapper">
                    <ChatViewProfile
                        userDetail={secondUserData}
                        openProfileSection={openProfileSection}
                    />
                    <div className="chat-section-wrapper message-chat-wrapper">
                        <div className="chat-inner-container">
                            {messages.length > 0
                                ? messages.map((msg, index) => {
                                      oldDay = latestDay;
                                      latestDay = getDateOfMsg(msg.dateUpdated.toString());

                                      let dayToDisplay;
                                      dayToDisplay = oldDay !== latestDay ? latestDay : oldDay;

                                      return (
                                          <Fragment key={index}>
                                              {oldDay !== latestDay ? (
                                                  <p className="date-in-msg-box">
                                                      <span>{dayToDisplay}</span>
                                                  </p>
                                              ) : (
                                                  ""
                                              )}

                                              {secondUserData ? (
                                                  <div
                                                      className={`chat-user
                                            ${
                                                msg.author === secondUserData.attributes.email
                                                    ? "chat-user1"
                                                    : msg.author === twilioToken.identity
                                                    ? "chat-user2"
                                                    : ""
                                            }`}
                                                  >
                                                      {msg.author ===
                                                      secondUserData.attributes.email ? (
                                                          <>
                                                              <span className="chat-text">
                                                                  {msg.body}
                                                              </span>
                                                              <span className="chat-time">
                                                                  {getTimeOfMsg(
                                                                      msg.dateUpdated.toString()
                                                                  )}
                                                              </span>
                                                          </>
                                                      ) : msg.author === twilioToken.identity ? (
                                                          <>
                                                              <span className="chat-time">
                                                                  {getTimeOfMsg(
                                                                      msg.dateUpdated.toString()
                                                                  )}
                                                              </span>
                                                              <span className="chat-text">
                                                                  {msg.body}
                                                              </span>
                                                          </>
                                                      ) : (
                                                          ""
                                                      )}
                                                  </div>
                                              ) : (
                                                  ""
                                              )}
                                          </Fragment>
                                      );
                                  })
                                : ""}
                        </div>
                        <div ref={this.messagesEndRef} />
                    </div>
                </div>
                <div className="chat-footer-wrapper">
                    <div className="chat-footer-content">
                        <div className="input-container">
                            <Input
                                type="textarea"
                                value={sendMessageText}
                                placeholder="Say Something..."
                                onChange={this.handleMessage}
                                onKeyDown={this.handleEnterKey}
                            />
                        </div>
                        <Button className="sent-icon" onClick={this.handleSendMessage}></Button>
                    </div>
                </div>
            </>
        );
    }
}

export default MessageBlockChatSection;
