import axiosInstance from "../..";

export const getUniversityList = (pageNum = 1) => {
    return axiosInstance()({
        method: "GET",
        url: "/universities?per_page=2000",
        params: {
            page: pageNum,
        },
    });
};
