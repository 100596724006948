import React, { Component } from "react";
import { Button } from 'reactstrap';
import DesktopHeader from '../../containers/homePage/DesktopHeader';
import MobileMenu from '../../containers/homePage/MobileMenu';
import UserNewsFeed from '../../containers/UserNewsFeed';
import { Redirect, withRouter } from 'react-router-dom';
import profilePic from "../../commons/images/profile_pic.svg";
import BlockingLoader from "../commonComponents/Loader";
import { needTodayList } from "../../constants/needTodayList";
import split from "lodash/split";
import advisorBanner from "../../commons/images/advisor_ribbon.svg";
import DeleteProfilePopup from './deleteProfilePopup';
import BackIcon from '../../commons/images/back-icon.svg'


class ProfilePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectTo: null,
        };
        this.toggleDeleteProfilePopup = this.toggleDeleteProfilePopup.bind(this);
    }

    toggleDeleteProfilePopup() {
        this.setState({
            modal: !this.state.modal
        })
    }

    handleEditProfile = () => {
        this.setState({ redirectTo: '/editProfile' })
    };
    

    render() {
        const {
            userType,
            profilePicUrl,
            name,
            lastName,
            email,
            linkedinProfileUrl,
            mbaProgram,
            colOrUniv,
            graduationYear,
            currentWorkLocation,
            concept,
            venture,
            needs,
            sectors,
            additionalDetails,
            profilePicBase64,
            userId,
            selectedNotifications,
            isInvestor,
        } = this.props;
        const { redirectTo } = this.state;

        let ventureUpperCase, needsToShow, feasibilityStudyAmount, investorType;

        if (userType === 'alumni') {
            ventureUpperCase = split(venture || "", '_',).join(' ');
            ventureUpperCase = `${ventureUpperCase.charAt(0).toUpperCase()}${ventureUpperCase.substring(1)}`;

            needsToShow = needs?.length ? needs.map(need => {
                const result = need.includes('|') ? null : need;
                return needTodayList.find(item => item && item.attributes && item.attributes.id === result);
            }) : [''];

            if (needs) {
                const feasibilityStudyValue = needs.find(item => item.includes('|') && item.split('|')[0] === 'feasibility_study');
                if (feasibilityStudyValue) {
                    feasibilityStudyAmount = feasibilityStudyValue.split('|')[1];
                }

                // const investorTypeValue = needs.find(item => item.includes('|') && item.split('|')[0] === 'advisor');
                // if (investorTypeValue) {
                //     // investorType = investorTypeValue.split('|')[1];
                // 	investorType = true
                // }
            }
        }

        const stringColOrUniv = typeof(colOrUniv)==='string' ? colOrUniv : '';
        const stringCurrentWorkLocation = typeof(currentWorkLocation)==='string' ? currentWorkLocation : '';
        const labelSetcorOrInterest = 'My Interests';

        let universityName = stringColOrUniv;

        return (
            <React.Fragment>
                {this.props.profileLoading && <BlockingLoader/>}
                <>
                    <DesktopHeader/>
                    <div className="screen-height">
                        <div className="edit-details-container">
                            {/* <button className="confirm-logout-modal__button confirm-logout-modal__secondary-button btn btn-secondary" onClick={this.props.history.goBack}>Back</button> */}
                           <div>
                           <h3 className="title-profile"> <span onClick={this.props.history.goBack}><img src={BackIcon} alt="Back"/></span>Profile</h3>
                           </div>
                            {userId === localStorage.getItem("id") ?
                                <Button
                                    className="edit-btn-profile"
                                    onClick={this.handleEditProfile}
                                >Edit Profile</Button>
                            : null }
                        </div>
                        <div className="profile-wrapper custom-profile-container custom-profile-container-edit-profile">
                            <div className="profile-basic-info">
                                <div className="profile-picture">
                                    <img
                                        src={profilePicBase64 ? profilePicBase64 : profilePic}
                                        alt="no_image"
                                        onError={(e)=>{e.target.onerror = null; e.target.src=profilePic}}
                                    />
                                    {userType === "advisor" || isInvestor ? <img src={advisorBanner} alt="Advisor" className="advisor-banner"/> : null}
                                </div>
                                <span className="profile-name">{name} {lastName}</span>
                                <div className="profile-address">
                                    <span>{universityName} {mbaProgram} {graduationYear}</span>
                                    <span>{stringCurrentWorkLocation}</span>
                                </div>
                                {localStorage.getItem("isAdmin") === true && (
                                    <span className="profile-email">{email}</span>
                                )}
                                {
                                    linkedinProfileUrl ?
                                        <div className="user-contact-details">
                                            <a
                                                href={linkedinProfileUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="linked-link">LinkedIn Profile
                                            </a>
                                        </div> : ''
                                }
                            </div>
                            {
                                (userType === 'alumni') &&
                                <div className="venture-details">
                                    <span className="venture-title">My Venture</span>
                                    {
                                        concept && 
                                        <div className="venture-desc">
                                            <span>My Concept</span>
                                            <p>{concept}</p>
                                        </div>
                                    }
                                    {
                                        venture && 
                                        <div className="venture-desc">
                                            <span>This Venture is Currently</span>
                                            <p>{ventureUpperCase}</p>
                                        </div>
                                    }
                                    {
                                        needs && 
                                        <div className="venture-desc">
                                            <span>What I Need Today</span>
                                            {
                                                needsToShow && needsToShow.map((need, i) => (
                                                    <p key={i}>
                                                        {
                                                            need && need.attributes && need.attributes.name
                                                                ? need.attributes.name
                                                                : ''
                                                        }
                                                    </p>
                                                ))
                                            }
                                            {
                                                feasibilityStudyAmount &&
                                                <p>Feasibility Study: (${feasibilityStudyAmount})</p>
                                            }
                                            {
                                                investorType &&
                                                <p>Advisor</p>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                            <div className={`venture-details ${(userType==='alumni') ? 'sector-details' : ''}`}>
                                <span className="venture-title">{labelSetcorOrInterest}</span>
                                <div className="venture-desc sector-desc">
                                    {
                                        sectors &&
                                        sectors.map((sector, i) => (
                                            <span key={i}>{sector.name}</span>
                                        ))
                                    }
                                </div>
                            </div>
                            {
                                (userType==='student' || userType === 'advisor') ?
                                    <div className="venture-details sector-details">
                                        <span className="venture-title">Additional Details</span>
                                        <div className="venture-desc sector-desc">
                                            <span>{additionalDetails}</span>
                                        </div>
                                    </div> : ''
                            }
                            {userId === localStorage.getItem("id") &&
                                <>
                                    <div className="venture-details notification-details">
                                        <span className="venture-title">My Notifications</span>
                                        <div className="venture-desc sector-desc">
                                        {
                                                selectedNotifications && selectedNotifications.length &&
                                                selectedNotifications.map((item, i) => (
                                                    <span key={i}>{item}</span>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="wrapper-delete-button">
                                        <Button
                                        className="button-delete"
                                        onClick={this.toggleDeleteProfilePopup}
                                        >Delete Profile</Button>
                                    </div>
                                </>
                            }
                        </div>
                        {
                            userId ?
                                <UserNewsFeed userId={userId} /> : ""
                        }
                    </div>
                    <MobileMenu />
                    { redirectTo ? <Redirect to={redirectTo} /> : null }
                </>
                <DeleteProfilePopup isOpen={this.state.modal} toggle={this.toggleDeleteProfilePopup}/>
            </React.Fragment>

        );
    }
}

export default withRouter(ProfilePage);
