import { connect } from "react-redux";
import { createNamespacer } from "../../utils/reducers";
import { SIGNUP, VALIDATIONERROR, LOGIN } from "../../constants/namespacer";
import { signUpToDB } from "../../api/userSignUp";
import { UserSignUp as UserSignUpComponent } from "../../components/userSignUp/UserSignUp";

const signUp = createNamespacer(SIGNUP);
const loginNamespacer = createNamespacer(LOGIN);
const toastErrorMsgNamespacer = createNamespacer(VALIDATIONERROR);

const mapStateToProps = (state) => {
  return {
    userEmail: state.signUp.userEmail,
    userPassword: state.signUp.userPassword,
    confirmPassword: state.signUp.confirmPassword,
    captchaToken: state.signUp.captchaToken,
    redirectUrl: state.signUp.redirectUrl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserEmail: (value) => {
      dispatch({
        type: signUp("SET_USER_EMAIL"),
        userEmail: value ? value.toLowerCase() : "",
      });
    },
    setUserPassword: (value) => {
      dispatch({
        type: signUp("SET_USER_PASSWORD"),
        userPassword: value,
      });
    },
    setUserConfirmPassword: (value) => {
      dispatch({
        type: signUp("SET_USER_CONFIRM_PASSWORD"),
        confirmPassword: value,
      });
    },
    setUserCaptchaToken: (value) => {
      dispatch({
        type: signUp("SET_USER_CAPTCHA_TOKEN"),
        captchaToken: value,
      });
    },
    userSignUp: async (
      userEmail,
      userPassword,
      confirmPassword,
      captchaToken
    ) => {
      try {
        let bodyFormData = new FormData();
        bodyFormData.append("user[email]", userEmail);
        // bodyFormData.append('user[first_name]', '')
        // bodyFormData.append('user[last_name]', '')
        // bodyFormData.append('user[status]', '')
        bodyFormData.append("user[password]", userPassword);
        bodyFormData.append("user[password_confirmation]", confirmPassword);
        bodyFormData.append("g-recaptcha-response", captchaToken);

        let response = await signUpToDB(bodyFormData);
        if (response && response.status === 201) {
          localStorage.setItem("id", response.data.user.id);
          localStorage.setItem("isLogin", "true");
          localStorage.setItem("isAdmin", "false");
          localStorage.setItem("auth_code", response.data.auth_token);

          dispatch({
            type: loginNamespacer("SET_IS_AUTHENTICATED"),
            isTokenValid: "true",
          });
          dispatch({
            type: signUp("SET_REDIRECT_URL"),
            redirectUrl: "/userInfo",
          });
        } else {
          alert("Email already exists");
        }
      } catch (error) {
        console.log(error);
        if (error.response && error.response.data) {
          const errorMessages = error.response.data.errors.map(
            (o) => o["detail"]
          );
          if (errorMessages) {
            alert(errorMessages);
          }
        }
      }
    },
  };
};

export const UserSignUp = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSignUpComponent);
