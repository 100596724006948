import React, { Component } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import YourInfoForm from "../../../containers/userForms/YourInfoForm";
import YourVentureForm from "../../../containers/userForms/YourVentureForm";
import YourSectorForm from "../../../containers/userForms/YourSectorForm";
import AdditionalDetailsTab from "../../../containers/userForms/AdditionalDetailsTab";
import Usertype from "../../../containers/UserType";
import LoginDetailsTab from "../../../containers/userForms/LoginDetails";

class UserFormTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
          showloginDetails: false,
        };
      }
    
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { currentFormTab, userType } = this.props;
        return (
            <React.Fragment>
                <Nav tabs className="userInfo-form-wrapper">
                    <NavItem className={`${currentFormTab > 1 ? "completed-stage" : ""}`}>
                        <NavLink
                            className={classnames({
                                active: currentFormTab === "1",
                            })}
                        />
                    </NavItem>
                    <NavItem className={`${currentFormTab > 2 ? "completed-stage" : ""}`}>
                        <NavLink
                            className={classnames({
                                active: currentFormTab === "2",
                            })}
                        />
                    </NavItem>
                    <NavItem className={`${currentFormTab > 3 ? "completed-stage" : ""}`}>
                        <NavLink
                            className={classnames({
                                active: currentFormTab === "3",
                            })}
                        />
                    </NavItem>
                    <NavItem className={`${currentFormTab > 4 ? "completed-stage" : ""}`}>
                        <NavLink
                            className={classnames({
                                active: currentFormTab === "4",
                            })}
                        />
                    </NavItem>
                </Nav>
                <TabContent activeTab={currentFormTab}>
                    <TabPane tabId="1">
                        <YourInfoForm
                            history={this.props.history}
                            setCurrentFormTab={this.props.setCurrentFormTab}
                            setFieldsMissing={this.props.setFieldsMissing}
                        />
                    </TabPane>
                    <TabPane tabId="2">
                        <YourSectorForm
                            history={this.props.history}
                            setCurrentFormTab={this.props.setCurrentFormTab}
                            setFieldsMissing={this.props.setFieldsMissing}
                            sectorLimit={true}
                        />
                    </TabPane>
                    <TabPane tabId="3">
                        <Usertype
                            history={this.props.history}
                            setCurrentFormTab={this.props.setCurrentFormTab}
                            setFieldsMissing={this.props.setFieldsMissing}
                        />
                    </TabPane>
                    {(userType === "alumni") && (
                        <TabPane tabId="4">
                            {this.state.showloginDetails ? <LoginDetailsTab /> : <YourVentureForm
                                history={this.props.history}
                                setCurrentFormTab={this.props.setCurrentFormTab}
                                setFieldsMissing={this.props.setFieldsMissing}
                                showLinkedInDetails={!!this.props.provider}
                                setShowLinkedInDetails={() => {this.setState({showloginDetails: true})}}
                            />}
                        </TabPane>
                    )}
                    {(userType === "student" || userType === 'advisor') && (
                        <TabPane tabId="4">
                            {this.state.showloginDetails ? <LoginDetailsTab /> : <AdditionalDetailsTab
                                history={this.props.history}
                                setCurrentFormTab={this.props.setCurrentFormTab}
                                setFieldsMissing={this.props.setFieldsMissing}
                                showLinkedInDetails={!!this.props.provider}
                                setShowLinkedInDetails={() => {this.setState({showloginDetails: true})}}
                            />}
                        </TabPane>
                    )}
                </TabContent>
            </React.Fragment>
        );
    }
}

export default UserFormTab;
