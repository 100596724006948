import React, { Component } from "react";
import select_icon from "../../commons/images/select-icon.svg";
import logo from "../../commons/images/logo.svg";
// import chatClient from "../../services/twilioConversation";

class ThankYou extends Component {

    componentDidMount = () => {
        this.props.getUserData();
    }

    logout = () => {
        localStorage.clear();
        this.props.setLogout();
        this.props.history.push(`/login`)
        // chatClient.client.shutdown()
           
    
    }

    render() {
        return (
            <div className="custom-container thank-page">
                <div className="form-wrapper thank-page-wrapper">
                    <img src={logo} alt="Gradshack" className="thanks-logo"/>
                    <img src={select_icon} alt="no_image" />
                    <h1>Thanks for joining! </h1>
                    <span>You can now log in with the email and password you provided.</span>
                    <button onClick={this.logout} className="btn btn-theme login-btn" >Return to Login page</button>
                </div>
            </div>
        );
    }
}

export default ThankYou;