import { connect } from 'react-redux';
import { getSectorList } from '../api/userForms/YourSectorForm/getSectorList'
import Filters from '../components/filters/Filters'
import { createNamespacer } from '../utils/reducers';
import { USERFORMDATA, HOMEPAGE, FILTERS } from '../constants/namespacer'

const userFormDataNamespacer = createNamespacer(USERFORMDATA);
const filtersNamespacer = createNamespacer(HOMEPAGE);
const filterUsersNamespacer = createNamespacer(FILTERS);

const mapStateToProps = (state) => {
    return {
        sectorList: state.yourSectorForm.sectorList,
        selectedFilterList: state.filters.selectedFilterList
    }
};

const mapDispatchToProps = (dispatch) => {
    return{
        setSelectedFilterList: (filteredSectorList) => {
            let selectedFilterList = [];
            selectedFilterList = filteredSectorList
                                .filter((sector) => sector.isChecked)
                                .map(sector => sector.attributes.id);
            dispatch({
                type: filtersNamespacer('SET_SELECTED_FILTER_LIST'),
                selectedFilterList: selectedFilterList
            })
        },
        setSelectAllFilters: (selectAll) => {
            dispatch({
                type: filterUsersNamespacer('SET_SELECT_ALL'),
                selectAll: selectAll,
            });
        },
        getSectorList: async() => {
            try{
                let response = await getSectorList();
                if(response.status===200){
                    dispatch({
                        type: userFormDataNamespacer('SET_SECTOR_LIST'),
                        payload: response.data.data
                    })
                    let sectorLists = response.data.data
                    sectorLists = sectorLists.map(sector => sector.attributes.id);
                    dispatch({
                        type: filtersNamespacer('SET_SELECTED_FILTER_LIST'),
                        selectedFilterList: sectorLists
                    })
                }
            }
            catch(error){
                console.log(error)
            }
        },  
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);

