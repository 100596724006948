import {connect} from 'react-redux'
import {createNamespacer} from '../../utils/reducers'
import {SIGNUP, VALIDATIONERROR, LOGIN} from '../../constants/namespacer'
import {signUpToDB} from '../../api/userSignUp'
import {forgotPassword} from '../../api/forgotPassword'
import {ForgotPassword as ForgotPasswordComponent} from '../../components/forgotPassword/ForgotPassword'

const signUp = createNamespacer(SIGNUP)

const mapStateToProps = (state) => {
  return {
    userEmail: state.signUp.userEmail,
    // userPassword: state.signUp.userPassword,
    // confirmPassword: state.signUp.confirmPassword,
	//   captchaToken: state.signUp.captchaToken,
    // redirectUrl: state.signUp.redirectUrl,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUserEmail: (value) => {
      dispatch({
        type: signUp('SET_USER_EMAIL'),
        userEmail: value ? value.toLowerCase() : "",
      })
    },
    emailResetLink: async (userEmail) => {
      try {
        let formData = {}
        formData["user"] = { "email": userEmail}

        let response = await forgotPassword(formData)
        if (response && response.status === 200) {
         console.log('email sent')
         return true;
        }
      }catch(error) {
        alert("Email not found")
        console.log(error)
        return false;
      }
    },
  }
}

export const ForgotPassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordComponent)
