import { createReducer, createNamespacer } from '../../utils/reducers';
import { USERFORMDATA } from '../../constants/namespacer';

const initialState = {
    sectors: [],
    oldSectorsForEditProfile: [],
    sectorList: '',
    additionalDetails: ''
}

const namespacer = createNamespacer(USERFORMDATA);

const handlers = {
    [namespacer('SET_SECTORS_VALUE')]: (state, action) => {
        return{
            ...state,
            sectors: action.sectors,
        }
    },
    [namespacer('SET_SECTOR_LIST')]: (state, action) => {
        return{
            ...state,
            sectorList: action.payload,
        }
    },
    [namespacer('SET_ADDITIONAL_DETAILS')]: (state, action) => {
        return{
            ...state,
            additionalDetails: action.additionalDetails,
        }
    },
    [namespacer('SET_OLD_SECTORS_VALUE')]: (state, action) => {
        return{
            ...state,
            oldSectorsForEditProfile: action.oldSectorsForEditProfile,
        }
    },
}

const userFormDataReducer = createReducer(initialState, handlers, [USERFORMDATA]);

export default userFormDataReducer;