import { useEffect } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { PAGES_VIEWED } from "../../../constants/googleAnalyticsConfig";

function sendPageView(location) {
    if (PAGES_VIEWED.includes(location.pathname) && window.hasOwnProperty('gtag')) {
        window.gtag('event', 'page_view', {
            page_path: location.pathname,
        });
    }
}

function GAListener({children, trackingId, history}) {
    useEffect(() => {
        sendPageView(history.location);
        return history.listen(sendPageView);
    }, [history]);

    return children;
}

GAListener.propTypes = {
    children: PropTypes.node,
    trackingId: PropTypes.string,
    history: PropTypes.shape({
        listen: PropTypes.func,
    }),
};

export default withRouter(GAListener);