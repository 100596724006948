import { createReducer, createNamespacer } from '../utils/reducers';
import { NEWSIGNUPUSERCARD } from '../constants/namespacer';

const initialState = {
    users: {},
};

const newsSignUpUserCardNamespacer = createNamespacer(NEWSIGNUPUSERCARD);

const handlers = {
    [newsSignUpUserCardNamespacer('GET_NEW_SIGN_UP_USER')]: (state, action) => {
        const users = state.users;
        users[action.user.id] = action.user;
        return {
            ...state,
            users: { ...users }
        }
    },
};

const newsSignUpUserCardReducer = createReducer(initialState, handlers, [NEWSIGNUPUSERCARD]);

export default newsSignUpUserCardReducer;
