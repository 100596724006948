import React, { Component } from "react";
import profilePic from "../../../commons/images/profile_pic.svg";

class ChatHeader extends Component {
    state = {  }


    render() {
        const { userDetail, toggleMessage } = this.props;
        return (
            <div className="chat-header-wrapper">
                <div className="chat-profile-block">
                    <div className="chat-profile-pic">
                        <img src={userDetail.attributes['image-url'] ? userDetail.attributes['image-url'] : profilePic} alt="User"/>
                    </div>
                    <div className="chat-profile-info">
                        <span className="chat-profile-name">{`${userDetail.attributes['first-name']} ${userDetail.attributes['last-name']}`}</span>
                        <span onClick={this.props.viewProfile} className="chat-linkedIn">
                            {this.props.openProfileSection?'View Chat':'View Profile'}
                        </span>
                    </div>
                </div>
                <div className="chat-close-details">
                    <span 
                        onClick={this.props.minimizeChat} 
                        className={'minimize-chat'+(this.props.isMinimizeChat?' maximize-chat':'')}
                    ></span>
                    <span 
                        className="chat-close"
                        onClick={() => {toggleMessage(userDetail, true)}}
                    ></span>
                </div>
            </div>
        );
    }
}

export default ChatHeader;