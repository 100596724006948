import { connect } from 'react-redux';
import { getSectorList } from '../../api/userForms/YourSectorForm/getSectorList'
import { createNamespacer } from '../../utils/reducers';
import { USERFORMDATA } from '../../constants/namespacer';
import EditSectorForm from '../../components/editProfile/editSector/EditSectorForm';

const userFormDataNamespacer = createNamespacer(USERFORMDATA);

const maptStateToProps = (state) => {
    return{
        sectors: state.yourSectorForm.sectors,
        sectorList: state.yourSectorForm.sectorList,
        additionalDetails: state.yourSectorForm.additionalDetails,
        oldSectorsForEditProfile: state.yourSectorForm.oldSectorsForEditProfile,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        setSectors: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_SECTORS_VALUE'),
                sectors: value,
            })
        },
        setAdditionalDetails: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_ADDITIONAL_DETAILS'),
                additionalDetails: value
            })
        },
        getSectorList: async() => {
            try{
                let response = await getSectorList();
                if(response.status===200){
                    dispatch({
                        type: userFormDataNamespacer('SET_SECTOR_LIST'),
                        payload: response.data.data
                    })
                }
            }
            catch(error){
                console.log(error)
            }
        },   
    }
}

export default connect(maptStateToProps, mapDispatchToProps)(EditSectorForm);