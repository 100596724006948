import React, { Component } from "react";
import UserFormTab from '../../containers/userForms/UserFormTab';

class UserFormMain extends Component {

    render() {
        return (
            <React.Fragment>
                <div className="custom-container">
                    <UserFormTab history={this.props.history} />
                </div>
            </React.Fragment>
        );
    }
}

export default UserFormMain;
