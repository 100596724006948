import React from "react";
import { FormGroup, Input } from "reactstrap";
function FormGroupinputBox(props) {
  return (
    <React.Fragment>
      <FormGroup>
        <Input
          type={props.type}
          value={props.value}
          placeholder={props.placeholder}
          className={`form_control ${props.isValid ? "" : "borderRed"}`}
          onChange={props.onChange}
        />
      </FormGroup>
      {!props.isValid ? 
      <div className={props.isValid ? "success" : "error"}>
        {props.invalidMessage}
      </div>
      : null}
    </React.Fragment>
  );
}

export default FormGroupinputBox;
