import { connect } from 'react-redux';
import { createNamespacer } from '../../utils/reducers';
import { ADMINHOMEPAGE, USERFORMDATA, PAGINATION } from '../../constants/namespacer';
import { getUserListForAdminApi, getFilterExistingUserListApi } from '../../api/adminHomePage'
import { getUniversityList } from '../../api/userForms/YourInfoForm/getUniversityList'
import { getUniversityListPrediction } from '../../api/userForms/YourInfoForm/getUniversityListPrediction'
import AdminExistingUsers from '../../components/adminExistingUsers/AdminExistingUsers'
import store from '../../store';

const adminHomepageNamespacer = createNamespacer(ADMINHOMEPAGE);
const userFormDataNamespacer = createNamespacer(USERFORMDATA);
const paginationNamespacer = createNamespacer(PAGINATION);

const mapStateToProps = (state) => {
    return {
        existingUserList: state.adminExistingUsers.existingUserList,
        existingUserType: state.adminExistingUsers.existingUserType,
        universityList: state.yourInfoForm.universityList,
        colOrUniv: state.yourInfoForm.colOrUniv,
        filteredExistingUserList: state.adminExistingUsers.filteredExistingUserList,
        totalRecords: state.pagination.totalRecords,
        recordsPerPage: state.pagination.recordsPerPage,
        showLoader: state.adminExistingUsers.showLoader,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        setExistingUserType: async(value) => {
            dispatch({
                type: adminHomepageNamespacer('SET_EXISTING_USER_TYPE'),
                existingUserType: value,
            })
        },
        getExistingUserList: async(userStatus, userType, page) => {
            try{
                let response = await getUserListForAdminApi(userStatus, userType, page);
                if(response.status===200){
                    dispatch({
                        type: adminHomepageNamespacer('SET_EXISTING_USER_LIST'),
                        existingUserList: response.data.data,
                        showLoader: true,
                    })
                    dispatch({
                        type: adminHomepageNamespacer('SET_FILTERED_EXISTING_USER_LIST'),
                        filteredExistingUserList: response.data.data,
                        showLoader: true
                    })
                    dispatch({
                        type: paginationNamespacer('SET_TOTAL_RECORDS'),
                        totalRecords: response.data.meta['total-records']
                    })
                }
            }
            catch(error){
                console.log(error) 
            }
        },
        setColOrUniv: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_COL_OR_UNIV_VALUE'),
                colOrUniv: value,
            })
        },
        getUniversityList: async() => {
            try{
                let response = await getUniversityList();
                if(response.status===200){
                    dispatch({
                        type: userFormDataNamespacer('SET_UNIVERSITY_LIST'),
                        payload: response.data.data
                    })
                } 
            }
            catch(error){
                console.log(error);   
            }
        },
        getUniversityListPrediction: async(param) => {
            try{
                let response = await getUniversityListPrediction(param);
                if(response.status===200){
                    dispatch({
                        type: userFormDataNamespacer('SET_UNIVERSITY_LIST'),
                        payload: response.data.data
                    })
                }
            }
            catch(error){
                console.log(error);
            }
        },
        getFilterExistingUserList: async(colOrUniv, page) => {
            const userStatus = 'approved';
            const userType = store.getState().adminExistingUsers.existingUserType;
            try{
                let response = await getFilterExistingUserListApi(userStatus, userType, colOrUniv, page);
                if(response.status===200){
                    dispatch({
                        type: adminHomepageNamespacer('SET_FILTERED_EXISTING_USER_LIST'),
                        filteredExistingUserList: response.data.data,
                        showLoader: true
                    })
                    dispatch({
                        type: paginationNamespacer('SET_TOTAL_RECORDS'),
                        totalRecords: response.data.meta['total-records']
                    })
                }
            }
            catch(error){
                console.log(error)  
            }
        },
        clearFilteredExistingUserList: async() => {
            try{
                const { existingUserType } = store.getState().adminExistingUsers;
                let response = await getUserListForAdminApi('approved', existingUserType);
                if(response.status===200){
                    dispatch({
                        type: adminHomepageNamespacer('SET_EXISTING_USER_LIST'),
                        existingUserList: response.data.data
                    })
                    dispatch({
                        type: adminHomepageNamespacer('SET_FILTERED_EXISTING_USER_LIST'),
                        filteredExistingUserList: response.data.data,
                        showLoader: true
                    })
                    dispatch({
                        type: userFormDataNamespacer('SET_COL_OR_UNIV_VALUE'),
                        colOrUniv: '',
                    })
                    dispatch({
                        type: paginationNamespacer('SET_TOTAL_RECORDS'),
                        totalRecords: response.data.meta['total-records']
                    })
                }
            }
            catch(error){
                console.log(error) 
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminExistingUsers);

