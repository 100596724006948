import React from 'react';
import logo from "../../commons/images/logo.svg";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const PrivacyPolicy = (props) => {
    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} className="aboutUs-modal privacy-policy-modal">
            <ModalHeader toggle={props.toggle} className="modal-head">
                <img src={logo} alt="Gradshack" />
            </ModalHeader>
            <ModalBody className="about-details privacy-policy-details">
                <h4>Privacy Policy</h4>
                <div className="content-in">
                    <ol>
                        <li>
                            <h5>In General</h5>
                            <div>
                                <p>Collaborative Labs LLC, a New York limited liability company (“we” or “us”), values our customers and respects their privacy. This privacy policy (“Privacy Policy”) governs our collection and use of data in connection with our website (the “Site”), our applications, and any services we offer (collectively, the “Services”). Terms capitalized but not defined in this Privacy Policy shall have the meaning ascribed to them in our Terms of Use.</p>
                                <p>This Privacy Policy sets forth the nature, purpose, use and sharing of any information that identifies or can be used to identify you, the visitors to the Site, and users of our Services (“Personal Information”). This Privacy Policy does not govern privacy practices associated with offline activities, websites other than ours, or practices of third parties that we do not own or control, such as our users, customers, partners, third party service providers featured on the Site or in the Services or advertisers. If you have objections to the Privacy Policy, you should immediately discontinue use of the Services and follow the procedure described in the section entitled “Opting-Out or Updating Your Personal Information and Privacy Preferences.”</p>
                                <p>The Site and the Services are intended for adult audiences only, and we do not knowingly collect any Personal Information from anyone under 13 years of age. If you are under age 13, please do not provide any Personal Information about yourself to us. If we learn that we have collected Personal Information from a child under age 13, we will delete that information as quickly as possible. If you believe that we might have any Personal Information from a child under the age of 13, please contact us immediately.</p>
                            </div>
                        </li>
                        <li>
                            <h5>Changes in Privacy Policy</h5>
                            <div>
                                <p>Any Personal Information that we collect and maintain will be subject to this Privacy Policy, as amended from time to time. We may change the Privacy Policy at our sole discretion. However, if at any time we make material changes to the Privacy Policy, we will notify you of those changes on the Site and through the publishing of an updated Privacy Policy. If as the result of such changes you want to alter the ways in which we are allowed to use your Personal Information, you can do so by following the procedure described in the section entitled “Opting-Out or Updating Your Personal Information and Privacy Preferences.”</p>
                            </div>
                        </li>
                        <li>
                            <h5>Collection of Information</h5>
                            <ul>
                                <li>We collect the Personal Information you give us when you register with the Site or use the Services, including name, address, and other Personal Information requested as part of your use of the Site or the Services, which could include credit card or bank information, or the information you choose to give us when you use the Services.</li>
                                <li>We may use automated tools to collect certain kinds of non-personal information from you when you use the Site or the Services, including device, browser type, operating system, CPU speed, referring or exit webpages, click patterns, Session ID, and your computer’s IP address.</li>
                                <li>We may on occasion supplement or correct the Personal Information you submit to us and your IP address with information in our other databases or information from third-party sources in order to reduce errors in our database, authenticate our users, prevent fraud and prevent abuse of our Site, as well as to provide more consistent, relevant experiences to our users.</li>
                                <li>We may collect general information about use of the Site and the Services, such as what pages visitors access, the number of visits, average time spent on the Site and other similar factors. This information is generally collected in aggregate form, without identifying any user individually, although IP addresses and Session ID in relation to downloads may be tracked as part of our fraud prevention efforts.</li>
                            </ul>
                            <div>
                                <p>You agree not to submit any sensitive personally-identifiable information to the Services.</p>
                            </div>
                        </li>
                        <li>
                            <h5>Location-Based Services</h5>
                            <div>
                                <p>Some Services may contain functionality that can enable us to access your location and tailor your experience based on your location (“<strong><em>Location-based Services</em></strong>”). To use Location-based Services, you must enable certain features of your mobile device (e.g., GPS, WiFi, or Bluetooth) to allow us to identify your location. To the extent your location is collected through Wi-Fi or Bluetooth sensors, such sensors, and the associated data services, may be provided by a third party that may access such information for the purpose of providing such data services to us.  You agree to such access by such third parties. You may enable or disable such Location-based Services at any time via a menu within the Services. If you choose to disable any Location-based Services on your device and/or opt out of any Location-based Services through the menu, you will not be able to utilize certain features of the Services. By enabling Location-based Services on your device, you agree and acknowledge that (i) device data we collect from you is directly relevant to your use of the Services, (ii) we may provide Location-based Services related to and based on your then-current location, and (iii) we may use any such information collected in connection with the provision of Location-based Services in providing the Services. PLEASE NOTE THAT LOCATION DATA MAY NOT ALWAYS BE ACCURATE, AND WE DISCLAIM ANY AND ALL WARRANTIES RELATED TO LOCATION-BASED SERVICES.</p>
                                <p><strong><em>We may use your location to provide you with advertisements, offers, and other promotional content as part of the Services.</em></strong></p>
                            </div>
                        </li>
                        <li>
                            <h5>How Your Information is Used</h5>
                            <p>Except as provided herein, we collect and use your personal and non-personal information for internal purposes only or for gathering aggregate and anonymized data. We may use your information in the following ways:</p>
                            <ul>
                                <li>To conduct routine business operations such as billing, identification, authentication, contact purposes and general research. As part of our routine business operations, we may transfer your Personal Information to certain third parties with whom we contract for the limited purpose of providing services such as web hosting, email, and credit card processing. These service providers are obligated to protect your information, and they do not use your Personal Information for their own commercial purposes.</li>
                                <li>To employ internal marketing techniques such as tracking customer preferences to provide a customized Site experience and communicating with you about Services, special offers, and other services, including, but not limited to, periodic notifications and newsletters.</li>
                                <li>To enhance the Services.</li>
                                <li>To gather generic product, location and other contributed information to make reports based on aggregate anonymized data, which we may use internally, or which we may sell to others.</li>
                                <li>To comply with law, regulation, or litigation, court or governmental orders, and as necessary for purposes of national security, law enforcement, or other issues of public importance.</li>
                            </ul>
                        </li>
                        <li>
                            <h5>Tracking, Use of Cookies, Web Beacons and Similar Devices</h5>
                            <p>In order to improve our Services and provide more convenient, relevant experiences to our customers, we and our agents may use “cookies,” “web beacons,” and similar devices to track your activities. A cookie is a small amount of data that is transferred to your browser by a web server and can only be read by the server that gave it to you. It functions as your identification card and enables us to record your passwords and preferences. It cannot be executed as code or deliver viruses. A web beacon is a small transparent .gif image that is embedded in an HTML page or email used to track when the page or email has been viewed. Most browsers are initially set to accept cookies, and most services that include similar devices are typically initially activated to collect data. You can set your browser to notify you when you receive a cookie, giving you the chance to decide whether or not to accept it. We do not support browsers’ Do Not Track features. You can enable or disable Do Not Track by visiting the preferences or settings page of your browser.</p>
                        </li>
                        <li>
                            <h5>Protecting Your Information</h5>
                            <div>
                                <p>We use a variety of security measures, including SSL and encryption, as well as authentication processes via LinkedIn (as applicable), to help protect your information. We do not retain your Personal Information for longer than is necessary for our business purposes. When we no longer need your Personal Information, we dispose of it safely. We may retain a copy as required by applicable law, our internal storage policy, or for compliance purposes.</p>
                                <p>Our security measures include standard international data protection measures as well as those described in the EU GDPR implementing regulations. These measures, may also include periodic system-wide password resets with high password strength requirements. We strongly discourage password sharing. Due to the nature of the information and related technology, we cannot be held responsible if you share your own login credentials with others. If we learn of a security breach, then we will immediately reset your account password and attempt to notify you as per GDPR guidelines to take appropriate steps.</p>
                            </div>
                        </li>
                        <li>
                            <h5>Data Processing</h5>
                            <p>In submitting any third-party personal data within the context of the Services (“Third-Party Data”), the parties agree that you shall be the Data Controller and you appoint us as a Data Processor of such data, for the purpose of providing the Services. To the extent that we are a “Data Processor” for you in respect of such Third-Party Data, we will:</p>
                            <ol>
                                <li>Implement appropriate technical and organizational measures to safeguard the Third-Party Data against any unauthorized or unlawful access, loss, destruction, theft, use or disclosure. We implement basic level security in accordance with United States law. If higher levels of security are required, please contact us.</li>
                                <li>Limit access to the Third-Party Data only to those employees who need to know it to enable the Processor to perform the Services, and shall take appropriate steps to ensure the reliability of those of our employees or subcontractors who have access to such Third-Party Data.</li>
                                <li>Only process the personal data as specified by this Privacy Policy and in accordance with your instructions, and will not use the Third-Party Data for any purposes other than those related to the performance of the Services or pursuant to your written instructions.</li>
                                <li>Upon the expiry or termination of this Privacy Policy, or upon your request, cease any and all use of the Third-Party Data and destroy or return it to you, provided that we may retain a copy as required by applicable law, our internal storage policy, or for compliance purposes.</li>
                                <li>Not disclose the Third-Party Data to any third-party without your prior written consent. Such consent is granted on, for example, your instruction to share a dashboard with third parties (public or within a restricted group).</li>
                            </ol>
                            <div>
                                <p>We as Data Processor may provide access to a subcontractor processor to any such Third-Party Data if we reasonably consider such access and processing necessary to the performance of the Services. In the event of such access and before the access takes place, we will ensure that an agreement with the third-party is in place which is sufficient to require it to treat personal data in accordance with the applicable provisions of this Agreement and applicable law. In particular, you authorize us to subcontract the provision of technology and commercial services to members of our corporate group.</p>
                                <p>You represent and warrant that you have all the appropriate consents from data subjects whose personal data are submitted to us in the course of the provision of the Services.</p>
                            </div>
                        </li>
                        <li>
                            <h5>Disclosure to Third Parties</h5>
                            <p>We will not share your Personal Information with third parties without your express consent, except in the limited circumstances described below:</p>
                            <ul>
                                <li>We may share your Personal Information with third party service providers, including but not limited to our attorneys, accountants, affiliates and advisors, for the limited purposes described in the section entitled “How Your Information is Used.”</li>
                                <li>We will share your Personal Information when required by law, regulation, or litigation, court or governmental order, and as necessary for purposes of national security, law enforcement, or other issues of public importance.</li>
                                <li>If we sell assets or are acquired by or merged with another entity, we may provide such entity customer information without obtaining your further consent. We may provide notice of such asset sales, acquisitions, or mergers on the Site.</li>
                            </ul>
                        </li>
                        <li>
                            <h5>Your Choices</h5>
                            <div>
                                <p>If at any time you are uncomfortable with our use of your Personal Information for internal marketing purposes and for the purpose of creating aggregate reports, you may opt-out by email (contact@gradshack.com) as described in “Opting-Out or Updating Your Personal Information and Privacy Preferences.”  Note that if you opt not to provide us with certain mandatory information, then our Products and services may be unavailable to you.</p>
                                <p>You may similarly opt out of receiving communications from us, although we may find it necessary to continue to communicate with you regarding your use of the Services.</p>
                                <p>For our websites, you may set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies, please note that some parts of this Site may then be inaccessible or not function properly.</p>
                            </div>
                        </li>
                        <li>
                            <h5>Governing Law</h5>
                            <p>The terms of this Privacy Policy are governed by and in accordance with the laws of the state of New York, and, where applicable, the laws of the countries where our servers are located. By providing us with any Personal Information, you consent to the application of United States law and, where applicable, New York law, and to the use and disclosure of your information in accordance with the terms of this Privacy Policy. You submit to the exclusive jurisdiction of the state and federal courts located in the city, state and county of New York.</p>
                        </li>
                        <li>
                            <h5>Opting-Out or Updating Your Personal Information and Privacy Preferences</h5>
                            <div>
                                <p>You have the right to access, correct and delete inaccuracies in your Personal Information and privacy preferences on the Services at any time by signing into your account and editing your Personal Information in your account profile. You may also do so by contacting us via telephone, postal mail, or email.</p>
                                <p>If at any time you wish to withdraw your consent to our processing of your data or opt out of our use of your information for internal marketing purposes or for compiling aggregate risk reports, you may do so by sending a written notice to <a href="mailto:contact@gradshack.com">contact@gradshack.com</a>. Note that your withdrawal of consent may prevent us from providing the Services to you.</p>
                            </div>
                        </li>
                        <li>
                            <h5>Privacy Complaints</h5>
                            <div>
                                <p>We take our users’ privacy concerns seriously. If you believe that we have not complied with this Privacy Policy with respect to your Personal Information, you may write to us at <a href="mailto:contact@gradshack.com">contact@gradshack.com</a>.</p>
                                <p>Also, please note that we are not responsible for the content or privacy practices of third-party websites or integrated products to which the Site or the Services may link. You should review the privacy policies of such sites before using the associated sites.</p>
                                <p>The Services are intended for users in the United States and those not governed by privacy policies of other countries. Users outside the United States are advised not to disclose Personal Information to us.</p>
                            </div>
                        </li>
                    </ol>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default PrivacyPolicy;