import React from 'react';
import { Spinner } from 'reactstrap';

function BlockingLoader(props){
    return(
        <div 
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'rgba(255,255,255,0.5)',
                position: 'absolute',
                zIndex: '50',
            }}
        >
           <Spinner style={{ width: '3rem', height: '3rem', color:"#f8434e" }} />
        </div>
    )
}

export default BlockingLoader;