import { createReducer, createNamespacer } from "../../utils/reducers";
import { ADDITIONALDETAILSTAB } from "../../constants/namespacer";

const initialState = {
  additionalDetails: "",
};

const namespacer = createNamespacer(ADDITIONALDETAILSTAB);

const handlers = {
  [namespacer("SET_ADDITIONALDETAILSTAB_VALUE")]: (state, action) => {
    return {
      ...state,
      additionalDetails: action.additionalDetails,
    };
  },
};

const additionalDetailsTabReducer = createReducer(initialState, handlers, [
  ADDITIONALDETAILSTAB,
]);

export default additionalDetailsTabReducer;
