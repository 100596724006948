import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

class SchoolsModal extends Component {
  state = {
    email: "",
    subscribeModal: false,
  };

  componentDidMount() {
    this.props.getSchoolList();
  }

  fetchData = () => {
    this.props.getSchoolList();
  };

  handleEmail = (evt) => {
    this.setState({ email: evt.target.value });
  };

  handleSubscribe = () => {
    let reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(this.state.email) === false) {
      alert("Invalid Email Address");
    } else {
      this.props.setEmailSubscription(this.state.email);
      this.subscribeModalToggle();
    }
  };

  subscribeModalToggle = () => {
    this.setState({
      subscribeModal: !this.state.subscribeModal,
    });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className="aboutUs-modal schools-modal"
      >
        <ModalHeader toggle={this.props.toggle} className="modal-head">
          <h3>Who we are</h3>
        </ModalHeader>

        <ModalBody className="about-details" id="scrollable-div">
          <div className="faq-wrapper screen-height">
            <div className="custom-profile-container faq-container">
              <div className="faq-content">
                <h4 className="faq-subtitle">Who runs GradShack?</h4>
                <p className="faq-desc">
                  GradShack is managed by the principals of Collaborative
                  Capital, LLC, a New York based investment firm. The activities
                  of GradShack are separate from those of Collaborative, though
                  Collaborative is not precluded from investing in ventures
                  arising from the GradShack platform.
                </p>
              </div>
              <div className="faq-content">
                <h4 className="faq-subtitle">
                  Are there any costs I should be aware of?
                </h4>
                <p className="faq-desc">
                  GradShack does not charge fees or request ownership.
                </p>
              </div>
              <div className="faq-content">
                <h4 className="faq-subtitle">What’s in it for GradShack?</h4>
                <p className="faq-desc">
                  Some combination of intellectual stimulation, network-building
                  and perhaps investment opportunity. It is difficult to predict
                  with much precision, but if we succeed in establishing an
                  ecosystem that unearths entrepreneurial talent, fosters
                  intergenerational collaboration, enhances alumni engagement
                  and supports transformational ventures, we are confident that
                  good things will come.
                </p>
              </div>
              <div className="faq-content">
                <h4 className="faq-subtitle">
                  Is GradShack officially endorsed by the schools?
                </h4>
                <p className="faq-desc">
                  We have not sought any official partnerships with schools,
                  though we enjoy collaborating with them. GradShack is an
                  independent platform.
                </p>
              </div>
            </div>
          </div>
        </ModalBody>

        {/* <ModalFooter>
					<p className="footer-text">
						Join our email list to receive updates on which schools we're adding next!
					</p>
					<Form>
						<FormGroup>
							<Label>Enter your Email </Label>
							<div className="modal-footer-email">
								<Input
									type="email"
									name="email"
									placeholder="user@example.com"
									onChange={this.handleEmail}
								/>
								<Button onClick={this.handleSubscribe}>Subscribe</Button>
							</div>
						</FormGroup>
					</Form>
				</ModalFooter>
				<SubscribeModal isOpen={this.state.subscribeModal} toggle={this.subscribeModalToggle} /> */}
      </Modal>
    );
  }
}

export default SchoolsModal;
