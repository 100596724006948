import { connect } from 'react-redux';
import { getUserData } from '../../api/commonApi/getUserData'
import { getUniversityList } from '../../api/userForms/YourInfoForm/getUniversityList'
import { getWorkLocationList } from '../../api/userForms/YourInfoForm/getWorkLocationList'
import { getUniversityListPrediction } from '../../api/userForms/YourInfoForm/getUniversityListPrediction'
import { getLocationListPrediction } from '../../api/userForms/YourInfoForm/getLocationListPrediction'
import { createNamespacer } from '../../utils/reducers';
import { USERFORMDATA, USERTYPE } from '../../constants/namespacer';
import YourInfoForm from '../../components/userForms/yourInfoForm/YourInfoForm';

const userFormDataNamespacer = createNamespacer(USERFORMDATA);
const userTypeNamespacer = createNamespacer(USERTYPE);

const maptStateToProps = (state) => {
    return{
        profilePicUrl: state.yourInfoForm.profilePicUrl,
        name: state.yourInfoForm.name,
        lastName: state.yourInfoForm.lastName,
        email: state.yourInfoForm.email,
        linkedinProfileUrl: state.yourInfoForm.linkedinProfileUrl,
        mbaProgram: state.yourInfoForm.mbaProgram,
        colOrUniv: state.yourInfoForm.colOrUniv,
        graduationYear: state.yourInfoForm.graduationYear,
        currentWorkLocation: state.yourInfoForm.currentWorkLocation,
        isTokenValid: state.redirectAfterLogin.isTokenValid,
        universityList: state.yourInfoForm.universityList,
        workLocationList: state.yourInfoForm.workLocationList,
        privacyCheckboxIsChecked: state.yourInfoForm.privacyCheckboxIsChecked,
        currentFormTab: state.userFormTab.currentFormTab,
        fieldsMissing: state.validationError.fieldsMissing,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        setProfilePic: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_PROFILE_PIC_URL'),
                profilePicUrl: value,
            })
        },
        setName: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_NAME_VALUE'),
                name: value,
            })
        },
        setLastName: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_LAST_NAME_VALUE'),
                lastName: value,
            })
        },
        setEmail: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_EMAIL_VALUE'),
                email: value,
            })
        },
        setLinkedinProfileUrl: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_LINKEDIN_PROFILE_URL_VALUE'),
                linkedinProfileUrl: value,
            })
        },
        setMbaProgram: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_MBA_PROGRAM_VALUE'),
                mbaProgram: value,
            })
        },
        setColOrUniv: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_COL_OR_UNIV_VALUE'),
                colOrUniv: value,
            })
        },
        setGraduationYear: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_GRADUATION_YEAR_VALUE'),
                graduationYear: value,
            })
        },
        setCurrentWorkLocation: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_CURRENT_WORK_LOCATION_VALUE'),
                currentWorkLocation: value,
            })
        },
        setPrivacyCheck: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_PRIVACY_CHECK_VALUE'),
                privacyCheckboxIsChecked: value,
            })
        },
        getUserData: async () => {
            try{
                let response  = await getUserData();
                if(response.status===200){

                    const { attributes } = response.data.data
                    let email = attributes.email;
                    let localStorageEmail = '';
                    const LSSignupDetails = JSON.parse(localStorage.getItem('userSignupDetails'));
                    if(LSSignupDetails && LSSignupDetails.email){
                        localStorageEmail = LSSignupDetails.email
                    }
                    if(localStorageEmail && attributes.email !== localStorageEmail){
                        email = localStorageEmail;
                    }
                    const firstName = attributes['first-name'];
                    const lastName = attributes['last-name'];
                    const profilePic = attributes['image-url'];
                    const userType = attributes['user-type'];
                    const linkedinProfileUrl = attributes['profile-url'];
					const colOrUniv = attributes['university']
					const graduationYear = attributes['grad-year']
					const currentWorkLocation = attributes['location']
					const mbaProgram = attributes['mba-program']
					const sectors = attributes['interests']
                    const provider = attributes['provider']
        
                    dispatch({
                        type: userFormDataNamespacer('SET_NAME_VALUE'),
                        name: firstName
                    })
        
                    dispatch({
                        type: userFormDataNamespacer('SET_LAST_NAME_VALUE'),
                        lastName: lastName
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_EMAIL_VALUE'),
                        email: email
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_PROVIDER_VALUE'),
                        provider: provider
                    })
        
                    dispatch({
                        type: userFormDataNamespacer('SET_PROFILE_PIC_URL'),
                        profilePicUrl: profilePic
                    })

                    dispatch({
                        type: userTypeNamespacer('SET_USERTYPE_VALUE'),
                        value: userType
                    })

					dispatch({
						type: userFormDataNamespacer('SET_LINKEDIN_PROFILE_URL_VALUE'),
						linkedinProfileUrl: linkedinProfileUrl,
					})

					dispatch({
						type: userFormDataNamespacer('SET_COL_OR_UNIV_VALUE'),
						colOrUniv: colOrUniv,
					})

					dispatch({
						type: userFormDataNamespacer('SET_GRADUATION_YEAR_VALUE'),
						graduationYear: graduationYear,
					})

					dispatch({
						type: userFormDataNamespacer('SET_CURRENT_WORK_LOCATION_VALUE'),
						currentWorkLocation: currentWorkLocation,
					})

					dispatch({
						type: userFormDataNamespacer('SET_MBA_PROGRAM_VALUE'),
						mbaProgram: mbaProgram,
					})
					dispatch({
						type: userFormDataNamespacer('SET_SECTORS_VALUE'),
						sectors: sectors,
					})
                } 
            }
            catch(error){
                console.log(error)   
            }
        },
        getUniversityList: async() => {
            try{
                let response = await getUniversityList();
                if(response.status===200){
                    dispatch({
                        type: userFormDataNamespacer('SET_UNIVERSITY_LIST'),
                        payload: response.data.data
                    })
                } 
            }
            catch(error){
                console.log(error)     
            }
        },
        getWorkLocationList: async() => {
            try{
                let response = await getWorkLocationList();
                if(response.status===200){
                    dispatch({
                        type: userFormDataNamespacer('SET_WORK_LOCATION_LIST'),
                        payload: response.data.data
                    })
                }
            }
            catch(error){
                console.log(error)
            }
        },
        getUniversityListPrediction: async(param) => {
            try{
                let response = await getUniversityListPrediction(param);
                if(response.status===200){
                    dispatch({
                        type: userFormDataNamespacer('SET_UNIVERSITY_LIST'),
                        payload: response.data.data
                    })
                }
            }
            catch(error){
                console.log(error)
            }
        },
        getLocationListPrediction: async(param) => {
            try{
                let response = await getLocationListPrediction(param);
                if(response.status===200){
                    dispatch({
                        type: userFormDataNamespacer('SET_WORK_LOCATION_LIST'),
                        payload: response.data.data
                    })
                }
            }
            catch(error){
                console.log(error)
            }
        },
    }
}

export default connect(maptStateToProps, mapDispatchToProps)(YourInfoForm);
