import { connect } from 'react-redux';
import { putApproveUser, putRejectUser, getUserListForAdminApi } from '../../api/adminHomePage'
import ApproveModal from '../../components/approveModal/ApproveModal';
import { createNamespacer } from '../../utils/reducers';
import { ADMINHOMEPAGE } from '../../constants/namespacer';

const adminHomepageNamespacer = createNamespacer(ADMINHOMEPAGE);

const mapStateToProps = (state) => {
    return {
        pendingUserList: state.adminHomePage.pendingUserList,
        refreshPendingUserListRequired: state.adminHomePage.refreshPendingUserListRequired,
        pendingUserData: state.adminHomePage.pendingUserData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setStatusToApprove: async(pendingUserId) => {
            try{
                let bodyFormData = new FormData();
                bodyFormData.append('user[status]', 'approved' );

                let response = await putApproveUser(bodyFormData, pendingUserId);
                if(response.status===200){
                    dispatch({
                        type: adminHomepageNamespacer('SET_PEND_USER_REFRESH_REQ'),
                        refreshPendingUserListRequired: true,
                    })
                }
            }
            catch(error){
                console.log(error);
            }
        },
        setStatusToReject: async(pendingUserId, rejectionReason) => {
            try{
                let bodyFormData = new FormData();
                bodyFormData.append('user[status]', 'rejected');
                bodyFormData.append('user[rejection_reason]', rejectionReason);

                let response = await putRejectUser(bodyFormData, pendingUserId);
                if(response.status===200){
                    dispatch({
                        type: adminHomepageNamespacer('SET_PEND_USER_REFRESH_REQ'),
                        refreshPendingUserListRequired: true,
                    })
                }
            }
            catch(error){
                console.log(error);
            }
        },
        getPendingUserData: async(userStatus, userType) => {
            try{
                let response = await getUserListForAdminApi(userStatus, userType);
                if(response.status===200){
                    dispatch({
                        type: adminHomepageNamespacer('SET_PENDING_USER_LIST'),
                        pendingUserList: response.data.data
                    })
                    
                }
            }
            catch(error){
                console.log(error)
            }
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApproveModal);

