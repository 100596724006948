import React, { Component } from 'react';
import AdminExistingUserMessagesBlockHeader from './AdminExistingUserMessagesBlockHeader';
import AdminExistingUserMessagesBlockChat from './AdminExistingUserMessagesBlockChat';

class AdminExistingUserMessagesBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openProfileSection: false
        };
        this.viewProfile = this.viewProfile.bind(this);
    }

    viewProfile() {
        this.setState({
            openProfileSection: !this.state.openProfileSection
        });
    }

    render() {
        const { tcCurrentChannel, userEmail } = this.props;
        const secondUserData = tcCurrentChannel.secondUserDetail;
        const messages = tcCurrentChannel.messages.items;
        return (
            <div className="message-right-container">
                <div
                    className={
                        "messageChat-content" +
                        (this.state.openProfileSection ? " user-overlay" : "")
                    }
                >
                    <AdminExistingUserMessagesBlockHeader 
                        secondUserData={secondUserData}
                        viewProfile={this.viewProfile}
                        openProfileSection={this.state.openProfileSection}
                    />
                    <AdminExistingUserMessagesBlockChat
                        secondUserData={secondUserData}
                        messages={messages}
                        userEmail={userEmail}
                        tcCurrentChannel={tcCurrentChannel}
                        openProfileSection={this.state.openProfileSection}
                    />
                </div>
            </div>
        );
    }
}

export default AdminExistingUserMessagesBlock;