import React, { Component } from "react";
import ProfilePage from "../profilePage/ProfilePage";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: this.props.match.params.userId,
            name: '',
            lastName: '',
            email: '',
            linkedinProfileUrl: '',
            mbaProgram: '',
            colOrUniv: '',
            graduationYear: '',
            currentWorkLocation: '',
            concept: '',
            venture: '',
            additionalDetails: '',
            needs: [],
            sectors: [],
            profilePic: '',
            selectedNotifications: [],
        };
    }

    componentDidMount() {
        this.props.getUserDataById(this.state.userId);
        this.props.setActiveScreen('profile');
    }

    componentWillReceiveProps(nextProps) {
        const { profileUserInfo } = nextProps;
        this.setUserData(profileUserInfo);
    }

    setUserData(attributes) {
        if (attributes) {
        const userType = attributes['user-type'];
        const profilePic = attributes['image-url'];
        const firstName = attributes['first-name'];
        const lastName = attributes['last-name'];
        const email = attributes.email;
        const linkedinProfileUrl = attributes['profile-url'];
        const mbaProgram = attributes['mba-program'];
        const selectedNotifications = attributes["selected-notifications"];
        const isInvestor = attributes["is-investor"];
        let colOrUniv;
        if (attributes.university) {
            colOrUniv = attributes.university?.name;
        }
        const graduationYear = attributes['grad-year'];
        let currentWorkLocation;
        if (attributes.location) {
            currentWorkLocation = `${attributes.location?.name}, ${attributes.location?.code}`;
        }
        let concept, venture, needs, additionalDetails;
        if (userType === 'alumni') {
            concept = attributes['alumni-detail']?.concept;
            venture = attributes['alumni-detail']?.state;
            needs = attributes['alumni-detail']?.['need-today'];
        }
        else if (userType === 'student' || userType === 'advisor'){
            additionalDetails = attributes['interest-desc'];
        }
        const sectors = attributes.interests;

        this.setState({
            userType,
            name: firstName,
            lastName,
            email,
            linkedinProfileUrl,
            mbaProgram,
            colOrUniv,
            graduationYear,
            currentWorkLocation,
            concept,
            venture,
            additionalDetails,
            needs,
            sectors,
            selectedNotifications,
            isInvestor,
            profilePicBase64: profilePic,
            profilePicUrl: profilePic,
        });
    }
    }

    render() {
        const {
            userType,
            name,
            lastName,
            email,
            linkedinProfileUrl,
            mbaProgram,
            colOrUniv,
            graduationYear,
            currentWorkLocation,
            concept,
            venture,
            additionalDetails,
            needs,
            sectors,
            profilePicBase64,
            profilePicUrl,
            userId,
            selectedNotifications,
            isInvestor,
        } = this.state;

        return (
            <ProfilePage
                userType={userType}
                profilePicUrl={profilePicUrl}
                name={name}
                lastName={lastName}
                email={email}
                linkedinProfileUrl={linkedinProfileUrl}
                mbaProgram={mbaProgram}
                colOrUniv={colOrUniv}
                graduationYear={graduationYear}
                currentWorkLocation={currentWorkLocation}
                concept={concept}
                venture={venture}
                needs={needs}
                sectors={sectors}
                additionalDetails={additionalDetails}
                profilePicBase64={profilePicBase64}
                userId={userId}
                selectedNotifications={selectedNotifications}
                isInvestor={isInvestor}
            />
        );
    }
}

export default Profile;
