import form_icon1 from "../commons/images/form_icon1.svg";
import form_icon2 from "../commons/images/form_icon2.svg";
import form_icon3 from "../commons/images/form_icon3.svg";

export const venturesList = [
  {
    attributes: { id: "just_an_idea", name: "Just an Idea", image: form_icon1 },
  },
  {
    attributes: {
      id: "in_development",
      name: "In Development",
      image: form_icon2,
    },
  },
  {
    attributes: {
      id: "already_a_business",
      name: "Already a Business",
      image: form_icon3,
    },
  },
];
