import React, { Component } from "react";
import { Nav, NavItem, NavLink, Navbar } from "reactstrap";
import AboutUsModal from "../aboutUsModal/aboutUsModal";
import SchoolsModal from "../../containers/schoolsModal";

class HeaderBeforeLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutUsModal: false,
      schoolModal: false,
    };
    this.aboutUsModalToggle = this.aboutUsModalToggle.bind(this);
    this.schoolsModalToggle = this.schoolsModalToggle.bind(this);
  }

  aboutUsModalToggle() {
    this.setState({
      aboutUsModal: !this.state.aboutUsModal,
    });
  }
  schoolsModalToggle() {
    this.setState({
      schoolModal: !this.state.schoolModal,
    });
  }
  render() {
    return (
      <React.Fragment>
        <Navbar
          className="before-login-header"
          style={{ ...this.props.styles }}
        >
          <Nav tabs className="main-navbar">
            <NavItem>
              <NavLink onClick={this.aboutUsModalToggle}>Use cases</NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={this.schoolsModalToggle}>Who we are</NavLink>
            </NavItem>
          </Nav>
        </Navbar>
        <AboutUsModal
          isOpen={this.state.aboutUsModal}
          toggle={this.aboutUsModalToggle}
        />
        <SchoolsModal
          isOpen={this.state.schoolModal}
          toggle={this.schoolsModalToggle}
        />
      </React.Fragment>
    );
  }
}

export default HeaderBeforeLogin;
