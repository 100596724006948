import { connect } from 'react-redux';
import { createNamespacer } from '../utils/reducers';
import { LOGIN, USERTYPE, ADDITIONALDETAILSTAB, USERFORMTAB, USERLOGIN, NEWSFEED, USERFORMDATA } from '../constants/namespacer'
import { getUserData } from '../api/commonApi/getUserData'
import RedirectAfterLogin from '../components/redirectAfterLogin/RedirectAfterLogin'
import {loginUserInfo} from "../api/userForms/loginUser";

const newsFeedNamespacer = createNamespacer(NEWSFEED);
const loginNamespacer = createNamespacer(LOGIN);
const userTypeNamespacer = createNamespacer(USERTYPE);
const additionalDetailsTabNamespacer = createNamespacer(ADDITIONALDETAILSTAB);
const userFormTabReducer = createNamespacer(USERFORMTAB);
const userLoginNamespacer = createNamespacer(USERLOGIN);
const userFormDataNamespacer = createNamespacer(USERFORMDATA);

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.redirectAfterLogin.isAuthenticated,
        authCode: state.redirectAfterLogin.auth_code,
        userId: state.redirectAfterLogin.userId,
        userData: state.redirectAfterLogin.userData,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return{
        setAuthCode: (value) => {
            dispatch({
                type: loginNamespacer('SET_AUTH_CODE'),
                authCode: value,
            })
        },
        setUserId: (value) => {
            dispatch({
                type: loginNamespacer('SET_USER_ID'),
                userId: value,
            })
        },
        getUserData: async() => {
            try{
                let response  = await getUserData();
                if(response.status===200){
                    dispatch({
                        type: loginNamespacer('SET_USER_DATA'),
                        userData: response.data.data
                    })
                    dispatch({
                        type: loginNamespacer('SET_IS_AUTHENTICATED'),
                        isTokenValid: true
                    })
                } 
                else {
                    ownProps.history.replace('/')
                }
            }
            catch(error){
                console.log(error);
                ownProps.history.replace('/')
            }
        },

        userLoginInfo: async (id) => {
            try {
                let response = await loginUserInfo(id);
                if (response.status === 200) {
                    localStorage.setItem('id', response.data.user.id);
                    localStorage.setItem('isLogin', true);
                    localStorage.setItem('isAdmin', false);
                    if (response.data.auth_token !== null) {
                        localStorage.setItem('auth_code', response.data.auth_token);
                    }
                    if (response.data.user.newsfeed) {
                        localStorage.setItem('getStreamApiKey', response.data.user.newsfeed.api_key);
                        localStorage.setItem('getStreamAppId', response.data.user.newsfeed.app_id);
                        localStorage.setItem('getStreamApiToken', response.data.user.newsfeed.newsfeed_token);
                        localStorage.setItem('getStreamFeedName', response.data.user.newsfeed.feed_name);
                        localStorage.setItem('getStreamNotificationFeedName', response.data.user.newsfeed.notification_feed_name);
                    }
                    dispatch({
                        type: loginNamespacer('SET_IS_AUTHENTICATED'),
                        isTokenValid: 'true'
                    });
                    dispatch({
                        type: newsFeedNamespacer('SET_GETSTREAM_API_KEY'),
                        getStreamApiKey: response.data.user.newsfeed.api_key
                    });
                    dispatch({
                        type: newsFeedNamespacer('SET_GETSTREAM_API_ID'),
                        getStreamAppId: response.data.user.newsfeed.app_id
                    });
                    dispatch({
                        type: newsFeedNamespacer('SET_GETSTREAM_API_TOKEN'),
                        getStreamApiToken: response.data.user.newsfeed.newsfeed_token
                    });
                    dispatch({
                        type: newsFeedNamespacer('SET_GETSTREAM_FEED_NAME'),
                        getStreamFeedName: response.data.user.newsfeed.feed_name
                    });
                    dispatch({
                        type: newsFeedNamespacer('SET_GETSTREAM_NOTIFICATION_FEED_NAME'),
                        getStreamNotificationFeedName: response.data.user.newsfeed.notification_feed_name
                    });
                }
            } catch (error) {
                console.error(error);
                if (error.response && error.response.data) {
                    dispatch({
                        type: userFormDataNamespacer('RESET_YOUR_FORM_DATA')
                    })
                    dispatch({
                        type: userFormTabReducer('SET_CURRENT_FORM_TAB'),
                        currentFormTab: '1'
                    })
                    dispatch({
                        type: userLoginNamespacer('RESET_FIELDS'),
                    })
                    dispatch({
                        type: userFormDataNamespacer('SET_SECTORS_VALUE'),
                        sectors: [],
                    })
                    dispatch({
                        type: additionalDetailsTabNamespacer('RESET_ADDITIONALDETAILSTAB_VALUE'),
                        additionalDetails: ''
                    })
                    dispatch({
                        type: userTypeNamespacer('RESET_USER_TYPE_FIELDS'),
                    })
                    const errorMessages = error.response.data.error;
                    if (errorMessages) {
                        alert(errorMessages)
                    }
                }
            }
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RedirectAfterLogin);

