import { connect } from 'react-redux';
import App from '../components/App/App'
import { LOGIN, ADMINLOGIN, USERTYPE } from '../constants/namespacer'
import { getUserData } from '../api/commonApi/getUserData'
import { createNamespacer } from '../utils/reducers';

const loginNamespacer = createNamespacer(LOGIN);
const adminLoginNamespacer = createNamespacer(ADMINLOGIN);
const userTypeNamespacer = createNamespacer(USERTYPE);

const mapStateToProps = (state) => {
    return {
        isTokenValid: state.redirectAfterLogin.isTokenValid,
        isAdmin: state.adminLogin.isAdmin,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserData: async () => {
            try {
                let response = await getUserData();
                if (response.status === 200) {
                    dispatch({
                        type: loginNamespacer('SET_USER_DATA'),
                        userData: response.data.data
                    })
                }
            }
            catch (error) {
                localStorage.clear();
                dispatch({
                    type: loginNamespacer('SET_IS_AUTHENTICATED'),
                    isTokenValid: false
                })
            }
        },

        checkInitialSettings: async() => {
            const id = localStorage.getItem('id');
            const auth_code = localStorage.getItem('auth_code');
            const user_type = localStorage.getItem('user_type');
            const isAdmin = JSON.parse(localStorage.getItem('isAdmin'));
            const isLogin = JSON.parse(localStorage.getItem('isLogin'));

            dispatch({
                type: adminLoginNamespacer('SET_IS_ADMIN_FLAG'),
                isAdmin: isAdmin,
            })
            dispatch({
                type: loginNamespacer('SET_USER_ID'),
                userId: id,
            })
            dispatch({
                type: loginNamespacer('SET_AUTH_CODE'),
                authCode: auth_code,
            })
            dispatch({
                type: userTypeNamespacer('SET_USERTYPE_VALUE'),
                userType: user_type
            })
            dispatch({
                type: loginNamespacer('SET_IS_AUTHENTICATED'),
                isTokenValid: isLogin
            })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

