import { connect } from 'react-redux';
import { createNamespacer } from '../../utils/reducers';
import { USERFORMDATA } from '../../constants/namespacer';
import EditVentureForm from '../../components/editProfile/editMyVenture/EditVentureForm';

const userFormDataNamespacer = createNamespacer(USERFORMDATA);

const maptStateToProps = (state) => {
    return{
        concept: state.yourVentureForm.concept,
        venture: state.yourVentureForm.venture,
        needs: state.yourVentureForm.needs,
        feasibilityStudyAmount: state.yourVentureForm.feasibilityStudyAmount,
        investorType: state.yourVentureForm.investorType,
        additionalDetails: state.yourVentureForm.additionalDetails,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        setConcept: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_CONCEPT_VALUE'),
                concept: value,
            })
        },
        setVenture: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_VENTURE_VALUE'),
                venture: value,
            })
        },
        setNeeds: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_NEEDS_VALUE'),
                needs: value,
            })
        },
        setFeasibilityStudyAmount: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_FEASIBILITY_STUDY_AMOUNT_VALUE'),
                feasibilityStudyAmount: value,
            })
        },
        setInvestorType: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_INVESTOR_VALUE'),
                investorType: value,
            })
        },
        setAdditionalDetails: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_ADDITIONAL_DETAILS_VALUE'),
                additionalDetails: value,
            })
        },
    }
}

export default connect(maptStateToProps, mapDispatchToProps)(EditVentureForm);