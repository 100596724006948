import { createReducer, createNamespacer } from '../utils/reducers';
import { USERNEWSFEED } from '../constants/namespacer';

const initialState = {
    getStreamApiKey: '',
    getStreamAppId: '',
    getStreamApiToken: '',
    getStreamFeedName: '',
    getStreamNotificationFeedName: '',
};

const userNewsFeedNamespacer = createNamespacer(USERNEWSFEED);

const handlers = {
    [userNewsFeedNamespacer('SET_GETSTREAM_API_KEY')]: (state, action) => {
        return {
            ...state,
            getStreamApiKey: action.getStreamApiKey
        }
    },
    [userNewsFeedNamespacer('SET_GETSTREAM_API_ID')]: (state, action) => {
        return {
            ...state,
            getStreamAppId: action.getStreamAppId
        }
    },
    [userNewsFeedNamespacer('SET_GETSTREAM_API_TOKEN')]: (state, action) => {
        return {
            ...state,
            getStreamApiToken: action.getStreamApiToken
        }
    },
    [userNewsFeedNamespacer('SET_GETSTREAM_FEED_NAME')]: (state, action) => {
        return {
            ...state,
            getStreamFeedName: action.getStreamFeedName
        }
    },
    [userNewsFeedNamespacer('SET_GETSTREAM_NOTIFICATION_FEED_NAME')]: (state, action) => {
        return {
            ...state,
            getStreamNotificationFeedName: action.getStreamNotificationFeedName
        }
    },
};

const userNewsFeedReducer = createReducer(initialState, handlers, [USERNEWSFEED]);

export default userNewsFeedReducer;

