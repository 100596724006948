import axiosInstance from '..';

export const putUserData = (data) => {
    const axios = axiosInstance();

    const userId = localStorage.getItem('id');

    return axios({
        method: 'PUT',
        url: `/users/${userId}`,
        data,
    })
}
