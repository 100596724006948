import { createReducer, createNamespacer } from '../../utils/reducers'
import { USERLOGIN } from '../../constants/namespacer'

const initialState = {
    userEmail: '',
    userPassword: ''
}

const userLoginNamespacer = createNamespacer(USERLOGIN)

const handlers = {
    [userLoginNamespacer('SET_USER_EMAIL')]: (state, action) => {
        return {
            ...state,
            userEmail: action.userEmail
        }
    },
    [userLoginNamespacer('SET_USER_PASSWORD')]: (state, action) => {
        return {
            ...state,
            userPassword: action.userPassword
        }
    },
    [userLoginNamespacer('RESET_FIELDS')]: (state, action) => {
        return initialState;
    }
}

const loginUser = createReducer(initialState, handlers, [USERLOGIN])

export default loginUser
