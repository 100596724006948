import React, { useEffect, useRef } from 'react'
import moment  from 'moment'
 
const getTimeOfMsg = (timestamp) => {
    return moment(timestamp).format('LT');
}

const getDateOfMsg = (timestamp) => {
    return moment(timestamp).format('ddd, MMM Do');
}

export default function ChatDisplayMsg({ msgFromAuthor, msgToAuthor, messages }) {
    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "auto" })
    }
    useEffect(scrollToBottom, [messages]);

    let oldDay;
    let latestDay;

    return (
        <div className="chat-section-wrapper">
            <div className="chat-inner-container">
                {
                    messages.length > 0 ?
                        messages.map((msg, index) => {

                            oldDay=latestDay;
                            latestDay = getDateOfMsg(msg.dateUpdated.toString());
                            
                            let dayToDisplay;
                            dayToDisplay = oldDay !== latestDay ? latestDay : oldDay;
                            
                            return <>
                                {   oldDay !== latestDay ? 
                                    <p className='date-in-msg-box'>
                                        <span>{dayToDisplay}</span>
                                    </p> : ''
                                }
                                
                                <div key={index}
                                    className={`chat-user 
                                    ${msg.author===msgToAuthor ? 'chat-user1' : 
                                    msg.author===msgFromAuthor ? 'chat-user2' : '' }`}
                                >
                                    {
                                        msg.author===msgToAuthor ? 
                                            <>
                                                <span className="chat-text">{msg.body}</span>
                                                <span className="chat-time">{getTimeOfMsg(msg.dateUpdated.toString())}</span> 
                                            </> 
                                        : 
                                            msg.author===msgFromAuthor ?
                                                <>
                                                    <span className="chat-time">{getTimeOfMsg(msg.dateUpdated.toString())}</span>
                                                    <span className="chat-text">{msg.body}</span>
                                                </> 
                                            : ''
                                    }
                                </div>
                            </>
                        }) 
                    : ''
                }
            </div>
            <div ref={messagesEndRef} />
        </div>
    )
}