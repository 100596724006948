import React, { Component } from "react";
import DesktopHeader from "../../containers/homePage/DesktopHeader";
import Filters from "../../containers/Filters";
import FilterUsers from "../../containers/FilterUsers";
import MobileMenu from "../../containers/homePage/MobileMenu";
import NoUsers from "../noUsers/NoUsers";
import { Link } from "react-router-dom";

class Favourites extends Component {
    componentDidMount() {
        this.props.getFavoriteList();
    }

    render() {
        const { favoriteUsersList } = this.props;
        return (
            <React.Fragment>
                <DesktopHeader />
                <div className="custom-container-wrapper users-section">
                    <Filters />
                    {favoriteUsersList.length ? (
                        <FilterUsers
                            filterUsersFor="favorite"
                            favoriteUsersList={favoriteUsersList}
                        />
                    ) : (
                        <div className="filter-user-wrapper">
                            <div className="pagination-container home-page-wrapper justify-content-end">
                                <Link
                                    to="/homepage"
                                    className="custom-pagination favorites-link"
                                >
                                    Show All Members
                                </Link>
                            </div>{" "}
                            <NoUsers for="favorite" />
                        </div>
                    )}
                </div>
                <MobileMenu />
            </React.Fragment>
        );
    }
}

export default Favourites;
