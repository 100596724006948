import { connect } from 'react-redux';
import { getUserData } from '../api/commonApi/getUserData'
import ThankYou from '../components/thankYou/ThankYou';
import { createNamespacer } from '../utils/reducers';
import { LOGIN, ADMINLOGIN, USERFORMDATA, USERFORMTAB } from '../constants/namespacer';

const loginNamespacer = createNamespacer(LOGIN);
const adminLoginNamespacer = createNamespacer(ADMINLOGIN);
const userFormDataNamespacer = createNamespacer(USERFORMDATA);
const userFormTabReducer = createNamespacer(USERFORMTAB);

const mapStateToProps = (state) => {
    return {
        isTokenValid: state.redirectAfterLogin.isTokenValid,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        getUserData: async() => {
            try{
                let response  = await getUserData();
                if(response.status===200){
                }
            }
            catch(error){
                console.log(error);
            }
        },
        setLogout: async() => {
            dispatch({
                type: loginNamespacer('SET_IS_AUTHENTICATED'),
                isTokenValid: false,
            })
            dispatch({
                type: adminLoginNamespacer('SET_IS_ADMIN_FLAG'),
                isAdmin: false,
            })
            dispatch({
                type: userFormDataNamespacer('RESET_YOUR_FORM_DATA')
            })
            dispatch({
                type: userFormTabReducer('SET_CURRENT_FORM_TAB'),
                currentFormTab: '1'
            })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThankYou);

