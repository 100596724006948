import { connect } from 'react-redux';
import { createNamespacer } from '../../utils/reducers';
import { ADMINHOMEPAGE, PAGINATION } from '../../constants/namespacer';
import { getUserListForAdminApi, putApproveUser, putRejectUser } from '../../api/adminHomePage'
import AdminHomePage from '../../components/adminHomePage/AdminHomePage'

const adminHomepageNamespacer = createNamespacer(ADMINHOMEPAGE);
const paginationNamespacer = createNamespacer(PAGINATION);

const mapStateToProps = (state) => {
    return {
        pendingUserList: state.adminHomePage.pendingUserList,
        refreshPendingUserListRequired: state.adminHomePage.refreshPendingUserListRequired,
        pendingUserData: state.adminHomePage.pendingUserData,
        pendingUserType: state.adminHomePage.pendingUserType,
        totalRecords: state.pagination.totalRecords,
        recordsPerPage: state.pagination.recordsPerPage,
        showLoader: state.adminHomePage.showLoader
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        setRefreshPendingUserListRequired: () => {
            dispatch({
                type: adminHomepageNamespacer('SET_PEND_USER_REFRESH_REQ'),
                refreshPendingUserListRequired: false,
            })
        },
        getPendingUserList: async(userStatus, userType, page) => {
            try{
                let response = await getUserListForAdminApi(userStatus, userType, page);
                if(response.status===200){
                    dispatch({
                        type: adminHomepageNamespacer('SET_PENDING_USER_LIST'),
                        pendingUserList: response.data.data,
                        showLoader: true,
                    })
                    dispatch({
                        type: paginationNamespacer('SET_TOTAL_RECORDS'),
                        totalRecords: response.data.meta['total-records']
                    })
                }
            }
            catch(error){
                console.log(error)  
            }
        },
        setStatusToApprove: async(pendingUserId) => {
            try{
                let bodyFormData = new FormData();
                bodyFormData.append('user[status]', 'approved' );

                let response = await putApproveUser(bodyFormData, pendingUserId);
                if(response.status===200){
                    dispatch({
                        type: adminHomepageNamespacer('SET_PEND_USER_REFRESH_REQ'),
                        refreshPendingUserListRequired: true,
                    })
                }
            }
            catch(error){
                console.log(error);
            }
        },
        setStatusToReject: async(pendingUserId, rejectionReason) => {
            try{
                let bodyFormData = new FormData();
                bodyFormData.append('user[status]', 'rejected');
                bodyFormData.append('user[rejection_reason]', 'rejectionReason');

                let response = await putRejectUser(bodyFormData, pendingUserId);
                if(response.status===200){
                    dispatch({
                        type: adminHomepageNamespacer('SET_PEND_USER_REFRESH_REQ'),
                        refreshPendingUserListRequired: true,
                    })
                }
            }
            catch(error){
                console.log(error);
            }
        },
        setPendingUserType: async(value) => {
            dispatch({
                type: adminHomepageNamespacer('SET_PENDING_USER_TYPE'),
                pendingUserType: value,
                showLoader: true,
            })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminHomePage);

