import axiosInstance from '.';

export const getAdminNotificationListAPI = (page=1, limit=20) => {
    return axiosInstance()({
        method: 'GET',
        url: `/admin/notifications?page=${page}&per_page=${limit}`,
    })
}

export const markAdminNotificationReadAPI = (id) => {
    return axiosInstance()({
        method: 'PUT',
        url: `/admin/notifications/${id}`,
    })
}