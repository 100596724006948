import React, {Component} from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import classnames from 'classnames';
import EditMyInfo from '../../../containers/editProfile/EditMyInfo';
import EditVentureForm from '../../../containers/editProfile/EditVentureForm';
import EditSectorForm from '../../../containers/editProfile/EditSectorForm';
import EditNotificationsForm from '../../../containers/editProfile/EditNotificationsForm';

class EditProfiletabs extends Component {
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          activeTab: '1'
        };
      }
    
      toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    
    render() {
        const { userType } =  this.props;
        return (
            <div className="tabs-wrapper">
                <Nav tabs>
                    <NavItem>
                        <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => { this.toggle('1'); }}
                        >
                        My Info
                        </NavLink>
                    </NavItem>
                    {
                        (userType ==='alumni') ?
                        <NavItem>
                            <NavLink
                            className={classnames({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.toggle('2'); }}
                            >
                            My Venture
                            </NavLink>
                        </NavItem>
                        : null
                    }
                    <NavItem>
                        <NavLink
                        className={classnames({ active: this.state.activeTab === '3' })}
                        onClick={() => { this.toggle('3'); }}
                        >My Interests
                        </NavLink>
                    </NavItem>
					<NavItem>
                        <NavLink
                        className={classnames({ active: this.state.activeTab === '4' })}
                        onClick={() => { this.toggle('4'); }}
                        >My Notifications
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <EditMyInfo userType={userType}/>
                    </TabPane>
                    <TabPane tabId="2">
                        <EditVentureForm/>
                    </TabPane>
                    <TabPane tabId="3">
                        <EditSectorForm userType={userType}/>
                    </TabPane>
                    <TabPane tabId="4">
						<EditNotificationsForm />
                    </TabPane>
                </TabContent>
            </div>
        );
    }
}

export default EditProfiletabs;