import React, { Component } from "react";
import student from "../../commons/images/student.svg";
import alumna from "../../commons/images/almuna-icon.svg";
import newTypeUser from "../../commons/images/3rd-user-type.svg";
import { Redirect } from "react-router-dom";
import UserFormButtons from ".././../containers/userForms/UserFormButtons";
import { Input } from "reactstrap"

export default class Usertype extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
        };
    }

    componentDidMount = () => {
        this.props.getUserData();
        localStorage.setItem("user_type", null);
    };

    handleUserType = () => {
        if (localStorage.getItem("user_type") === "alumni" || localStorage.getItem("user_type") === "student" || localStorage.getItem("user_type") === "advisor") {
            this.routeChange();
        } else {
            alert("Please select a user type");
        }
    };

	handleInvestorCheckbox = (e) => {
		this.props.setIsInvestor(!this.props.isInvestor)
    };

	toggleUserType = (userType) => {
		this.props.setUserType(userType)
	}

    routeChange = () => {
        this.setState({ redirectTo: "/userInfo" });
    };

    render() {
        const { userType, isInvestor } = this.props;
        const { redirectTo } = this.state;

        return (
            <React.Fragment>
                <div className="custom-container">
                    <div className="user-wrapper usertype-container d-flex flex-column justify-content-center align-items-center">
                        <div className="user-header-container text-center">
                            <h6>Which of the following best describes you?</h6>
                        </div>
                        <div className="user-type-container ">
                            <div
                                onClick={() => this.toggleUserType("alumni")}
                                className={`user-block ${userType === "alumni" ? "user-selected" : ""}`}
                            >
                                <div className="img-border">
                                    <div className="user-profile">
                                        <img src={alumna} alt="no_image" />
                                    </div>
                                </div>
                                <div className="profile-details">
                                    <p className="content">I have an idea/venture</p>
                                </div>
                            </div>
                            <div
                                onClick={() => this.toggleUserType("student")}
                                className={`user-block ${userType === "student" ? "user-selected" : ""}`}
                            >
                                <div className="img-border">
                                    <div className="user-profile">
                                        <img src={student} alt="no_image" />
                                    </div>
                                </div>
                                <div className="profile-details">
                                    <p className="content">I'm seeking to collaborate on someone else's idea/venture</p>
                                </div>
                            </div>
                            <div
                                onClick={() => this.toggleUserType('advisor') }
                                className={`user-block ${userType === "advisor" ? "user-selected" : ""}`}
                            >
                                <div className="img-border">
                                    <div className="user-profile">
                                        <img src={newTypeUser} alt="no_image" />
                                    </div>
                                </div>
                                <div className="profile-details">
                                    <p className="content">I'm a prospective advisor</p>
                                </div>
                            </div>
                        </div>
						<div>
					{
						userType === 'student' || userType === 'alumni' ? 
                    <p className="privacy-link-in-user">
                        <Input
                            type="checkbox"
                            checked={this.props.isInvestor}
                            onChange={this.handleInvestorCheckbox}
                            id="check1"
                        />
                        <label>
							I&apos;m also a prospective advisor
                        </label>
                    </p>
: null
					}
						</div>
                        <div className="form-container">
                            <UserFormButtons
                                history={this.props.history}
                                setCurrentFormTab={this.props.setCurrentFormTab}
                            />
                        </div>
                    </div>
                </div>
                {redirectTo ? <Redirect push to={redirectTo} /> : null}
            </React.Fragment>
        );
    }
}
