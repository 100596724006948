import axiosInstance from './indexWithoutApiInBaseUrl';

const headers = {
    'Content-Type': 'application/json'
  }
  
export const forgotPassword = (data) => {
    return axiosInstance({'Content-Type': 'application/json',})({
        method: 'POST',
        url: `/password`,        
        data,
        
    })
}
