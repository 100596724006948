import React, { Component } from 'react';
import profile_pic from '../../../../commons/images/profile_pic.svg'
import BackArrow from '../../../../commons/images/back-icon.svg';

class MessageBlockHeader extends Component {
    handleMessageBox = ()=>{
        this.props.toggleViewChat()
    }
    render() {
        const { secondUserData } = this.props;
        return (
            <div className="messages-header">
                <button type="button" className="back-btn-wrapper d-inline-block d-md-none"
                    onClick={() => {this.handleMessageBox()}}
                >
                    <img src={BackArrow} className="back-btn" alt="Back" />
                </button>
                <div className="message-profile-container">
                    <div className="message-profile-pic">
                        <img 
                            src={secondUserData && secondUserData.attributes['image-url'] ? secondUserData.attributes['image-url'] : profile_pic} 
                            alt="no_image"
                        />
                    </div>
                    <div className="message-profile-info d-flex align-items-start justify-content-start flex-column">
                        <span>
                            {secondUserData ? `${secondUserData.attributes['first-name']} ${secondUserData.attributes['last-name']}` : ''}
                        </span>
                        <span onClick={this.props.viewProfile}  className="message-linkedIn">
                            {
                                secondUserData ? 
                                this.props.openProfileSection ? 'View Chat':'View Profile' 
                                : ''
                            }
                        </span>
                    </div>
                </div>
                {/* Required later */}
                {/* <span className="message-menu"></span> */} 
            </div> 
        );
    }
}

export default MessageBlockHeader;