export const needTodayList = [
  {
    attributes: { id: "feasibility_study", name: "Feasibility study" },
  },
  {
    attributes: { id: "CEO", name: "CEO" },
  },
  {
    attributes: { id: "co-founder", name: "Co-founder" },
  },
  {
    attributes: { id: "early_hire", name: "Early hire" },
  },
  {
    attributes: { id: "CTO", name: "CTO" },
  },
  {
    attributes: { id: "advisor", name: "Advisor" },
  },
];
