import React, { Component } from "react";
import { Redirect, withRouter } from 'react-router-dom';
import profilePic from "../../commons/images/profile_pic.svg";
import NewsFeedPostCard from '../newsFeedPostCard/NewsFeedPostCard';
import advisorBanner from "../../commons/images/advisor_ribbon.svg";

const formatUniversityName = (university) => {
    return university.nickname || university.name;
}

class UserCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectTo: null,
            isCommentModalOpen: false,
            activity: null,
            showMore: false,
        };

        this.commentModalToggle = this.commentModalToggle.bind(this);
        this.handleClickComment = this.handleClickComment.bind(this);
    }

    commentModalToggle(activity) {
        this.setState({ redirectTo: `/newsFeed/${activity.id}/${activity.actor.id}`});
    }

    handleClickComment(activity) {
        this.setState({ activity: activity });
        this.commentModalToggle(activity);
    }

    handleClickUser(userDetail) {
        if (userDetail.id) {
            // this.setState({ redirectTo: `/profile/${userDetail.id}` });
            this.props.history.push(`/profile/${userDetail.id}`);
        }   
    }

    handleSendComment() { }

    render() {
        const { activityProps, userType, userDetail, isNewMember } = this.props;
        const { redirectTo } = this.state;

        return (
            <>
                <div className="left-user-profile">
                    <div className="user-profile">
                        <div
                            className="profile-picture"
                            onClick={() => this.handleClickUser(userDetail)}
                        >
                            <img
                                src={userDetail && userDetail.attributes && userDetail.attributes['image-url'] ? userDetail.attributes['image-url'] : profilePic}
                                alt="no_image"
								onError={(e)=>{e.target.onerror = null; e.target.src=profilePic}}
                            />
                            {userType === "advisor" || userDetail.attributes['is-investor'] ? <img src={advisorBanner} alt="Advisor" className="advisor-banner"/> : null}
                        </div>
                        <div className="profile-details">
                            {
                                userDetail && userDetail.attributes &&
                                <span>{`${userDetail.attributes['first-name']} ${userDetail.attributes['last-name']}`}</span>
                            }
                            {
                                userDetail && userDetail.attributes && userDetail.attributes.university ?
                                <span>{formatUniversityName(userDetail.attributes.university)} {userDetail.attributes['mba-program']} {userDetail.attributes['grad-year']}</span>
                                : ''
                            }
                            {
                                userDetail && userDetail.attributes && userDetail.attributes.location ?
                                <span>{userDetail.attributes.location.name}, {userDetail.attributes.location.code}</span>
                                : ''
                            }

                        </div>
                    </div>
                    {
                        (userDetail && userDetail.attributes && userDetail.attributes['profile-url']) ?
                            <div className="user-contact-details">
                                <a
                                    href={userDetail.attributes['profile-url']}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="linked-link">LinkedIn Profile
                                </a>
                            </div> : ''
                    }
                </div>
                <div className="right-user-profile">
                    {/* {
                        userType === 'student' || userType === 'advisor' ?
                            <div className="card-wrapper-div card-wrapper">
                                <span className="card-key">Wants to disrupt...</span>
                                {
                                    userDetail && userDetail.attributes && userDetail.attributes.interests.length > 0 ?
                                        (
                                            userDetail.attributes.interests.map((studentInterest, index) => (
                                                <span key={index} className="card-value">{studentInterest.name}</span>
                                            ))
                                        ) : null
                                }
                                <span className="italic-text">
                        {
                            userDetail && userDetail.attributes && userDetail.attributes['interest-desc']
                                ? `"${userDetail.attributes['interest-desc']}"`
                                : ''
                        }
                    </span>
                            </div> : ''
                    } */}
                    {
                        (((activityProps && isNewMember) && !(userType === 'student' || userType === 'advisor')) ||
                        ((!activityProps && !isNewMember) && !(userType === 'student' || userType === 'advisor'))) &&
                        <div className="card-wrapper-div">
                            {
                                (userType === 'alumni') && userDetail && userDetail.attributes && userDetail.attributes['alumni-detail'] ?
                                    <div>
                                        {
                                            <div className="card-wrapper">
                                                <span className="card-key">My concept...</span>
                                                <span className="card-value">{userDetail.attributes['alumni-detail'].concept}</span>
                                            </div>
                                        }
                                    
                                        {
                                            <div className="card-wrapper">
                                                <span className="card-key">What I need today...</span>
                                                {
                                                    userDetail.attributes['alumni-detail']['need-today'].length > 0 ?
                                                        (
                                                            userDetail.attributes['alumni-detail']['need-today'].map((alumniNeedToday, index) => (
                                                                <span className="card-value" key={index}>
                                                        { alumniNeedToday.match(/feasibility/,"i") && alumniNeedToday.match(/\|/, "i") ?
                                                            (`Feasibility Study ($${alumniNeedToday.split('|')[1]})`)
                                                            :
                                                            (
                                                                `${alumniNeedToday.split('_').join(' ').charAt(0).toUpperCase()}${alumniNeedToday.split('_').join(' ').substring(1)}`
                                                            )}</span>
                                                            ))
                                                        ) : null
                                                }
                                            </div>
                                        }

                                        {
                                            <div className="card-wrapper">
                                                <span className="card-key">Status of venture...</span>
                                                <span className="card-value">{
                                                    `${userDetail?.attributes['alumni-detail']?.state?.split('_').join(' ').charAt(0).toUpperCase()}${userDetail.attributes['alumni-detail'].state.split('_').join(' ').substring(1)}`
                                                }</span>
                                            </div>
                                        }
                                    </div>
                                    : ''
                            }
                        </div>
                    }
                    {
                        ((activityProps && isNewMember) && userDetail?.attributes?.interests && userDetail?.attributes?.interests?.length>0) || 
                        ((!activityProps && !isNewMember) && userDetail?.attributes?.interests && userDetail?.attributes?.interests?.length>0) ? 
                        <div className="card-wrapper-div card-wrapper">
                            <span className="card-key">Wants to disrupt...</span>
                            {
                                userDetail.attributes.interests.map((studentInterest, index) => (
                                    <span key={index} className="card-value">{studentInterest.name}</span>
                                ))
                            }
                            {
                                (userType==='student' || userType==='advisor') && userDetail.attributes['interest-desc'] ? 
                                <span className="italic-text">
                                        {`"${userDetail.attributes['interest-desc']}"`}
                                    </span> : ''
                            }
                        </div> : null
                    }
                    {
                        isNewMember &&
                            <div className="raf-footer">
                                <button
                                    onClick={() => { this.handleClickComment(activityProps.activity) }}
                                    className="raf-comment-button"
                                >
                                    <span>
                                        {
                                            activityProps.activity.reaction_counts.comment
                                                ? `${activityProps.activity.reaction_counts.comment}`
                                                : `+`
                                        }
                                    </span>
                                </button>
                            </div>
                    }
                    {
                        !isNewMember && activityProps && activityProps.activity &&
                            <NewsFeedPostCard
                                activityProps={activityProps}
                                onClickComment={this.handleClickComment}
                            />
                    }
                </div>
                { redirectTo  ? 
                    <Redirect to={redirectTo} /> 
                    : null}
            </>
        )
    }
}

export default withRouter(UserCard);