import { createNamespacer, createReducer } from "../utils/reducers";
import { SCHOOLSMODAL } from '../constants/namespacer'

const initialState = {
    schoolList: [],
    pageCount: 1
};

const schoolsModalNamespacer = createNamespacer(SCHOOLSMODAL);

const handlers = {
    [schoolsModalNamespacer('SET_SCHOOL_LIST')]: (state, action) => {
        return {
            ...state,
            schoolList: [...state.schoolList, ...action.payload.results],
            pageCount: action.payload.pageCount
        };
    },
}

const schoolsModalReducer = createReducer(initialState, handlers, [SCHOOLSMODAL]);

export default schoolsModalReducer;