import { connect } from "react-redux";
import { createNamespacer } from "../../utils/reducers";
import { TWILIOCHAT } from "../../constants/namespacer";
import { addMemberToChannelApi, notifyUserToTriggerMail, notifyChannelConversationToAdmin } from "../../api/twilioChat";
import ChatSection from "../../components/userConversation/chatSection/ChatSection";
import chatClient from "../../services/twilioConversation";
import { getMessageCount } from "../../constants/fixedValues";
import store from "../../store";
import { notifyUserAboutUnreadMessages } from "../../utils/twilio";

const twilioChatNamespacer = createNamespacer(TWILIOCHAT);

const mapStateToProps = (state) => {
    return {
        twilioToken: state.twilioChat.twilioToken,
        msgFromIdentity: state.twilioChat.msgFromIdentity,
        messages: state.twilioChat.messages,
        sendMessageText: state.twilioChat.sendMessageText,
        tcCurrentChannel: state.twilioChat.tcCurrentChannel,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMessages: () => {
            dispatch({
                type: twilioChatNamespacer("SET_MESSAGES"),
                messages: [],
            });
        },
        setSendMessageText: (value) => {
            dispatch({
                type: twilioChatNamespacer("SET_SEND_MSG_TEXT"),
                sendMessageText: value,
            });
        },

        get getChannelOrCreateChannel() {
            return async (fromIdentity, toIdentity, fromEmail, toEmail) => {
                document.getElementsByClassName("form-control")[0].value =
                    "Connecting to chat server...";
                document.getElementsByClassName("form-control")[0].disabled = true;

                let conversationName =
                    fromIdentity < toIdentity
                        ? `${fromIdentity}-${toIdentity}`
                        : `${toIdentity}-${fromIdentity}`;

                const newChannelDetail = {
                    friendlyName: conversationName,
                    uniqueName: conversationName,
                };

                let channel;
                try {
                    console.log("In getChannelOrCreateChannel try");
                    channel = await chatClient.client.getConversationByUniqueName(
                        newChannelDetail.uniqueName
                    );
                } catch (error) {
                    console.error("In getChannelOrCreateChannel catch", error);
                    if (
                        error.toString() === "Error: Not Found" ||
                        localStorage.getItem("from") === "Signup" ||
                        localStorage.getItem("from") === "Login"
                    ) {
                        channel = await this.createNewChannel(
                            newChannelDetail,
                            fromIdentity,
                            toIdentity,
                            fromEmail,
                            toEmail
                        );

                        localStorage.removeItem("from");
                    }
                }

                console.log("In getChannelOrCreateChannel channel", channel);

                await chatClient.attachHandlersToChannel(channel);

                await channel?.updateLastReadMessageIndex(channel?.lastMessage?.index || 0);

                dispatch({
                    type: twilioChatNamespacer("SET_TC_CURRENT_CHANNEL"),
                    tcCurrentChannel: channel,
                });

                if (channel) {
                    this.getMessageFromChannel(channel);

                    document.getElementsByClassName("form-control")[0].value = "";
                    document.getElementsByClassName("form-control")[0].disabled = false;
                }
                if (channel?._participants && channel?._participants?.size === 2) {
                    await notifyChannelConversationToAdmin({conv_sid: channel.sid});
                }
            };
        },

        // get addMemberToChannel() {
        //     return async (channel, fromIdentity, toIdentity, fromEmail, toEmail) => {
        //         let bodyFormData = new FormData();
        //         bodyFormData.append("channel_sid", channel.sid);
        //         bodyFormData.append("from", fromEmail);
        //         bodyFormData.append("to", toEmail);
        //         bodyFormData.append("sender_id", fromIdentity);
        //         bodyFormData.append("receiver_id", toIdentity);

        //         try {
        //             await addMemberToChannelApi(bodyFormData);
        //         } catch (error) {
        //             console.log("In addMemberToChannel catch", error);
        //         }
        //     };
        // },

        get createNewChannel() {
            return async (newChannelDetail, fromIdentity, toIdentity, fromAuthor, toAuthor) => {
                try {
                    let newChannel = await chatClient.client.createConversation(newChannelDetail);
                    await newChannel.join();
                    await newChannel.add(toAuthor);
                    // if(newChannel._participants.size !== 2){
                    //     await window.refreshToken()
                    //     await newChannel.add(toAuthor);
                    // }

                    return newChannel;

                    // if (newChannel) {
                    //     await this.addMemberToChannel(
                    //         newChannel,
                    //         fromIdentity,
                    //         toIdentity,
                    //         fromAuthor,
                    //         toAuthor
                    //     );
                    //     return newChannel;
                    // }
                    // await newChannel.updateLastConsumedMessageIndex(0);
                } catch (error) {
                    console.error("In createNewChannel catch", error);
                }
            };
        },

        get sendMessageToChannel() {
            return async (channelssdsd, msg) => {
                let channel = store.getState().twilioChat.tcCurrentChannel;
                try {
                    console.log(msg);
                    try {
                        await channel.sendMessage(msg);
                    } catch (e) {
                        console.error("In sendMessageToChannel() channel.sendMessage", e, e?.body);
                    }
                    // console.log("Message Sent");

                    const lastMessageIndex =
                        (channel.lastMessage && channel.lastMessage.index) || 0;

                    await channel.updateLastReadMessageIndex(lastMessageIndex);

                    notifyUserAboutUnreadMessages(channel, msg);
                } catch (error) {
                    console.error("In sendMessageToChannel() catch", error, error?.body);
                }
            };
        },
        get getMessageFromChannel() {
            return async (channel) => {
                try {
                    // console.log("In getMessageFromChannel try", channel);
                    const messages = await channel.getMessages(getMessageCount);

                    // console.log("In getMessageFromChannel messages.items", messages.items)
                    dispatch({
                        type: twilioChatNamespacer("SET_MESSAGES"),
                        messages: messages.items,
                    });
                } catch (error) {
                    console.error("In getMessageFromChannel catch", error);
                }
            };
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatSection);
