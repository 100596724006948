import { createReducer, createNamespacer } from '../../utils/reducers';
import { HOMEPAGE } from '../../constants/namespacer';

const initialState = {
    getStreamApiKey: '',
    getStreamAppId: '',
    getStreamApiToken: '',
    getStreamFeedName: '',
    getStreamNotificationFeedName: '',
};

const homePageNamespacer = createNamespacer(HOMEPAGE);

const handlers = {
    [homePageNamespacer('SET_GETSTREAM_API_KEY')]: (state, action) => {
        return {
            ...state,
            getStreamApiKey: action.getStreamApiKey
        }
    },
    [homePageNamespacer('SET_GETSTREAM_API_ID')]: (state, action) => {
        return {
            ...state,
            getStreamAppId: action.getStreamAppId
        }
    },
    [homePageNamespacer('SET_GETSTREAM_API_TOKEN')]: (state, action) => {
        return {
            ...state,
            getStreamApiToken: action.getStreamApiToken
        }
    },
    [homePageNamespacer('SET_GETSTREAM_FEED_NAME')]: (state, action) => {
        return {
            ...state,
            getStreamFeedName: action.getStreamFeedName
        }
    },
    [homePageNamespacer('SET_GETSTREAM_NOTIFICATION_FEED_NAME')]: (state, action) => {
        return {
            ...state,
            getStreamNotificationFeedName: action.getStreamNotificationFeedName
        }
    },
};

const homePageReducer = createReducer(initialState, handlers, [HOMEPAGE]);

export default homePageReducer;

