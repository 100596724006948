import React, {Component} from 'react';
import { Form, FormGroup, Label, Input } from "reactstrap";
import Selector from "../../selector/Selector";
import { graduationYearList } from '../../../constants/graduationYear'
import { programTypes} from "../../../constants/programTypes"

class EditMyInfo extends Component {
    constructor(props){
        super(props);
        this.state= {
            universityList: [],
            workLocationList: [],
        };
    }

    handleNameChange = (e) => {
        this.props.setName(e.target.value);
    }

    handleLastNameChange = (e) => {
        this.props.setLastName(e.target.value);
    }

    handlEmailChange = (e) => {
        this.props.setEmail(e.target.value);
    }

    handleMbaProgram = (value) => {
        this.props.setMbaProgram(value);
    }

    handlelinkedinProfileUrl = (e) => {
        this.props.setLinkedinProfileUrl(e.target.value);
    }

    toggleIsEmailNotificationOn = () => {
        const { isEmailNotificationOn } = this.props;
        this.props.setIsEmailNotificationOn(!isEmailNotificationOn)
    }

    componentDidMount = () => {
        this.props.getUniversityList();
        this.props.getWorkLocationList();
    }

    render() {
        const { userType, name, lastName, email, linkedinProfileUrl, mbaProgram, colOrUniv, graduationYear, currentWorkLocation, isEmailNotificationOn } = this.props;
        return (
            <div className="form-wrapper edit-form-wrapper">
                <Form className="form-container">
                    <FormGroup>
                        <Label>Name</Label>
                        <Input
                            type="text"
                            name="name"
                            value={name}
                            placeholder="Enter your first name"
                            onChange={this.handleNameChange}
                            maxLength={25}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Last Name</Label>
                        <Input
                            type="text"
                            name="lastName"
                            value={lastName}
                            placeholder="Enter your last name"
                            onChange={this.handleLastNameChange}
                            maxLength={25}
                        />
                    </FormGroup>
                    {/* Required later */}
                    {/* <FormGroup className="error-field"> */}
                    <FormGroup>
                        <Label>Email Address (Username)</Label>
                        <Input
                            type="email"
                            name="email"
                            value={email}
                            placeholder="Enter your email"
                            data-tip data-for='error'
                            onChange={this.handlEmailChange}
                        />
                        {/* <span className="note-text">
                            Changing this email address will not change your GradShack username.
                        </span> */}
                        {/* Required later */}
                        {/* <div className="error-msg-wrap">
                            <ReactTooltip id='error' className="error-tooltip" effect="solid" data-tip-disable="true">
                                <span>Something went wrong</span>
                            </ReactTooltip>
                        </div> */}
                    </FormGroup>
                    {/* <FormGroup className="email-notification-group">
                        <div className="email-notification-wrapper">
                            <Label>Email Notification for Unread Messages</Label>
                        </div>
                        <div className="switch">
                            <Input
                                type="checkbox"
                                checked={isEmailNotificationOn}
                                onChange={this.toggleIsEmailNotificationOn}
                            />
                            <span className="slider round"></span>
                        </div>
                    </FormGroup> */}
                    <FormGroup>
                        <Label>LinkedIn Profile URL</Label>
                        <Input
                            name="linkedinProfileUrl"
                            value={linkedinProfileUrl}
                            placeholder="Enter your LinkedIn Profile URL"
                            onChange={this.handlelinkedinProfileUrl}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>University</Label>
                        <Selector
                            options={this.props.universityList}
                            universityList={this.props.universityList}
                            getUniversityListPrediction={this.props.getUniversityListPrediction}
                            functionToBeUsed='setColOrUniv'
                            setColOrUniv={this.props.setColOrUniv}
                            defaultValue={colOrUniv}
                        />
                    </FormGroup>
                    <FormGroup className="radio-wrapper">
                        <Label>Program</Label>
						<Selector 
						options={programTypes}
						functionToBeUsed="setMbaProgram"
						setMbaProgram={this.props.setMbaProgram}
						defaultValue={mbaProgram}
						/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Graduation Year</Label>
                        <Selector
                            options={graduationYearList}
                            functionToBeUsed='setGraduationYear'
                            setGraduationYear={this.props.setGraduationYear}
                            defaultValue={graduationYear}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            {`Current ${userType==='alumni' ? 'Work' : ''} Location`}
                        </Label>
                        <Selector
                            getLocationListPrediction={this.props.getLocationListPrediction}
                            options={this.props.workLocationList}
                            workLocationList={this.state.workLocationList}
                            functionToBeUsed='setCurrentWorkLocation'
                            setCurrentWorkLocation={this.props.setCurrentWorkLocation}
                            defaultValue={currentWorkLocation}
                            className="dropdown-work-location"
                        />
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

export default EditMyInfo;