import { createReducer, createNamespacer } from '../../utils/reducers';
import { ADMINHOMEPAGE } from '../../constants/namespacer';

const initialState = {
    pendingUserList: [],
    refreshPendingUserListRequired: false,
    showLoader: false,
    pendingUserData: '',
    pendingUserType: '',
}

const adminHomepageNamespacer = createNamespacer(ADMINHOMEPAGE);

const handlers = {
    [adminHomepageNamespacer('SET_PENDING_USER_LIST')]: (state, action) => {
        return{
            ...state,
            pendingUserList: action.pendingUserList,
            showLoader: action.showLoader,
        }
    },
    [adminHomepageNamespacer('SET_PEND_USER_REFRESH_REQ')]: (state, action) => {
        return{
            ...state,
            refreshPendingUserListRequired: action.refreshPendingUserListRequired,
        }
    },
    [adminHomepageNamespacer('SET_PENDING_USER_DATA')]: (state, action) => {
        return{
            ...state,
            pendingUserData: action.pendingUserData,
            showLoader: action.showLoader,
        }
    },
    [adminHomepageNamespacer('SET_PENDING_USER_TYPE')]: (state, action) => {
        return{
            ...state,
            pendingUserType: action.pendingUserType,
            showLoader: action.showLoader,
        }
    },
}

const adminLoginReducer = createReducer(initialState, handlers, [ADMINHOMEPAGE]);

export default adminLoginReducer;