import React, {Component} from 'react';
import DesktopHeader from '../../../containers/homePage/DesktopHeader';
import MobileMenu from '../../../containers/homePage/MobileMenu';
import { Redirect } from 'react-router-dom'

class MoreMenu extends Component {

    state = {
        redirectTo: null,
    }

    handleMoreMenuRoute = (path) => {
        this.setState({redirectTo: path})
    }

    render() {
        const { redirectTo } = this.state;
        return (
            <React.Fragment>
                <DesktopHeader/>
                <div className="more-menu-wrapper screen-height">
                    <div 
                        className="more-menu-items"
                        onClick={() => {this.handleMoreMenuRoute('/about')}}
                    >
                        <span className="menu-block">About Us</span>
                    </div>
                    <div 
                        className="more-menu-items"
                        onClick={() => {this.handleMoreMenuRoute('/faq')}}
                    >
                        <span className="menu-block">FAQs</span>
                    </div>
                </div>
                <MobileMenu />
                {redirectTo ? <Redirect to={redirectTo} /> : null}
            </React.Fragment>
        );
    }
}

export default MoreMenu;