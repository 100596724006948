import { connect } from 'react-redux';
import { createNamespacer } from '../../utils/reducers';
import { ADMINHOMEPAGE } from '../../constants/namespacer';
import { getPendingUserData, putApproveUser, putRejectUser } from '../../api/adminHomePage'

import AdminMoreUserDetail from '../../components/adminMoreUserDetail/AdminMoreUserDetail'

const adminHomepageNamespacer = createNamespacer(ADMINHOMEPAGE);

const mapStateToProps = (state) => {
    return {
        pendingUserData: state.adminHomePage.pendingUserData,
        showloader: state.adminHomePage.showLoader,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        getPendingUserData: async (pendingUserId) => {
            try {
                let response = await getPendingUserData(pendingUserId);

                if (response.status === 200) {
                    dispatch({
                        type: adminHomepageNamespacer('SET_PENDING_USER_DATA'),
                        pendingUserData: response.data.data,
                        showLoader: true,
                    })
                }
            }
            catch (error) {
                console.log(error);
            }
        },
        setStatusToApprove: async(pendingUserId) => {
            try{
                let bodyFormData = new FormData();
                bodyFormData.append('user[status]', 'approved' );

                let response = await putApproveUser(bodyFormData, pendingUserId);
                if(response.status===200){
                    dispatch({
                        type: adminHomepageNamespacer('SET_PEND_USER_REFRESH_REQ'),
                        refreshPendingUserListRequired: true,
                    })
                }
            }
            catch(error){
                console.log(error);
            }
        },
        setStatusToReject: async(pendingUserId, rejectionReason) => {
            try{
                let bodyFormData = new FormData();
                bodyFormData.append('user[status]', 'rejected');
                bodyFormData.append('user[rejection_reason]', 'rejectionReason');

                let response = await putRejectUser(bodyFormData, pendingUserId);
                if(response.status===200){
                    dispatch({
                        type: adminHomepageNamespacer('SET_PEND_USER_REFRESH_REQ'),
                        refreshPendingUserListRequired: true,
                    })
                }
            }
            catch(error){
                console.log(error);
            }
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminMoreUserDetail);

