import React, { Component } from "react";

class Filters extends Component {
    constructor(props){
        super(props);
        this.state = {
            selectAll: true,
            filteredSectorList: [],
        }
    }

    componentDidMount(){
        this.props.getSectorList();
        this.props.setSelectAllFilters(this.state.selectAll);
    }

    componentWillReceiveProps(nextProps){
        if(this.props.sectorList !== nextProps.sectorList){
            let filteredSectorList = nextProps.sectorList.length>0 ?
            nextProps.sectorList.map((sector) => {
                return {
                    ...sector,
                    isChecked: true
                }
            })
            : []
            this.setState({filteredSectorList})
        }
    }

    handleFilterSelectAll = (event) => {
        let filteredSectorList = this.state.filteredSectorList;
        let selectAll = this.state.selectAll

        filteredSectorList.forEach(sector => sector.isChecked = event.target.checked);
        if(event.target.checked){selectAll = true}
        else{selectAll = false;}

        this.setState({
            filteredSectorList,
            selectAll
        })
        this.props.setSelectedFilterList(this.state.filteredSectorList)
        this.props.setSelectAllFilters(selectAll);
    }

    handleFilterSelect = (event) => {
        let filteredSectorList = this.state.filteredSectorList;
        let selectAll = this.state.selectAll;
        let countIsChecked = 0;

        filteredSectorList.forEach(sector => {
            if(sector.attributes.id === Number(event.target.value)){
                sector.isChecked = event.target.checked
            }
            if(sector.isChecked){
                countIsChecked++
            }
        });

        if(filteredSectorList.length === countIsChecked){selectAll = true}
        else{selectAll = false}

        this.setState({
            filteredSectorList,
            selectAll
        })
        this.props.setSelectedFilterList(this.state.filteredSectorList);
        this.props.setSelectAllFilters(selectAll);
    }
    
    handleFilterClearAll = (event) => {
        let filteredSectorList = this.state.filteredSectorList;

        filteredSectorList.forEach(sector => sector.isChecked = false);
        const selectAll = false

        this.setState({
            filteredSectorList,
            selectAll,
        })
        this.props.setSelectedFilterList(this.state.filteredSectorList);
        this.props.setSelectAllFilters(selectAll);
    }
    
    render() {
        const { selectAll, filteredSectorList } = this.state;
        return(
            <div className="filters-wrapper">
                <div className="filter-title">
                    <span className="filter-head">Filters</span>
                </div>
                <div className="filter-options-container">
                    <div className="selectall-filter">
                        <div className="filter-option">
                            <input 
                                type="checkbox"
                                onChange={this.handleFilterSelectAll}
                                checked={selectAll}
                            />
                            <span>Select All</span>
                        </div>
                        <div 
                            className="clear-selected-options"
                            onClick={this.handleFilterClearAll}
                        >Clear All</div>
                    </div>
                    <div className="filter-option-section">
                        {
                            filteredSectorList.length ? (
                                filteredSectorList.map((sector, index) => (
                                    <div 
                                        key={index} 
                                        className="filter-option"
                                    >
                                        <input 
                                            type="checkbox"
                                            value={sector.id}
                                            checked={sector.isChecked}
                                            onChange={this.handleFilterSelect}
                                        />
                                        <span>{sector.attributes.name}</span>
                                    </div>
                                ))    
                            ) : null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Filters;
