import { connect } from "react-redux";
import AdditionalDetailsTab from "../../components/userForms/additionalDetailsTab/AdditionalDetailsTab";
import { ADDITIONALDETAILSTAB } from "../../constants/namespacer";
import { createNamespacer } from "../../utils/reducers";

const additionalDetailsTabNamespacer = createNamespacer(ADDITIONALDETAILSTAB);

const mapStateToProps = (state) => {
  return {
    additionalDetails: state.additionalDetailsTab.additionalDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAdditionalDetails: (value) => {
      dispatch({
        type: additionalDetailsTabNamespacer("SET_ADDITIONALDETAILSTAB_VALUE"),
        additionalDetails: value,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdditionalDetailsTab);
