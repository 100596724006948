import { connect } from 'react-redux';
import UserFormButtons from '../../components/userForms/userFormButtons/UserFormButtons';
import { createNamespacer } from '../../utils/reducers';
import { USERFORMDATA, USERTYPE, ADDITIONALDETAILSTAB, LOGIN, USERFORMTAB, USERLOGIN, NEWSFEED } from '../../constants/namespacer';
import { putUserData } from '../../api/userForms/putUserData'
import {loginUserInfo} from "../../api/userForms/loginUser";

const userFormDataNamespacer = createNamespacer(USERFORMDATA);
const userTypeNamespacer = createNamespacer(USERTYPE);
const additionalDetailsTabNamespacer = createNamespacer(ADDITIONALDETAILSTAB);
const loginNamespacer = createNamespacer(LOGIN);
const userFormTabReducer = createNamespacer(USERFORMTAB);
const userLoginNamespacer = createNamespacer(USERLOGIN);
const newsFeedNamespacer = createNamespacer(NEWSFEED);

const mapStateToProps = (state) => {
    return {
        profilePicUrl: state.yourInfoForm.profilePicUrl,
        name: state.yourInfoForm.name,
        lastName: state.yourInfoForm.lastName,
        email: state.yourInfoForm.email,
        linkedinProfileUrl: state.yourInfoForm.linkedinProfileUrl,
        mbaProgram: state.yourInfoForm.mbaProgram,
        colOrUniv: state.yourInfoForm.colOrUniv,
        graduationYear: state.yourInfoForm.graduationYear,
        currentWorkLocation: state.yourInfoForm.currentWorkLocation,
        provider: state.yourInfoForm.provider,
        concept: state.yourVentureForm.concept,
        venture: state.yourVentureForm.venture,
        needs: state.yourVentureForm.needs,
        sectors: state.yourSectorForm.sectors,
        currentFormTab: state.userFormTab.currentFormTab,
        additionalDetails: state.additionalDetailsTab.additionalDetails,
        privacyCheckboxIsChecked: state.yourInfoForm.privacyCheckboxIsChecked,
        userType: state.userType.userType,
        isInvestor: state.userType.isInvestor,
        feasibilityStudyAmount: state.yourVentureForm.feasibilityStudyAmount,
        investorType: state.yourVentureForm.investorType,
        userEmail: state.signUp.userEmail,
        userPassword: state.signUp.userPassword,
        isTokenValid: state.redirectAfterLogin.isTokenValid,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        clearFormData: async() => {
            dispatch({
                type: userFormDataNamespacer('SET_MBA_PROGRAM_VALUE'),
                mbaProgram: ''
            })
            dispatch({
                type: userFormDataNamespacer('SET_COL_OR_UNIV_VALUE'),
                colOrUniv: ''
            })
            dispatch({
                type: userFormDataNamespacer('SET_GRADUATION_YEAR_VALUE'),
                graduationYear: ''
            })
            dispatch({
                type: userFormDataNamespacer('SET_LINKEDIN_PROFILE_URL_VALUE'),
                linkedinProfileUrl: '',
            })
            dispatch({
                type: userFormDataNamespacer('SET_CURRENT_WORK_LOCATION_VALUE'),
                currentWorkLocation: ''
            })
            dispatch({
                type: userFormDataNamespacer('SET_CONCEPT_VALUE'),
                concept: ''
            })
            dispatch({
                type: userFormDataNamespacer('SET_VENTURE_VALUE'),
                venture: ''
            })
            dispatch({
                type: userFormDataNamespacer('SET_NEEDS_VALUE'),
                needs: ''
            })
            dispatch({
                type: userFormDataNamespacer('SET_SECTORS_VALUE'),
                sectors: []
            })
            dispatch({
                type: userFormDataNamespacer('SET_PRIVACY_CHECK_VALUE'),
                privacyCheckboxIsChecked: false
            })
            dispatch({
                type: userTypeNamespacer('SET_USERTYPE_VALUE'),
                userType: ''
            })
            dispatch({
                type: userTypeNamespacer('SET_IS_INVESTOR_VALUE'),
                isInvestor: false,
            })
            dispatch({
                type: additionalDetailsTabNamespacer('SET_ADDITIONALDETAILSTAB_VALUE'),
                additionalDetails: ''
            })
        },
        saveUserData: async(bodyFormData) => {
            try{
                let response = await putUserData(bodyFormData);
                if(response.status===200){
                    localStorage.removeItem('userSignupDetails')
                }
            }
            catch(error){
                console.log(error)
            }
        },
        userLoginInfo: async (id) => {
            try {
                let response = await loginUserInfo(id);
                if (response.status === 200) {
                    localStorage.setItem('id', response.data.user.id);
                    // localStorage.setItem('auth_code', response.data.auth_token);
                    if (response.data.auth_token !== null) {
                        localStorage.setItem('auth_code', response.data.auth_token);
                    }
                    localStorage.setItem('isLogin', true);
                    localStorage.setItem('isAdmin', false);
                    localStorage.setItem('deviceToken', response.data.user.pushy_token);
                    if (response.data.user.newsfeed) {
                        localStorage.setItem('getStreamApiKey', response.data.user.newsfeed.api_key);
                        localStorage.setItem('getStreamAppId', response.data.user.newsfeed.app_id);
                        localStorage.setItem('getStreamApiToken', response.data.user.newsfeed.newsfeed_token);
                        localStorage.setItem('getStreamFeedName', response.data.user.newsfeed.feed_name);
                        localStorage.setItem('getStreamNotificationFeedName', response.data.user.newsfeed.notification_feed_name);
                    }

                    dispatch({
                        type: loginNamespacer('SET_IS_AUTHENTICATED'),
                        isTokenValid: 'true'
                    });
                    dispatch({
                        type: newsFeedNamespacer('SET_GETSTREAM_API_KEY'),
                        getStreamApiKey: response.data.user.newsfeed.api_key
                    });
                    dispatch({
                        type: newsFeedNamespacer('SET_GETSTREAM_API_ID'),
                        getStreamAppId: response.data.user.newsfeed.app_id
                    });
                    dispatch({
                        type: newsFeedNamespacer('SET_GETSTREAM_API_TOKEN'),
                        getStreamApiToken: response.data.user.newsfeed.newsfeed_token
                    });
                    dispatch({
                        type: newsFeedNamespacer('SET_GETSTREAM_FEED_NAME'),
                        getStreamFeedName: response.data.user.newsfeed.feed_name
                    });
                    dispatch({
                        type: newsFeedNamespacer('SET_GETSTREAM_NOTIFICATION_FEED_NAME'),
                        getStreamNotificationFeedName: response.data.user.newsfeed.notification_feed_name
                    });
                }
            } catch (error) {
                console.error(error);
                if (error.response && error.response.data) {
                    dispatch({
                        type: userFormDataNamespacer('RESET_YOUR_FORM_DATA')
                    })
                    dispatch({
                        type: userFormTabReducer('SET_CURRENT_FORM_TAB'),
                        currentFormTab: '1'
                    })
                    dispatch({
                        type: userLoginNamespacer('RESET_FIELDS'),
                    })
                    dispatch({
                        type: userFormDataNamespacer('SET_SECTORS_VALUE'),
                        sectors: [],
                    })
                    dispatch({
                        type: additionalDetailsTabNamespacer('RESET_ADDITIONALDETAILSTAB_VALUE'),
                        additionalDetails: ''
                    })
                    dispatch({
                        type: userTypeNamespacer('RESET_USER_TYPE_FIELDS'),
                    })
                    const errorMessages = error.response.data.error;
                    if (errorMessages) {
                        alert(errorMessages)
                    }
                }
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserFormButtons);