import { createReducer, createNamespacer } from '../utils/reducers';
import { NEWSFEED, PUSHY } from '../constants/namespacer';

const initialState = {
    newsFeedList: [],
    getStreamApiKey: '',
    getStreamAppId: '',
    getStreamApiToken: '',
    getStreamFeedName: '',
    getStreamNotificationFeedName: '',
    deviceToken: '',
};

const newsFeedNamespacer = createNamespacer(NEWSFEED);
const pushyNamespacer = createNamespacer(PUSHY);

const handlers = {
    [newsFeedNamespacer('SET_NEWS_FEED_LIST')]: (state, action) => {
        return{
            ...state,
            newsFeedList: action.newsFeedList,
        }
    },
    [newsFeedNamespacer('SET_GETSTREAM_API_KEY')]: (state, action) => {
        return {
            ...state,
            getStreamApiKey: action.getStreamApiKey
        }
    },
    [newsFeedNamespacer('SET_GETSTREAM_API_ID')]: (state, action) => {
        return {
            ...state,
            getStreamAppId: action.getStreamAppId
        }
    },
    [newsFeedNamespacer('SET_GETSTREAM_API_TOKEN')]: (state, action) => {
        return {
            ...state,
            getStreamApiToken: action.getStreamApiToken
        }
    },
    [newsFeedNamespacer('SET_GETSTREAM_FEED_NAME')]: (state, action) => {
        return {
            ...state,
            getStreamFeedName: action.getStreamFeedName
        }
    },
    [newsFeedNamespacer('SET_GETSTREAM_NOTIFICATION_FEED_NAME')]: (state, action) => {
        return {
            ...state,
            getStreamNotificationFeedName: action.getStreamNotificationFeedName
        }
    },
    [newsFeedNamespacer('RESET_NEWS_FEED_FIELDS')]: (state, action) => {
        return {
            ...initialState,
        }
    },
    [pushyNamespacer('DEVICE_TOKEN')]: (state, action) => {
        return {
            ...state,
            deviceToken: action.deviceToken
        }
    },
};

const newsFeedReducer = createReducer(initialState, handlers, [NEWSFEED, PUSHY]);

export default newsFeedReducer;
