import React, { Component } from "react";
import { Table, FormGroup, Label, Input } from "reactstrap";
import _ from "lodash";

class EditNotificationsForm extends Component {
  state = {
    options: this.props.notificationOptions,
  };

  componentDidMount = () => {
    this.setState({
      options: this.props.notificationOptions,
    })
  };

  componentDidUpdate(prevProps, prevState) {}

  handleToggleNotificationOptionValue(option, type, rowType, groupType) {
    this.setState(prevState => {
        const newOptions = {
            ...prevState.options,
            [groupType]: {
                ...prevState.options[groupType],
                [rowType]: {
                    ...prevState.options[groupType][rowType],
                    [type]: !option[type]
                }
            }
        };

        this.props.setNotificationOptions(newOptions);

        return { options: newOptions };
    });
  }

  componentWillReceiveProps(nextProps) {
      if (_.isEmpty(nextProps.notificationOptions)) {
          return false;
      }
      if (!_.isEqual(nextProps.notificationOptions, this.notificationOptions)) {
          this.setState({ options: nextProps.notificationOptions });
      }
  }

  inputCell = () => {
    return (
      <FormGroup check>
        <Label check>
          <Input type="radio" name="radio1" />
        </Label>
      </FormGroup>
    );
  };

  inputSlider = (value, option, type, rowType, groupType) => {
      // const rowTypeValue = groupType === 'sectors' ? 'all' : rowType;
      // const disabled = !(this.props.notificationRules[groupType]
      //     && this.props.notificationRules[groupType][rowTypeValue]
      //     && this.props.notificationRules[groupType][rowTypeValue][type]);
    return (
      <FormGroup className="email-notification-group">
        <div className={`switch notification-slider`}>
          <Input
              type="checkbox"
              checked={`${value ? 'checked' : ''}`}
              onChange={() => (this.handleToggleNotificationOptionValue(option, type, rowType, groupType))}
          />
          <span className="slider round"></span>
        </div>
      </FormGroup>
    );
  };

  inputRow = (option, rowType, groupType) => {
      // let rowExists = true;
      // if (groupType === 'sectors') {
      //     rowExists = false;
      //     const sector = this.props.sectors.find(item => {
      //         let found = item.name === option.label;
      //         if (item.name === 'Media, Telecom & Entertainment' && option.label === 'Media, Art & Entertainment') {
      //             found = true;
      //         }
      //         return found;
      //     });
      //     if (sector) {
      //         rowExists = true;
      //     }
      // }
      // if (groupType === 'typesPosts') {
      //     rowExists = false;
      //     if (rowType === 'fullTimeRole') {
      //         // student is idea seeker on sign up
      //         rowExists = this.props.userType === 'student' && this.props.notificationRules[groupType].hasOwnProperty(rowType);
      //     }
      //     if (rowType === 'advising') {
      //         rowExists = this.props.userType === 'advisor' && this.props.notificationRules[groupType].hasOwnProperty(rowType);
      //     }
      // }
      return (
        <>
          {
            // rowExists &&
            groupType === "general" ? (
              option.label !== "Site Announcements" ? (
                <tr>
                  <td>{option.label}</td>
                  <td>
                    {this.inputSlider(
                      option.email,
                      option,
                      "email",
                      rowType,
                      groupType
                    )}
                  </td>
                  {/* <td>{this.inputSlider(option.email, option, 'email', rowType, groupType)}</td> */}
                </tr>
              ) : null
            ) : (
              <tr>
                <td>{option.label}</td>
                {/* <td>{this.inputSlider(option.push, option, 'push', rowType, groupType)}</td> */}
                <td>
                  {this.inputSlider(
                    option.email,
                    option,
                    "email",
                    rowType,
                    groupType
                  )}
                </td>
              </tr>
            )
          }
        </>
      );
  };

  inputGroup = (entries, groupType) => {
    return entries.map((pair, index) => this.inputRow(pair[1], pair[0], groupType));
  };

  render() {
    const { options } = this.state;

    return (
      <div className="form-wrapper edit-form-wrapper">
        <div className="studentForm-head-block">
          <h6>Email Notification Preferences</h6>
        </div>
        <div className="form-container notification-form">
            {
              options && Object.keys(options).length > 0 &&
              <Table striped>
                  <thead>
                  {/* <tr>
                      <th></th>
                      <th className="notification-header">Push</th>
                      <th className="notification-header">Email Digest</th>
                  </tr> */}
                  </thead>
                  <tbody>
                  <tr>
                      <td colSpan="3" className="notification-subtitle">
                          General
                      </td>
                  </tr>
                  {this.inputGroup(Object.entries(options.general || {}), 'general')}
                  <tr>
                      <td colSpan="3" className="notification-subtitle">
                          Sector-Specific Posts
                      </td>
                  </tr>
                  {this.inputGroup(Object.entries(options.sector || {}), 'sector')}
                  {/* <tr>
                      <td colSpan="3" className="notification-subtitle">
                          Types of Posts
                      </td>
                  </tr>
                  {this.inputGroup(Object.entries(options.typesPosts || {}), 'typesPosts')} */}
                  </tbody>
              </Table>
            }
        </div>
      </div>
    );
  }
}

export default EditNotificationsForm;
