import React, { useState } from "react";
import ChatHeader from "./chatHeader/ChatHeader";
import ChatSection from "../../containers/userConversation/ChatSection";

const UserConversation = ({ userDetail, toggleMessage }) => {
    const [openProfileSection, setSetOpenProfileSection] = useState(false);
    const [isMinimizeChat, setIsMinimizeChat] = useState(false);

    const viewProfile = () => {
        setSetOpenProfileSection((prev) => !prev);
    };

    const minimizeChat = () => {
        setIsMinimizeChat((prev) => !prev);
    };

    return (
        <div className={"userChat-wrapper" + (isMinimizeChat ? " user-chat-minimize" : "")}>
            <div className={"userChat-content" + (openProfileSection ? " user-overlay" : "")}>
                <ChatHeader
                    userDetail={userDetail}
                    viewProfile={viewProfile}
                    openProfileSection={openProfileSection}
                    isMinimizeChat={isMinimizeChat}
                    minimizeChat={minimizeChat}
                    toggleMessage={toggleMessage}
                />

                <ChatSection userDetail={userDetail} openProfileSection={openProfileSection} />
            </div>
        </div>
    );
};

export default UserConversation;
