import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, Button, Form, FormGroup, Label } from 'reactstrap';

class FavouriteModal extends Component {
    constructor(props){
        super(props)
        this.state ={
            favouriteNote: '',
            favoriteUserId: '',
            favoriteUserName: '',
            favUnfavModalLabel: '',
            favoriteUsersList: '',
            isFavorite: '',
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.favoriteUserId !== this.props.favoriteUserId){
            this.setState({
                favoriteUserId: nextProps.favoriteUserId
            })
        }
        if(nextProps.favoriteUserName !== this.props.favoriteUserName){
            this.setState({
                favoriteUserName: nextProps.favoriteUserName
            })
        }
        if(nextProps.favUnfavModalLabel !== this.props.favUnfavModalLabel){
            this.setState({
                favUnfavModalLabel: nextProps.favUnfavModalLabel
            })
        }
        if(nextProps.favoriteUsersList !== this.props.favoriteUsersList){
            this.setState({
                favoriteUsersList: nextProps.favoriteUsersList
            })
        }
        if(nextProps.isFavorite !== this.props.isFavorite){
            this.setState({
                isFavorite: nextProps.isFavorite
            })
        }
    }

    handleFavoriteNote = (event) => {
        this.setState({
            favouriteNote: event.target.value
        })
    }

    handleSetFavorite = () => {
        const { favoriteUserId, favouriteNote } = this.state;
        if (window.hasOwnProperty('gtag')) {
            window.gtag('event', 'favourite_user', {
                event_category: 'User',
                event_label: 'Favourite',
                value: 'Favourite User ' + favoriteUserId + ' with note: ' + favouriteNote,
            });
        }
        this.props.setFavorite(favoriteUserId, favouriteNote);
        this.props.favouriteModalToggle();
    };

    handleSetUnfavorite = () => {
        const { favoriteUserId, favoriteUsersList } = this.state;
        let favoriteUsersDeatils = favoriteUsersList
                                .filter(favoriteUserDetail => (
                                    favoriteUserDetail.userId === favoriteUserId)
                                );
        let favouriteId = favoriteUsersDeatils[0].favouriteId;
        if (window.hasOwnProperty('gtag')) {
            window.gtag('event', 'favourite_user', {
                event_category: 'User',
                event_label: 'Unfavourite',
                value: 'Unfavourite User ' + favoriteUserId,
            });
        }
        this.props.setUnfavorite(favouriteId);
        this.props.favouriteModalToggle();
    };
    
    render() {
        const { favoriteUserName, favUnfavModalLabel, isFavorite} = this.state;
        return (
            <div>
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="favourite-modal">
            {
            isFavorite ? 
            <>
                <ModalHeader toggle={this.props.toggle} className="modal-head">You have favorited</ModalHeader>
                <ModalBody className="favourite-details">
                    <span className="favourite-name">{favoriteUserName}</span>
                </ModalBody>
            </>
            : <>
                <ModalHeader toggle={this.props.toggle} className="modal-head">{favUnfavModalLabel}</ModalHeader>
                <ModalBody className="favourite-details">
                   <span className="favourite-name">{favoriteUserName}</span>
                   <Form className="favourite-form">
                   {
                       favUnfavModalLabel==='You are favoriting' ? 
                        <>
                            <FormGroup>
                                <Label for="exampleEmail">Add Notes (optional) - Maximum 75 Characters</Label>
                                <textarea 
                                    maxLength="75"
                                    onChange={this.handleFavoriteNote}
                                />
                            </FormGroup>
                            <Button
                                onClick={this.handleSetFavorite}
                            >Confirm</Button>
                        </> : ''
                    }
                    {
                        favUnfavModalLabel==='You are unfavoriting' ? 
                        <Button
                            onClick={this.handleSetUnfavorite}
                        >Confirm</Button>
                        : ''
                   }  
                    </Form>
                </ModalBody>
            </>
            }
            </Modal>
        </div>
        );
    }
}

export default FavouriteModal;