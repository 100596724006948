import React, { Component } from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { Redirect } from "react-router-dom";
import Selector from "../../selector/Selector";
import UserFormButtons from "../.././../containers/userForms/UserFormButtons";
import { graduationYearList } from "../../../constants/graduationYear";
import { programTypes} from "../../../constants/programTypes"
import profilePic from "../../../commons/images/profile_pic.svg";
// import ToastErrorMsg from '../../toastErrorMsg/ToastErrorMsg';
import UserFormModal from "../../userForms/userFormButtons/UserFormModal";
import camera from "../../../commons/images/camera.svg";
import PrivacyModal from "../../staticPages/privacyPolicy";
import TermsModal from "../../staticPages/terms";

class YourInfoForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            universityList: [],
            workLocationList: [],
            userFormModal: false,
            privacyCheckboxIsChecked: false,
            profilePicDisplay: typeof this.props.profilePicUrl === "object" ? "" : this.props.profilePicUrl,
            errorMessage: '',
        };
        this.userFormModalToggle = this.userFormModalToggle.bind(this);
        this.privacyModalToggle = this.privacyModalToggle.bind(this);
        this.termsModalToggle = this.termsModalToggle.bind(this);
    }
    userFormModalToggle() {
        this.setState({
            userFormModal: !this.state.userFormModal,
        });
    }

    privacyModalToggle() {
        this.setState({
            privacyModal: !this.state.privacyModal,
        });
    }

    termsModalToggle() {
        this.setState({
            termsModal: !this.state.termsModal,
        });
    }

    static getDerivedStateFromProps(props, state) {
        const {
            currentFormTab,
            name,
            email,
            mbaProgram,
            colOrUniv,
            graduationYear,
            currentWorkLocation,
            lastName,
        } = props;
        const { privacyCheckboxIsChecked } = state;
        if (currentFormTab === "1") {
            let reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
            if (email && !reg.test(email)) {
                props.setFieldsMissing("Valid Email Address");
            } else if (name && lastName && email && colOrUniv && mbaProgram && graduationYear && currentWorkLocation) {
                props.setFieldsMissing(undefined);
            } else if (!name) {
                props.setFieldsMissing("Name");
            } else if (!lastName) {
                props.setFieldsMissing("Last Name");
            } else if (!email) {
                props.setFieldsMissing("Email");
            } else if (!colOrUniv) {
                props.setFieldsMissing("Program Name");
            } else if (!mbaProgram) {
                props.setFieldsMissing("Program Type");
            } else if (!graduationYear) {
                props.setFieldsMissing("Graduation Year");
            } else if (!currentWorkLocation) {
                props.setFieldsMissing("Location");
            } else if (!privacyCheckboxIsChecked) {
                props.setFieldsMissing("Terms and Conditions");
            }
        }
        return {};
    }

    handleNameChange = (e) => {
        this.props.setName(e.target.value);
    };

    handleLastNameChange = (e) => {
        this.props.setLastName(e.target.value);
    };

    handlEmailChange = (e) => {
        this.props.setEmail(e.target.value);
    };

    handlelinkedinProfileUrl = (e) => {
        this.props.setLinkedinProfileUrl(e.target.value);
    };

    handleMbaProgram = (value) => {
        this.props.setMbaProgram(value.value);
    };

    handleProfilePicChange = (value) => {
        this.setState({
            errorMessage: '',
            profilePicDisplay: value,
        });
    };

    handleTermsCheckbox = (e) => {
        const { privacyCheckboxIsChecked } = this.state;
        this.props.setPrivacyCheck(!privacyCheckboxIsChecked);
        this.setState({ privacyCheckboxIsChecked: !privacyCheckboxIsChecked });
    };

    componentDidMount = () => {
        this.props.getUserData();
        this.props.getUniversityList();
        this.props.getWorkLocationList();
    };

    render() {
        // const { userType, profilePicUrl, name, lastName, email, fieldsMissing } = this.props;
        const { profilePicUrl, name, lastName, email, linkedinProfileUrl, 
			colOrUniv, graduationYear, currentWorkLocation, mbaProgram } = this.props;
        const { privacyCheckboxIsChecked } = this.state;

        const imgSrc = this.state.profilePicDisplay ? this.state.profilePicDisplay : typeof profilePicUrl === "string" ? profilePicUrl : profilePic

        return (
            <div className="form-wrapper form-wrapper-user-info">
                {/* <ToastErrorMsg fieldsMissing={fieldsMissing} customClass="userinfo-toast" /> */}
                <div className="profile-picture">
                    <img
                        src={imgSrc}
                        alt="no_image"
                        onClick={this.userFormModalToggle}
						onError={(e)=>{e.target.onerror = null; e.target.src=profilePic}}
                    />
                    <img src={camera} alt="camera" className="camera" onClick={this.userFormModalToggle} />
                </div>
                <UserFormModal
                    isOpen={this.state.userFormModal}
                    toggle={this.userFormModalToggle}
                    profilePic={imgSrc}
                    handleProfilePicChange={this.handleProfilePicChange}
                    setProfilePic={this.props.setProfilePic}
                />
                <Form className="form-container">
                    <FormGroup>
                        <Label>First Name</Label>
                        <Input
                            type="text"
                            name="name"
                            value={name || ""}
                            placeholder="Enter your first name"
                            onChange={this.handleNameChange}
                            maxLength={25}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Last Name</Label>
                        <Input
                            type="text"
                            name="lastName"
                            value={lastName || ""}
                            placeholder="Enter your last name"
                            onChange={this.handleLastNameChange}
                            maxLength={25}
                        />
                    </FormGroup>
                    {/* <FormGroup>
                        <Label>Email</Label>
                        <Input
                            type="email"
                            name="email"
                            value={email}
                            placeholder="Enter your email"
                            onChange={this.handlEmailChange}
                            disabled
                        />
                        <span className="note-text">
                            Your email will not be shared with other users.
                        </span>
                    </FormGroup> */}
                    <FormGroup>
                        <Label>LinkedIn Profile URL</Label>
                        <Input
                            type="url"
                            name="linkedinProfileUrl"
							value={linkedinProfileUrl || ""}
                            placeholder="Enter your LinkedIn Profile URL"
                            onChange={this.handlelinkedinProfileUrl}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>University</Label>
                        <Selector
                            options={this.props.universityList}
                            universityList={this.props.universityList}
                            getUniversityListPrediction={this.props.getUniversityListPrediction}
                            functionToBeUsed="setColOrUniv"
                            setColOrUniv={this.props.setColOrUniv}
							defaultValue={colOrUniv}
                        />
                    </FormGroup>
                    <FormGroup className="radio-wrapper">
                        <Label>Program</Label>
						<Selector 
						options={programTypes}
						functionToBeUsed="setMbaProgram"
						setMbaProgram={this.props.setMbaProgram}
						defaultValue={mbaProgram}
						/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Graduation Year</Label>
                        <Selector
                            options={graduationYearList}
                            functionToBeUsed="setGraduationYear"
                            setGraduationYear={this.props.setGraduationYear}
							defaultValue={graduationYear}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Current Location</Label>
                        <Selector
                            getLocationListPrediction={this.props.getLocationListPrediction}
                            options={this.props.workLocationList}
                            workLocationList={this.state.workLocationList}
                            functionToBeUsed="setCurrentWorkLocation"
                            setCurrentWorkLocation={this.props.setCurrentWorkLocation}
							defaultValue={currentWorkLocation}
                        />
                    </FormGroup>
                    <p className="privacy-link-in-user mt-0 justify-content-start">
                        <Input
                            type="checkbox"
                            checked={privacyCheckboxIsChecked}
                            onChange={this.handleTermsCheckbox}
                            id="check1"
                        />
                        <label>
                            I have reviewed and agree to GradShack's{" "}
                            <span onClick={this.privacyModalToggle}>Privacy Policy</span> and{" "}
                            <span onClick={this.termsModalToggle}>Terms of Use</span>
                        </label>
                    </p>
					<PrivacyModal isOpen={this.state.privacyModal} toggle={this.privacyModalToggle} />
					<TermsModal isOpen={this.state.termsModal} toggle={this.termsModalToggle} />
                    <div style={{display: "flex", alignItems: "center"}} className="wrapper-error-message">
                    <UserFormButtons
                        style={{ border: "1px solid red"}}
                        history={this.props.history}
                        setCurrentFormTab={this.props.setCurrentFormTab}
                        setError = {(message) => {this.setState({errorMessage: message})}}
                        conatinerStyles={this.state.errorMessage && {width: '55%'}}
                    />
                    {this.state.errorMessage ? <div className="error-message border">{this.state.errorMessage}</div> : null}
                    </div>
                </Form>
                {!this.props.isTokenValid ? <Redirect to="/" /> : null}
            </div>
        );
    }
}

export default YourInfoForm;
