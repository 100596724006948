export const AdminHeaderRoutes = [
    {
        'toRoute': '/pendingAlumni',
        'activeScreenVal': 'pendingAlumni',
        'label': 'Pending Alumni',
    },
    {
        'toRoute': '/pendingStudents',
        'activeScreenVal': 'pendingStudents',
        'label': 'Pending Students',
    },
    {
        'toRoute': '/pendingAdvisors',
        'activeScreenVal': 'pendingAdvisors',
        'label': 'Pending Advisors',
    },
    {
        'toRoute': '/existingUsers',
        'activeScreenVal': 'existingUsers',
        'label': 'Existing Users',
    },
    {
        'toRoute': '/notifications',
        'activeScreenVal': 'notifications',
        'label': 'Notifications',
        'icon': true
    },
]