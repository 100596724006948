import React, { Component } from "react";
import MessageBlockHeader from "./messageBlockHeader/MessageBlockHeader";
import MessageBlockChatSection from "../../../containers/messages/MessageBlockChatSection";

class MessageBlock extends Component {
    state = {};

    constructor(props) {
        super(props);
        this.state = {
            openProfileSection: false
        };
        this.viewProfile = this.viewProfile.bind(this);
    }

    componentWillUnmount() {
      this.props.setSendMessageText("")
    }

    viewProfile() {
        this.setState({
            openProfileSection: !this.state.openProfileSection
        });
    }

    render() {
        const { messages, secondUserData } = this.props;
        return (
            <div className="message-right-container">
                <div
                    className={
                        "messageChat-content" +
                        (this.state.openProfileSection ? " user-overlay" : "")
                    }
                >
                    <MessageBlockHeader
                        secondUserData={secondUserData}
                        viewProfile={this.viewProfile}
                        openProfileSection={this.state.openProfileSection}
                        toggleViewChat={this.props.toggleViewChat}
                    />
                    <MessageBlockChatSection
                        messages={messages}
                        secondUserData={secondUserData}
                        viewProfile={this.viewProfile}
                        openProfileSection={this.state.openProfileSection}
                    />
                </div>
            </div>
        );
    }
}

export default MessageBlock;
