import { createReducer, createNamespacer } from '../../utils/reducers';
import { ADMINHOMEPAGE } from '../../constants/namespacer';

const initialState = {
    existingUserList: [],
    existingUserType: 'alumni',
    filteredExistingUserList: [],
    showLoader: false
}

const adminHomepageNamespacer = createNamespacer(ADMINHOMEPAGE);

const handlers = {
    [adminHomepageNamespacer('SET_EXISTING_USER_LIST')]: (state, action) => {
        return{
            ...state,
            existingUserList: action.existingUserList,
            showLoader: action.showLoader
        }
    },
    [adminHomepageNamespacer('SET_EXISTING_USER_TYPE')]: (state, action) => {
        return{
            ...state,
            existingUserType: action.existingUserType,
        }
    },
    [adminHomepageNamespacer('SET_FILTERED_EXISTING_USER_LIST')]: (state, action) => {
        return{
            ...state,
            filteredExistingUserList: action.filteredExistingUserList,
            showLoader: action.showLoader
        }
    },
}

const adminExistingUsersReducer = createReducer(initialState, handlers, [ADMINHOMEPAGE]);

export default adminExistingUsersReducer;