import { connect } from 'react-redux';
import NewsFeed from '../components/newsFeed/NewsFeed';
import { createNamespacer } from '../utils/reducers';
import { NEWSFEED, PUSHY, USERFORMDATA, ADDITIONALDETAILSTAB } from '../constants/namespacer';
import { getUserData } from "../api/commonApi/getUserData";
import { putUserData } from "../api/userForms/putUserData";

const newsFeedNamespacer = createNamespacer(NEWSFEED);
const userFormDataNamespacer = createNamespacer(USERFORMDATA);
const pushyNamespacer = createNamespacer(PUSHY);
const additionalDetailsTabNamespacer = createNamespacer(ADDITIONALDETAILSTAB);


const mapStateToProps = (state) => {
    return {
        newsFeedList: state.newsFeed.newsFeedList,
        getStreamApiKey: state.newsFeed.getStreamApiKey,
        getStreamAppId: state.newsFeed.getStreamAppId,
        getStreamApiToken: state.newsFeed.getStreamApiToken,
        getStreamFeedName: state.newsFeed.getStreamFeedName,
        getStreamNotificationFeedName: state.newsFeed.getStreamNotificationFeedName,
        deviceToken: state.newsFeed.deviceToken,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setNewsFeedList: (value) => {
            dispatch({
                type: newsFeedNamespacer('SET_NEWS_FEED_LIST'),
                newsFeedList: value
            });
        },
        checkInitialNewsFeedSettings: async() => {
            const apiKey = localStorage.getItem('getStreamApiKey');
            const appId = localStorage.getItem('getStreamAppId');
            const token = localStorage.getItem('getStreamApiToken');
            const feedName = localStorage.getItem('getStreamFeedName');
            const notificationFeedName = localStorage.getItem('getStreamNotificationFeedName');

            dispatch({
                type: newsFeedNamespacer('SET_GETSTREAM_API_KEY'),
                getStreamApiKey: apiKey
            });
            dispatch({
                type: newsFeedNamespacer('SET_GETSTREAM_API_ID'),
                getStreamAppId: appId
            });
            dispatch({
                type: newsFeedNamespacer('SET_GETSTREAM_API_TOKEN'),
                getStreamApiToken: token
            });
            dispatch({
                type: newsFeedNamespacer('SET_GETSTREAM_FEED_NAME'),
                getStreamFeedName: feedName
            });
            dispatch({
                type: newsFeedNamespacer('SET_GETSTREAM_NOTIFICATION_FEED_NAME'),
                getStreamNotificationFeedName: notificationFeedName
            });
        },
        getUserData: async() => {
            try {
                let response = await getUserData();
                if (response.status === 200) {
                    const { attributes } = response.data.data;

                    const profilePic = attributes['image-url'];

                    dispatch({
                        type: userFormDataNamespacer('SET_PROFILE_PIC_URL'),
                        profilePicUrl: profilePic
                    })
                    dispatch({
                        type: additionalDetailsTabNamespacer('SET_ADDITIONALDETAILSTAB_VALUE'),
                        additionalDetails: ''
                    })
                    dispatch({
                        type: userFormDataNamespacer('SET_CONCEPT_VALUE'),
                        concept: ''
                    })
                    dispatch({
                        type: userFormDataNamespacer('SET_VENTURE_VALUE'),
                        venture: ''
                    })
                    dispatch({
                        type: userFormDataNamespacer('SET_NEEDS_VALUE'),
                        needs: ''
                    })
                }
            }
            catch(error) {
                console.log(error);
            }
        },
        getPushyToken: () => {
            return '609fdb3ebe50e00f1b8f5732';
        },
        sendPushyDeviceToken: async (deviceToken) => {
            try {
                let bodyFormData = new FormData();
                bodyFormData.append('user[pushy_token]', deviceToken);
                let response = await putUserData(bodyFormData);
                dispatch({
                    type: pushyNamespacer('REGISTER_PUSHY_DEVICE'),
                    isPushyDeviceRegistered: true,
                });
            }
            catch (error) {
                console.error(error, "sendPushyDeviceToken");
            }
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsFeed);
