import { connect } from "react-redux";
import NeedsItem from "../../components/userForms/yourVentureForm/NeedsItem/NeedsItem";

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(NeedsItem);
