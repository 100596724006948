import React, { Component } from "react";
import DesktopHeader from "../../containers/homePage/DesktopHeader";
import MobileMenu from "../../containers/homePage/MobileMenu";

class About extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <DesktopHeader />
        <div className="about-wrapper">
          <div className="edit-details-container about-us-subheader">
            <span>Use Cases</span>
          </div>
          <div className="custom-profile-container aboutus-container">
            <p className="about-text">
              GradShack leverages the community around your alma mater or
              current school to help you find:
            </p>
            <p className="about-text">
              - Someone to run with your business idea that you’re too busy to
              pursue, or an early hire or advisor for your startup.
            </p>
            <p className="about-text">
              - Someone with a business idea or startup you’d be suitable to
              cofound or advise.
            </p>
            <p className="about-text">
              - Referrals/advice to accelerate your launch (e.g. “Looking for a
              cost-effective developer to build me an MVP-lite”).
            </p>
          </div>
        </div>
        <MobileMenu />
      </React.Fragment>
    );
  }
}

export default About;
