import { createReducer, createNamespacer } from '../utils/reducers';
import { FAVOURITETIMELINEITEM } from '../constants/namespacer';

const initialState = {
    user: {},
    users: {},
};

const favouriteTimelineItemNamespacer = createNamespacer(FAVOURITETIMELINEITEM);

const handlers = {
    [favouriteTimelineItemNamespacer('GET_FAVOURITE_TIMELINE_USER')]: (state, action) => {
        const users = state.users;
        users[action.user.id] = action.user;
        return {
            ...state,
            users: { ...users },
            user: action.user,
        }
    }
};

const favouriteTimelineItemReducer = createReducer(initialState, handlers, [FAVOURITETIMELINEITEM]);

export default favouriteTimelineItemReducer;
