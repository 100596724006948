import React, { Component } from "react";
import { Label } from "reactstrap";
import UserFormButtons from "../.././../containers/userForms/UserFormButtons";

class AdditionalDetailsTab extends Component {
  handleAdditionalDetails = (event) => {
    this.props.setAdditionalDetails(event.target.value);
  };

  render() {
    return (
      <div className="form-wrapper">
        <div className="form-container p-4">
          <div className="additional-block">
            <Label>Additional details on your interests (Maximum 90 characters)</Label>
            <textarea
              maxLength="90"
              onChange={this.handleAdditionalDetails}
            ></textarea>
          </div>
          <UserFormButtons
            history={this.props.history}
            setCurrentFormTab={this.props.setCurrentFormTab}
            showLinkedInDetails={this.props.showLinkedInDetails}
            setShowLinkedInDetails={this.props.setShowLinkedInDetails}
          />
        </div>
      </div>
    );
  }
}

export default AdditionalDetailsTab;
