const httpsPrefix = "https://";
const httpPrefix = "http://";
const wwwPrefix = "www.";

const requiredPrefix = httpsPrefix + wwwPrefix;

const insertSubStr = (str, substr, position) => {
    return [str.slice(0, position), substr, str.slice(position)].join("");
};

export const isValidURLByRegex = (url) => {
    var pattern = /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub|company|school)\/[a-zA-Z0-9-_.]+(\/.*)?$/;
    return pattern.test(url);
};

export const sanitizedURL = (urlStr) => {
    let url = urlStr.trim();

    if (url.substr(0, httpsPrefix.length) === httpsPrefix) {
        // started with https://
        url = santizedUrlBeginsWithHttps(url);
    } else if (url.substr(0, httpPrefix.length) === httpPrefix) {
        // started with http://
        // replace http:// with https://
        url = url.replace(httpPrefix, httpsPrefix);

        url = santizedUrlBeginsWithHttps(url);
    } else {
        // starte with either www.google.com, google.com

        if (url.substr(0, wwwPrefix.length) === wwwPrefix) {
            // started with www.
            url = httpsPrefix + url;
        } else {
            // started with google.com

            // modifying google.com to https://www.google.com
            url = httpsPrefix + wwwPrefix + url;
        }
    }

    return url;
};

const santizedUrlBeginsWithHttps = (url) => {
    if (url.substr(0, requiredPrefix.length) === requiredPrefix) {
        // started with https://www.google.com
    } else {
        // started with https://google.com
        const position = httpsPrefix.length;

        url = insertSubStr(url, wwwPrefix, position);
    }

    return url;
};
