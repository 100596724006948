import { connect } from 'react-redux';
import UserNewsFeed from '../components/userNewsFeed/UserNewsFeed';
import { createNamespacer } from '../utils/reducers';
import { USERNEWSFEED } from '../constants/namespacer';

const userNewsFeedNamespacer = createNamespacer(USERNEWSFEED);

const mapStateToProps = (state) => {
    return {
        getStreamApiKey: state.userNewsFeed.getStreamApiKey,
        getStreamAppId: state.userNewsFeed.getStreamAppId,
        getStreamApiToken: state.userNewsFeed.getStreamApiToken,
        getStreamFeedName: state.userNewsFeed.getStreamFeedName,
        getStreamNotificationFeedName: state.userNewsFeed.getStreamNotificationFeedName,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        checkInitialUserNewsFeedSettings: () => {
            const apiKey = localStorage.getItem('getStreamApiKey');
            const appId = localStorage.getItem('getStreamAppId');
            const token = localStorage.getItem('getStreamApiToken');
            const feedName = localStorage.getItem('getStreamFeedName');
            const notificationFeedName = localStorage.getItem('getStreamNotificationFeedName');

            dispatch({
                type: userNewsFeedNamespacer('SET_GETSTREAM_API_KEY'),
                getStreamApiKey: apiKey
            });
            dispatch({
                type: userNewsFeedNamespacer('SET_GETSTREAM_API_ID'),
                getStreamAppId: appId
            });
            dispatch({
                type: userNewsFeedNamespacer('SET_GETSTREAM_API_TOKEN'),
                getStreamApiToken: token
            });
            dispatch({
                type: userNewsFeedNamespacer('SET_GETSTREAM_FEED_NAME'),
                getStreamFeedName: feedName
            });
            dispatch({
                type: userNewsFeedNamespacer('SET_GETSTREAM_NOTIFICATION_FEED_NAME'),
                getStreamNotificationFeedName: notificationFeedName
            });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserNewsFeed);
