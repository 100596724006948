import React, { Component } from "react";
import ChatViewProfile from "../ChatViewProfile";
import ChatDisplayMsg from "../ChatDisplayMsg";
import chatClient from "../../../services/twilioConversation";
import { Input, Button } from "reactstrap";

class ChatSection extends Component {
    state = {
        twilioToken: null,
    };

    constructor(props) {
        super(props);
        const msgFromIdentity = this.props.msgFromIdentity;
        const msgToIdentity = this.props.userDetail.id;
        const msgFromAuthor = this.props.twilioToken.identity;
        const msgToAuthor = this.props.userDetail.attributes.email;

        if (chatClient.client) {
            setTimeout(() => {
                this.props.getChannelOrCreateChannel(
                    msgFromIdentity,
                    msgToIdentity,
                    msgFromAuthor,
                    msgToAuthor
                );
            });
        }
        this.props.setMessages();
    }

    handleMessage = (event) => {
        this.props.setSendMessageText(event.target.value);
    };

    handleEnterKey = (event) => {
        if (event.keyCode === 13) {
            this.handleSendMessage();
        }
    };

    handleSendMessage = () => {
        const { tcCurrentChannel, sendMessageText } = this.props;
        const trimmedMsgText = sendMessageText.replace(/^\s+|\s+$/g, "");
        if (trimmedMsgText.length) {
            this.props.sendMessageToChannel(tcCurrentChannel, trimmedMsgText);
        }
        this.props.setSendMessageText("");
    };

    componentWillUnmount() {
        this.props.setSendMessageText("");
    }

    render() {
        const { userDetail, openProfileSection, twilioToken, messages, sendMessageText } =
            this.props;
        return (
            <>
                <div className="chat-section-container">
                    <ChatViewProfile
                        userDetail={userDetail}
                        openProfileSection={openProfileSection}
                    />
                    <ChatDisplayMsg
                        msgFromAuthor={twilioToken.identity}
                        msgToAuthor={userDetail.attributes.email}
                        messages={messages}
                    />
                </div>
                <div className="chat-footer-wrapper">
                    <div className="chat-footer-content">
                        <div className="input-container">
                            <Input
                                type="textarea"
                                value={sendMessageText}
                                placeholder="Say Something..."
                                onChange={this.handleMessage}
                                onKeyDown={this.handleEnterKey}
                            />
                        </div>
                        <Button className="sent-icon" onClick={this.handleSendMessage}></Button>
                    </div>
                </div>
            </>
        );
    }
}

export default ChatSection;
