import React, { Component } from 'react';
import logo from '../../commons/images/logo.svg';
import { landingPageUrl } from '../../constants/config';
import PrivacyModal from '../staticPages/privacyPolicy';
import TermsModal from '../staticPages/terms';
import { Link, Redirect } from 'react-router-dom';
import { Button, Form, FormGroup } from 'reactstrap';
import BackIcon from '../../commons/images/back-icon.svg';
import FormInput from '../commonComponents/FormGroupInputBox';
import HeaderBeforeLogin from '../headerBeforeLogin/headerBeforeLogin';

export default class LandingPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			authURL: landingPageUrl,
			privacyModal: false,
			termsModal: false,
			isValidPassword: true,
			isValidEmail: true,
		};

		this.privacyModalToggle = this.privacyModalToggle.bind(this);
		this.termsModalToggle = this.termsModalToggle.bind(this);
	}
	
	privacyModalToggle() {
		this.setState({
			privacyModal: !this.state.privacyModal
		});
	}

	termsModalToggle() {
		this.setState({
			termsModal: !this.state.termsModal
		});
	}

	handleUserEmail = (event) => {
		this.props.setUserEmail(event.target.value);
		this.emailValidate(event.target.value);
	};

	emailValidate = (email) => {
        if (/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g.test(email)) {
            this.setState({ isValidEmail: true })
        } else {
            this.setState({ isValidEmail: false })
        }
    }

	passwordValidate(p) {
        return /([^\s])/.test(p)
    }

	handleUserPassword = (event) => {
		this.props.setUserPassword(event.target.value);
		if (!this.passwordValidate(event.target.value)) {
            this.setState({ isValidPassword: false })
        } else {
            this.setState({ isValidPassword: true })
        }
	};

	handleUserLogin = (event) => {
		const { userEmail, userPassword } = this.props;
		if (userEmail && userPassword) {
			this.props.userLogin(userEmail, userPassword);
		} else {
			alert('Please enter Email and Password');
		}
	};

	render() {
		return (
			<React.Fragment>
				<div className="login-wrapper d-flex flex-column flex-md-row">
					<div className="login-banner-container" />
					<div className="login-right-container d-flex">
						<HeaderBeforeLogin />
						<div className="login-inner-container ">
							<div className="back-to-login">
								<Link to="/">
									<span>
										<img alt='back' src={BackIcon} />
									</span>
									Back
								</Link>
							</div>
							<img src={logo} alt="Gradshack" />
							<h4 className="title">Log In to your Account</h4>

							<Form className="form-container login-form mt-4">
									<FormInput
										type="email"
										placeholder="Enter your Email"
										className="form-control"
										isValid = {this.state.isValidEmail}
										onChange={this.handleUserEmail}
										invalidMessage = "Invalid email."
									/>

									<FormInput
										type="password"
										placeholder="Enter Password"
										isValid = {this.state.isValidPassword}
										className="form-control"
										onChange={this.handleUserPassword}
										invalidMessage = " Password required."
									/>

								<Button
									onClick={this.handleUserLogin}
									className="landing-btn w-100"
									disabled={
										(
											!(this.state.isValidEmail && this.props.userEmail) ||
                                            !(this.state.isValidPassword && this.props.userPassword)
										)
									}
									style={{ cursor:  !(this.state.isValidEmail && this.props.userEmail) ||
										!(this.state.isValidPassword && this.props.userPassword) ? 'not-allowed' : 'pointer'}}
								>
									Log In
								</Button>

								{localStorage.getItem("auth_code") && localStorage.getItem("user_type") ? <Redirect to="/homepage" /> : null}

								<div className="buttons-wrapper w-100">
									<p>Don’t Have an Account? {' '}
										<span><Link to="/" className="signup-text">Sign Up</Link></span></p>									
									<div className="forgot-password-text">
										<Link style={{ textDecoration: 'underline' }} to="/forgotPassword">
											Forgot Password?
										</Link>
									</div>
								</div>
							</Form>
							<span className="comment-text">
								Comments? Questions? Email us at{' '}
								<a href="mailto:contact@gradshack.com">contact@gradshack.com</a>
							</span>
						</div>
					</div>
				</div>
				<PrivacyModal isOpen={this.state.privacyModal} toggle={this.privacyModalToggle} />
				<TermsModal isOpen={this.state.termsModal} toggle={this.termsModalToggle} />
			</React.Fragment>
		);
	}
}
