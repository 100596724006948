import React, { Component } from "react";
import { Redirect } from "react-router-dom";

export default class RedirectAfterLogin extends Component {
  state = {
    userData: null,
    redirectTo: null,
  };

  componentWillMount() {
    const allParams = window.location.search.substr(1);
    if (allParams) {
      const params = allParams.split("&");
      let authCode = params[0].split("=")[1];
      let iD = params[1].split("=")[1];
      localStorage.setItem("auth_code", authCode);
      localStorage.setItem("id", iD);
      localStorage.setItem("isAdmin", false);
      localStorage.setItem("isLogin", true);

      this.props.setAuthCode(authCode);
      this.props.setUserId(iD);
    }
  }

  shouldCallLoginUserDetailsApi() {
    const streamApi = localStorage.getItem("getStreamApiKey");
    const streamAppId = localStorage.getItem("getStreamAppId");
    const streamApiToken = localStorage.getItem("getStreamApiToken");
    const streamFeedName = localStorage.getItem("getStreamFeedName");
    const streamNotificationFeedName = localStorage.getItem(
      "getStreamNotificationFeedName"
    );

    return (
      !streamApi &&
      !streamAppId &&
      !streamApiToken &&
      !streamFeedName &&
      !streamNotificationFeedName
    );
  }

  componentDidMount() {
    if(!this.state.userData || !this.props.userData.id){
      this.props.getUserData();
    }
    if (this.shouldCallLoginUserDetailsApi()) {
      this.props.userLoginInfo(localStorage.getItem("id"));
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userData !== this.props.userData) {
      this.setState({ userData: nextProps.userData });
    }
  }

  routeChange = (path) => {
    this.setState({ redirectTo: path });
  };

  getLinkedinDetail = () => {
    const { userData } = this.state;

    if (userData) {
      const { attributes } = this.props.userData;
      localStorage.setItem("user_type", attributes["user-type"]);

      if (
        attributes.interests.length === 0 ||
        attributes["user-type"] === null
      ) {
        return "/userInfo";
      } else if (attributes.status === "rejected") {
        return "/thankYou";
      } else if (
        attributes.status === "approved" ||
        attributes.status === "pending"
      ) {
        return "/newsFeed";
      } else {
        return "/newsFeed";
      }
    } else {
      return "/";
    }
  };

  render() {
    const { userData } = this.state;

    return <div>{userData ? <Redirect to={this.getLinkedinDetail()} /> : null}</div>;
  }
}
