import { connect } from "react-redux";
import Match from "../../components/match/Match";

const mapStateToProps = (state) => {
  return {
    isTokenValid: state.redirectAfterLogin.isTokenValid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Match);
