import React, { Component } from "react";
import DesktopHeader from '../../containers/homePage/DesktopHeader';
import ExistingUserFilters from './ExistingUserFilters';
import ExistingUserCards from './ExistingUserCards';
import MobileMenu from '../../containers/homePage/MobileMenu';
import Pagination from '../shared/Pagination';
import cx from 'classnames';

class AdminExistingUsers extends Component {

    state={
        colOrUniv: '',
        currentPage: 1,
    }

    getExistingUser = (userStatus, userType, page=1) => {
        this.props.getExistingUserList(userStatus, userType, page);
    }
    
    componentDidMount(){
        const { hash } = this.props.location;
        const userType = hash.substr(1);
        this.props.setExistingUserType(userType ? userType : 'alumni');
        this.getExistingUser('approved', userType ? userType : 'alumni');
    }

    static getDerivedStateFromProps(props, state){
        const { colOrUniv } = props;
        if(colOrUniv && state.colOrUniv !== colOrUniv){
            props.getFilterExistingUserList(colOrUniv, 1)
            state.currentPage = 1;
        }
        return {colOrUniv}
    }

    setCurrentPage = (page) => {
        this.setState({currentPage: page});
    }

    handleCurrentPage = (page) => {
        this.setCurrentPage(page);
        this.props.colOrUniv ? 
            this.props.getFilterExistingUserList(this.props.colOrUniv, page) : 
            this.getExistingUser('approved', this.props.existingUserType, page);
    }

    render() {
        const { getExistingUserList, existingUserType, setExistingUserType, existingUserList, getTwilioTokenForUser, createTwilioClient, filteredExistingUserList,
            universityList, getUniversityListPrediction, setColOrUniv, getUniversityList, clearFilteredExistingUserList, colOrUniv, totalRecords, recordsPerPage, showLoader } = this.props;
        return (
            <React.Fragment>
                <DesktopHeader
                    setCurrentPage={this.setCurrentPage}
                />
                <div className={cx("custom-container-wrapper users-section", {"admin-existing-users" : this.props.totalRecords>this.props.recordsPerPage})}>
                    <ExistingUserFilters
                        options={universityList}
                        universityList={universityList}
                        getUniversityListPrediction={getUniversityListPrediction}
                        functionToBeUsed={'setColOrUniv'}
                        setColOrUniv={setColOrUniv}
                        getUniversityList={getUniversityList}
                        clearFilteredExistingUserList={clearFilteredExistingUserList}
                        colOrUniv={colOrUniv}
                        setCurrentPage={this.setCurrentPage}
                    />
                    <ExistingUserCards 
                        filteredExistingUserList={filteredExistingUserList}
                        getExistingUserList={getExistingUserList}
                        existingUserList={existingUserList}
                        existingUserType={existingUserType}
                        noUserFor='existing'
                        getTwilioTokenForUser={getTwilioTokenForUser}
                        createTwilioClient={createTwilioClient}
                        setColOrUniv={setColOrUniv}
                        setExistingUserType={setExistingUserType}
                        currentPage={this.state.currentPage}
                        setCurrentPage={this.setCurrentPage}
                        totalRecords={totalRecords}
                        recordsPerPage={recordsPerPage}
                        showLoader={showLoader}
                    />
                </div>
                {
                    this.props.totalRecords>this.props.recordsPerPage &&
                    <Pagination
                        current={this.state.currentPage}
                        total={this.props.totalRecords}
                        pageSize={this.props.recordsPerPage}
                        onChange={this.handleCurrentPage}
                    />
                }
                <MobileMenu />
            </React.Fragment>
        );
    }
}

export default AdminExistingUsers;