import React from "react";

import { landingPageUrl } from "../../constants/config";

import linkedin from "../../commons/images/linkedin.png";
import logo from "../../commons/images/logo.svg";

function LinkedInSignupForm(props) {

  return (
    <div className="linkedin-container">
      <div className="content-container">
        <img src={logo} alt="Gradshack" className="logo" />

        <div className="button-container">
          <div className="buttons-wrapper">
            <a href={landingPageUrl} className="blue-btn">
              <div className="linked-in-text">
                <img
                  className="m-2"
                  style={{ width: 17 }}
                  src={linkedin}
                  alt="linkedin"
                />
                Expedite the Sign-up Process
              </div>
              {/* <div className="faster-tag">(Faster sign-up)</div> */}
            </a>
          </div>

          <div className="divider-container">
            <hr />
            <p>OR</p>
            <hr />
          </div>

          <div>
            <div className="continue-text" onClick={() => props?.handleContinueClick()}>
              Continue Manually
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LinkedInSignupForm;
