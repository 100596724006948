import React from "react";
import logo from "../../commons/images/logo.svg";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { landingPageUrl } from "../../constants/config";
import linkedin from "../../commons/images/linkedin.png";
import email from "../../commons/images/mail-icon-black.svg";
import HeaderBeforeLogin from "../headerBeforeLogin/headerBeforeLogin";
import PrivacyModal from "../staticPages/privacyPolicy";
import TermsModal from "../staticPages/terms";

export default class FirstPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      privacyModal: false,
      termsModal: false,
      redirectTo: '',
    };
  }

  privacyModalToggle = () => {
    this.setState({
      privacyModal: !this.state.privacyModal,
    });
  };

  termsModalToggle = () => {
    this.setState({
      termsModal: !this.state.termsModal,
    });
  };

  componentDidMount() {
    if(localStorage.getItem("auth_code") &&
    localStorage.getItem("user_type")){
      this.setState({
        redirectTo: "/homepage",
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="login-wrapper d-flex flex-column flex-md-row">
          <div className="login-banner-container" />
          <div className="login-right-container d-flex">
            <div className="login-inner-container ">
              <HeaderBeforeLogin />
              <img src={logo} alt="Gradshack" />
              <h4 className="title">
                Alumni connections for venture formation
              </h4>

              <div className="buttons-wrapper">
                <a href={landingPageUrl} className="blue-btn">
                  <div className="linked-in-text">
                    {/* <img
                      className="m-2"
                      style={{ width: 17 }}
                      src={linkedin}
                      alt="linkedin"
                    /> */}
                    Expedited Sign-Up (via LinkedIn)
                  </div>
                </a>
              </div>

              {this.state.redirectTo ? (
                <Redirect to={this.state.redirectTo} />
              ) : null}

              <div className="buttons-wrapper">
                <Link to="/userSignUp" className="login-btn">
                  {/* <img
                    className="m-2"
                    style={{ width: 17 }}
                    src={email}F
                    alt="signup"
                  /> */}
                  Manual Sign-Up
                </Link>
              </div>

              {/* <div className="divider-container">
                <hr />
                <p>OR</p>
                <hr />
              </div> */}

              <div className="buttons-wrapper login">
                <Link to="/login" className="login-btn login">
                  <div className="login-in-text">
                    {/* <img
                      className="m-2"
                      style={{ width: 17 }}
                      src={linkedin}
                      alt="login"
                    /> */}
                    Already a user?
                  </div>
                  </Link>
              </div>

              <div className="comment-text">
                <div className="terms-and-privacy-text">
                  By signing up you agree to GradShack’s{" "}
                  <span onClick={this.privacyModalToggle}>Privacy</span>
                  <strong> & </strong>
                  <span onClick={this.termsModalToggle}>Terms</span>
                </div>
                <div>
                  Comments? Questions? Email us at{" "}
                  <a href="mailto:contact@gradshack.com">
                    contact@gradshack.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PrivacyModal
          isOpen={this.state.privacyModal}
          toggle={this.privacyModalToggle}
        />
        <TermsModal
          isOpen={this.state.termsModal}
          toggle={this.termsModalToggle}
        />
      </React.Fragment>
    );
  }
}
