import React, { Component } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { withRouter } from 'react-router-dom'

    class StudentAlumniTabs extends Component {

    state = {
        activeTab : ''
    }

    componentDidMount(){
        this.setActiveTabOnMount();
    }

    setActiveTabOnMount = () => {
        const { hash } = this.props.location;
        if(hash==='#student'){
            this.setState({activeTab: 'student'})
        }
        else if(hash==='#advisor'){
            this.setState({activeTab: 'advisor'})
        }
        else{
            this.setState({activeTab: 'alumni'})
        }
    }
    

    toggle = tab => {
        const { activeTab } = this.state;
        if(activeTab !== tab){
            this.setActiveTab(tab);
        }
    }

    setActiveTab = (tab) => {
        this.setState({activeTab: tab})
        if(tab==='alumni'){
            this.props.setExistingUserType('alumni');
            this.props.getExistingUserList('approved', 'alumni');
        }
        else if(tab==='student'){
            this.props.setExistingUserType('student');
            this.props.getExistingUserList('approved', 'student');
        }
        else if(tab==='advisor'){
            this.props.setExistingUserType('advisor');
            this.props.getExistingUserList('approved', 'advisor');
        }
        this.props.history.push(`/admin/existingUsers#${tab}`)
        this.props.setColOrUniv('')
        this.props.setCurrentPage(1);
    }
    
    render() {
        const { activeTab } = this.state;
        return (
            <div className="admin-tabs-section">
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={`${activeTab === 'alumni' ? 'active' : ''}`}
                            onClick={() => { this.toggle('alumni'); }}
                        >
                            Alumni
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={`${activeTab === 'student' ? 'active' : ''}`}
                            onClick={() => { this.toggle('student'); }}
                        >
                            Student
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={`${activeTab === 'advisor' ? 'active' : ''}`}
                            onClick={() => { this.toggle('advisor'); }}
                        >
                            Advisor
                        </NavLink>
                    </NavItem>
                </Nav>
            </div>
        )
    }
}

export default withRouter(StudentAlumniTabs);
