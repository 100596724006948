import { createReducer, createNamespacer } from '../../utils/reducers';
import { USERFORMDATA } from '../../constants/namespacer';

const initialState = {
    profilePicUrl: '',
    userId: '',
    name: '',
    lastName: '',
    email: '',
    linkedinProfileUrl: '',
    mbaProgram: '',
    colOrUniv: '',
    graduationYear: '',
    currentWorkLocation: '',
    universityList:'',
    workLocationList:'',
    isEmailNotificationOn: '',
    profilePicBase64:"",
    profilePicBase64Copy:"",
    provider: null,
    loading: false,
    profileLoading: false,
    privacyCheckboxIsChecked: false,
    selectedNotifications: [],
    isInvestor: false,
}

const namespacer = createNamespacer(USERFORMDATA);

const handlers = {
    [namespacer('SET_PROFILE_PIC_URL')]: (state, action) => {
        return{
            ...state,
            profilePicUrl: action.profilePicUrl,
        }
    },
    [namespacer('SET_PROFILE_PIC_BASE64')]: (state, action) => {
        return{
            ...state,
            profilePicBase64: action.profilePicBase64,
        }
    },
    [namespacer('SET_PROFILE_PIC_BASE64_COPY')]: (state, action) => {
        return{
            ...state,
            profilePicBase64Copy: action.profilePicBase64Copy,
        }
    },
    [namespacer('SET_LOADER_VALUE')]: (state, action) => {
        return{
            ...state,
            loading: action.loading,
        }
    },
    [namespacer('SET_PROFILE_LOADER_VALUE')]: (state, action) => {
        return{
            ...state,
            profileLoading: action.profileLoading,
        }
    },
    [namespacer('SET_NAME_VALUE')]: (state, action) => {
        return{
            ...state,
            name: action.name,
        }
    },
    [namespacer('SET_LAST_NAME_VALUE')]: (state, action) => {
        return{
            ...state,
            lastName: action.lastName,
        }
    },
    [namespacer('SET_EMAIL_VALUE')]: (state, action) => {
        return{
            ...state,
            email: action.email,
        }
    },
    [namespacer('SET_PROVIDER_VALUE')]: (state, action) => {
        return{
            ...state,
            provider: action.provider,
        }
    },
    [namespacer('SET_LINKEDIN_PROFILE_URL_VALUE')]: (state, action) => {
        return{
            ...state,
            linkedinProfileUrl: action.linkedinProfileUrl,
        }
    },
    [namespacer('SET_MBA_PROGRAM_VALUE')]: (state, action) => {
        return{
            ...state,
            mbaProgram: action.mbaProgram,
        }
    },
    [namespacer('SET_COL_OR_UNIV_VALUE')]: (state, action) => {
        return{
            ...state,
            colOrUniv: action.colOrUniv,
        }
    },
    [namespacer('SET_GRADUATION_YEAR_VALUE')]: (state, action) => {
        return{
            ...state,
            graduationYear: action.graduationYear,
        }
    },
    [namespacer('SET_CURRENT_WORK_LOCATION_VALUE')]: (state, action) => {
        return{
            ...state,
            currentWorkLocation: action.currentWorkLocation,
        }
    },
    [namespacer('SET_UNIVERSITY_LIST')]: (state, action) => {
        return{
            ...state,
            universityList: action.payload,
        }
    },
    [namespacer('SET_WORK_LOCATION_LIST')]: (state, action) => {
        return{
            ...state,
            workLocationList: action.payload,
        }
    },
    [namespacer('SET_EMAIL_NOTIFICATION_ON_VALUE')]: (state, action) => {
        return{
            ...state,
            isEmailNotificationOn: action.isEmailNotificationOn
        }
    },
    [namespacer('SET_PRIVACY_CHECK_VALUE')]: (state, action) => {
        return{
            ...state,
            privacyCheckboxIsChecked: action.privacyCheckboxIsChecked
        }
    },
    [namespacer('SET_USER_ID')]: (state, action) => {
        return{
            ...state,
            userId: action.userId
        }
    },
    [namespacer('SET_SELECTED_NOTIFICATIONS')]: (state, action) => {
        return{
            ...state,
            selectedNotifications: action.selectedNotifications,
        }
    },
    [namespacer('SET_IS_INVESTOR')]: (state, action) => {
        return{
            ...state,
            isInvestor: action.isInvestor,
        }
    },
    [namespacer('RESET_YOUR_FORM_DATA')]: (state, action) => {
        return{
            ...initialState,
        }
    },
};

const userFormDataReducer = createReducer(initialState, handlers, [USERFORMDATA]);

export default userFormDataReducer;