import { connect } from 'react-redux';
import { createNamespacer } from '../../utils/reducers';
import { LOGIN, ADMINLOGIN } from '../../constants/namespacer';
import { adminLogin } from '../../api/admin'
import AdminLogin from '../../components/adminLogin/AdminLogin'

const loginNamespacer = createNamespacer(LOGIN);
const adminLoginNamespacer = createNamespacer(ADMINLOGIN);

const mapStateToProps = (state) => {
    return {
        adminEmail: state.adminLogin.adminEmail,
        adminPassword: state.adminLogin.adminPassword,
        isAdmin: state.adminLogin.isAdmin,
        isTokenValid: state.redirectAfterLogin.isTokenValid,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        setAdminEmail: (value) => {
            dispatch({
                type: adminLoginNamespacer('SET_ADMIN_EMAIL'),
                adminEmail: value
            })
        },
        setAdminPassword: (value) => {
            dispatch({
                type: adminLoginNamespacer('SET_ADMIN_PASSWORD'),
                adminPassword: value
            })
        },
        adminLogin: async(adminEmail, adminPassword) => {
            try{
                let bodyFormData = new FormData();
                bodyFormData.append('user[email]', adminEmail );
                bodyFormData.append('user[password]', adminPassword);

                let response = await adminLogin(bodyFormData);
                if(response.status===200){
                    localStorage.setItem('id',response.data.user.id);
                    localStorage.setItem('isAdmin', 'true');
                    localStorage.setItem('isLogin', 'true')
                    localStorage.setItem('auth_code',response.data.auth_token);
                    
                    dispatch({
                        type: loginNamespacer('SET_IS_AUTHENTICATED'),
                        isTokenValid: 'true'
                    })
                    dispatch({
                        type: adminLoginNamespacer('SET_IS_ADMIN_FLAG'),
                        isAdmin: 'true',
                    })
                }
            }
            catch(error){
                console.log(error) 
            }
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin);

