import axiosInstance from "..";

export const getTimelineFeedList = (params) => {
    const axios = axiosInstance();

    return axios({
        method: "GET",
        url: `/timelines/feeds?${params}`,
    });
};

export const getTimelineFeedListWithFilter = (params, signal) => {
    const axios = axiosInstance();

    return axios({
        method: "GET",
        url: "timelines/feeds",
        params,
        signal,
    });
};

export const getMembersAPI = (params) => {
    const axios = axiosInstance();

    return axios({
        method: "GET",
        url: "timelines/feeds",
        params,
    });
};
