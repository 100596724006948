import React, { Component } from "react";
import { Link } from 'react-router-dom';
import DesktopHeader from '../../containers/homePage/DesktopHeader';
import AdminExistingUserMessagesList from './AdminExistingUserMessagesList'
import AdminExistingUserMessagesBlock from './AdminExistingUserMessagesBlock'
import BackIcon from '../../commons/images/back-icon.svg'

class AdminExistingUserMessages extends Component {

    state = {
        subscribedChannels: null,
    }

    componentDidMount = async() => {
        const { userId, userEmail } = this.props.location.state;
        await this.props.getTwilioTokenForUser(userId, userEmail);
        const subscribedChannels = await this.props.createTwilioClient();
        this.setState({subscribedChannels})
    }

    render() {
        const { setActiveUserChatIndex, activeUserChatIndex, setCurrentChannelAndMessages } = this.props;
        const { userName, userEmail, userType, backToNotification } = this.props.location.state;
        const { subscribedChannels } = this.state;
        let activeChatIndex;
        if(subscribedChannels){
            activeChatIndex = activeUserChatIndex < subscribedChannels.length ? activeUserChatIndex : 0
        }
        const linkPath = backToNotification ? '/admin/notifications' 
                            : userType==='student' ? '/admin/existingUsers#student' : '/admin/existingUsers#alumni'
        return(
            <>
                <DesktopHeader/>
                <div>
                    {
                        subscribedChannels && subscribedChannels.length>0 ?
                        <>
                            <div className="custom-container-wrapper message-wrapper screen-height chat-histroy-wrapper">
                                <div className="pagination-container pagination-container-admin-messages">
                                    <Link to={linkPath} className="btn-backicon">                                    
                                        <img src={BackIcon} alt="Back"/>
                                    </Link>
                                    <span className="custom-pagination">Messages of {userName}</span>
                                </div>
                                <div className={`messages-members-container messages-members-container-admin ${this.state.viewChat?'message-member':''}`}>
                                    <span className="mobile-message-header">Messages</span>
                                    <AdminExistingUserMessagesList
                                        subscribedChannels={subscribedChannels}
                                        setActiveUserChatIndex={setActiveUserChatIndex}
                                        activeUserChatIndex={activeChatIndex}
                                        setCurrentChannelAndMessages={setCurrentChannelAndMessages}
                                    />
                                    {
                                        subscribedChannels ?
                                        <AdminExistingUserMessagesBlock
                                            tcCurrentChannel={subscribedChannels[activeChatIndex]}
                                            userEmail={userEmail}
                                        /> : ''
                                    }
                                </div>
                            </div>
                        </>
                        : <div className="loading-btn">
                            {
                                subscribedChannels ? 
                                <div className="pagination-container pagination-container-admin-messages">
                                    <Link to={linkPath} className="btn-backicon">                                    
                                        <img src={BackIcon} alt="Back"/>
                                    </Link>
                                    <span className="custom-pagination">No messages to show for selected User</span>
                                </div> 
                                : 'Loading.....'
                            }
                        </div>
                    }
                </div>
            </>
        );
    }
}

export default AdminExistingUserMessages;