import axiosInstance from '..';

export const getUserData = () => {
    const axios = axiosInstance();

    const userId = localStorage.getItem('id');

    return axios({
        method: 'GET',
        url: `/users/${userId}`,
    })
};
