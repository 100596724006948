import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Redirect } from "react-router-dom";
import {
    SinglePost,
    CommentList,
    CommentField,
    Title,
    StreamApp,
} from "react-activity-feed";
import { onAddCommentSuccess } from "../../api/pushy";
import BlockingLoader from "../commonComponents/Loader";

class AddCommentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCommentModalOpen: true,
            redirectTo: null,
            commentText: "",
            activity: "",
            loading: false,
            activityId: this.props.match.params.activityId || null,
            userId: this.props.match.params.userId || null,
        };

        this.commentModalToggle = this.commentModalToggle.bind(this);
        this.onAddReaction = this.onAddReaction.bind(this);
        this.notifyUser = this.notifyUser.bind(this);
        this.getAct = this.getAct.bind(this);
    }

    getAct = async()=>{
            const act = await fetch(
                `https://api.stream-io-api.com/api/v1.0/enrich/feed/user/${
                    this.state.userId
                }/?api_key=${localStorage.getItem(
                    "getStreamApiKey"
                )}&location=unspecified&id_lte=${this.state.activityId}&id_gte=${
                    this.state.activityId
                }&limit=10&withReactionCounts=true&withOwnReactions=true&withRecentReactions=true`,
                {
                    headers: {
                        authorization: localStorage.getItem("getStreamApiToken"),
                        "x-stream-client": "stream-javascript-client-browser-5.0.5",
                        "stream-auth-type": "jwt",
                    },
                }
            )
                .then((res) => res.json())
                .then((json) => {
                    this.setState({
                        loading: false,
                    });
                    return json.results[0];
                })
                .catch((err) => {
                    this.setState({
                        loading: false,
                    });
                });
            if (act) {
                this.setState({
                    activity: act,
                });
            }
            return act
    }

    async componentDidMount() {
        if (!this.state.activityId || !this.state.userId) {
            this.setState({
                activityId: this.props.match.params.activityId,
                userId: this.props.match.params.userId,
            });
        }
        this.setState({
            loading: true,
        });

        try {
          await this.getAct()
        } catch (error) {
          this.setState({ loading: false });
        }
    }

    commentModalToggle() {
        this.setState({
            isCommentModalOpen: !this.state.isCommentModalOpen,
        });
        this.setState({ redirectTo: "/newsFeed" });
    }

    onSuccess = async (activity, data) => {
        this.notifyUser(activity,data);
    };

    notifyUser = async (activity, data) =>{
        const id = this.state.activity.actor.id.split("_");
        const commentedById = localStorage.getItem("id");
        if (commentedById !== id[1]) {
            await onAddCommentSuccess({
                user_id: id[1],
                commented_by_id: commentedById,
                comment_desc: activity.latest_reactions.comment
                    ? activity.latest_reactions.comment[0].data.text
                    : "",
                post_id: this.state.activityId,
            });
        }
    }

    onAddReaction = async (props, data) => {
        this.notifyUser(props,data)
    };

    render() {
        const { isCommentModalOpen } = this.state;
        const activity = this.state.activity;
        const userId = activity?.actor?.id;
        const isNewMember = activity?.title?.includes("New signup for");
        const name =
            activity?.actor &&
            activity?.actor?.data &&
            activity?.actor?.data.name
                ? activity?.actor?.data.name
                : "";
        const title = isNewMember
            ? `${activity?.title.trim()} ${name}`
            : activity?.title;

        return (
            <>
                {this.state.loading ? (
                    <div style={{ width: "100vw", height: "100vh" }}>
                        <BlockingLoader />
                    </div>
                ) : (
                    <Modal
                        isOpen={isCommentModalOpen}
                        toggle={this.commentModalToggle}
                        className="modal-dialog-centered modal-xl add-comment-modal"
                    >
                        <ModalHeader
                            toggle={this.commentModalToggle}
                            className="modal-head"
                            style={{ minHeight: "60px" }}
                        >
                            {title}
                        </ModalHeader>
                        <ModalBody className="modal-body">
                            <div className="comments-wrapper">
                                <StreamApp
                                    apiKey={localStorage.getItem(
                                        "getStreamApiKey"
                                    )}
                                    appId={localStorage.getItem(
                                        "getStreamAppId"
                                    )}
                                    token={localStorage.getItem(
                                        "getStreamApiToken"
                                    )}
                                >
                                    <SinglePost
                                        activityId={
                                            activity?.id ||
                                            this.state.activityId
                                        }
                                        feedGroup="user"
                                        userId={userId}
                                        Activity={(props) => (
                                            <div className="card-wrapper">
                                                <div className="raf-activity__content">
                                                    <div
                                                        style={{
                                                            padding:
                                                                "8px 16px 8px 0",
                                                        }}
                                                    >
                                                        <p>
                                                            {
                                                                props.activity
                                                                    ?.text
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="post-title-wrapper">
                                                    <Title>
                                                        <h2 className="raf-title-text">
                                                            Comments
                                                        </h2>
                                                    </Title>
                                                </div>
                                                <CommentList
                                                    activityId={
                                                        props.activity.id
                                                    }
                                                />
                                                <CommentField
                                                    activity={props.activity}
                                                    // onAddReaction={this.onAddReaction(
                                                    //     props
                                                    // )}
                                                    onSuccess={async()=>{
                                                        const activity = await this.getAct()
                                                        this.onSuccess(
                                                            activity
                                                               
                                                        )
                                                    }
                                                }
                                                />
                                            </div>
                                        )}
                                    />
                                </StreamApp>
                            </div>
                        </ModalBody>
                    </Modal>
                )}
                {this.state.redirectTo ? (
                    <Redirect to={this.state.redirectTo} />
                ) : null}
            </>
        );
    }
}

export default AddCommentModal;
