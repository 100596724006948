import { createReducer, createNamespacer } from '../utils/reducers';
import { VALIDATIONERROR } from '../constants/namespacer';

const initialState = {
    fieldsMissing: '',
}

const namespacer = createNamespacer(VALIDATIONERROR);

const handlers = {
    [namespacer('SET_FIELDMISSING')]: (state, action) => {
        return{
            ...state,
            fieldsMissing: action.fieldsMissing,
        }
    },
}

const validationErrorReducer = createReducer(initialState, handlers, [VALIDATIONERROR]);

export default validationErrorReducer;