import axiosInstance from '.';

export const getTwilioTokenApi = () => {
    return axiosInstance()({
        method: 'POST',
        url: '/chats',
    })
}

export const addMemberToChannelApi = (data) => {
    return axiosInstance()({
        method: 'POST',
        url: '/chats/add_member',
        data,
    })
}

export const notifyChannelConversationToAdmin = (data) => {
    return axiosInstance()({
        method: 'POST',
        url: '/chats/chat_info',
        data
    })
}

export const getSecondUserDetailApi = (userId) => {
    return axiosInstance()({
        method: 'GET',
        url: `/users/${userId}`,
    })
}

export const notifyUserToTriggerMail = (params) => {
    return axiosInstance()({
        method: 'GET',
        url: `chats/notify_user`,
        params,
    })
}