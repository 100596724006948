import { createReducer, createNamespacer } from '../utils/reducers';
import { PROFILEUSER } from '../constants/namespacer';

const initialState = {
    profileUserInfo: null,
};

const namespacer = createNamespacer(PROFILEUSER);

const handlers = {
    [namespacer('SET_PROFILE_USER_INFO')]: (state, action) => {
        return{
            ...state,
            profileUserInfo: action.profileUserInfo,
        }
    },
};

const profileReducer = createReducer(initialState, handlers, [PROFILEUSER]);

export default profileReducer;
