import { connect } from 'react-redux';
import { createNamespacer } from '../../utils/reducers';
import { TWILIOCHAT, LOGIN } from '../../constants/namespacer';
import { getTwilioTokenApi, getSecondUserDetailApi } from '../../api/twilioChat'
import Messages from '../../components/messages/Messages';
import chatClient from '../../services/twilioConversation';
import { getMessageCount } from '../../constants/fixedValues';
import get from "lodash/get";
import store from '../../store'

const twilioChatNamespacer = createNamespacer(TWILIOCHAT);

const mapStateToProps = (state) => {
    return {
        msgFromIdentity: state.twilioChat.msgFromIdentity,
        messages: state.twilioChat.messages,
        sendMessageText: state.twilioChat.sendMessageText,
        tcCurrentChannel: state.twilioChat.tcCurrentChannel,
        subscribedChannels: state.twilioChat.subscribedChannels,
        secondUserData: state.twilioChat.secondUserData,
        activeUserChatIndex: state.twilioChat.activeUserChatIndex,
        twilioToken: state.twilioChat.twilioToken,
        loading: state.twilioChat.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        getTwilioToken: async () => {
            try{
                dispatch({
                    type: twilioChatNamespacer('SET_MESSAGES_LOADING'),
                    loading: true,
                })
                let response = await getTwilioTokenApi();
                dispatch({
                    type: twilioChatNamespacer('SET_MSG_FROM_IDENTITY'),
                    msgFromIdentity: localStorage.getItem('id')
                })
                dispatch({
                    type: twilioChatNamespacer('SET_TWILIO_TOKEN'),
                    twilioToken: response.data
                })
                dispatch({
                    type: twilioChatNamespacer('SET_MESSAGES_LOADING'),
                    loading: false,
                })
                return response.data.token;
            }
            catch(error){
                dispatch({
                    type: twilioChatNamespacer('SET_MESSAGES_LOADING'),
                    loading: false,
                })
				if(error.response.status===500){
					const loginNamespacer = createNamespacer(LOGIN);
					localStorage.clear();
					store.dispatch({
						type: loginNamespacer('SET_IS_AUTHENTICATED'),
						isTokenValid: false
					})
				}
            }
        },
        clrStates: () => {
            dispatch({
                type: twilioChatNamespacer('SET_TC_CURRENT_CHANNEL'),
                tcCurrentChannel: null,
            })
            dispatch({
                type: twilioChatNamespacer('SET_MESSAGES'),
                messages: [],
            })
            dispatch({
                type: twilioChatNamespacer('SET_SECOND_USER_DATA'),
                secondUserData: null,
            })
        },

        setSendMessageText: (value) => {
            dispatch({
                type: twilioChatNamespacer("SET_SEND_MSG_TEXT"),
                sendMessageText: value,
            });
        },

        setActiveUserChatIndex: (value) => {
            dispatch({
                type: twilioChatNamespacer('SET_ACTIVE_USER_CHAT_INDEX'),
                activeUserChatIndex: value,
            })
        },
        get getUserSubscribedChannels(){
            return async (msgFromIdentity) => {
                try{
                    dispatch({
                        type: twilioChatNamespacer('SET_MESSAGES_LOADING'),
                        loading: true,
                    })

                    const allConversationItems = chatClient.client && await this.getAllPaginatedSubscribedConversationItems()
                    
                    const channelWithOtherDetails = allConversationItems && await this.getChannelWithOtherDetails(allConversationItems, msgFromIdentity);
                    const channelWithRequiredDetails = channelWithOtherDetails && channelWithOtherDetails.map(channelObj => channelObj.value);

                    const filterChannelWithOtherDetails = channelWithRequiredDetails && channelWithRequiredDetails.filter(channel => {
                        return channel.secondUserDetail !== undefined && channel.latestMessage !== undefined;
                    })
                    
                    filterChannelWithOtherDetails && filterChannelWithOtherDetails.sort((a, b) => b.latestMessageTimeStamp - a.latestMessageTimeStamp);

                    dispatch({
                        type: twilioChatNamespacer('SET_SUBSCRIBED_CHANNEL'),
                        subscribedChannels: filterChannelWithOtherDetails,
                    })

                }
                catch(error){
                  console.log("In getUserSubscribedChannels() catch", error)
                } finally {
                    dispatch({
                        type: twilioChatNamespacer('SET_MESSAGES_LOADING'),
                        loading: false,
                    })
                }
            }
        },

        getAllPaginatedSubscribedConversationItems: async() => {
          let conversations = await chatClient.client.getSubscribedConversations();
          
          let items = conversations.items;

          while (conversations.hasNextPage) {
            const nextPageConversations = await conversations.nextPage();

            items = items.concat(nextPageConversations.items);

            conversations = nextPageConversations;
          }

          return items;
        },

        get getChannelWithOtherDetails(){
            return async (subscribedChannelsArr, msgFromIdentity) => {
                try{
                    return await Promise.allSettled(subscribedChannelsArr.map(async (channel) => {
                        const secondUserId = channel.uniqueName.split('-').filter((userId) => (
                            userId !== msgFromIdentity
                        ))

                        let secondUserDetail;
                        if (secondUserId.length === 1){
                            secondUserDetail = await this.getSecondUserDetail(secondUserId[0], false);
                        }
                        
                        const messages = await channel.getMessages(getMessageCount);
                        const totalMessagesCountOnChannel = messages.items.length;

                        let latestMessage;
                        let latestMessageTimeStamp;

                        if(totalMessagesCountOnChannel>0){
                            latestMessage = messages.items[totalMessagesCountOnChannel-1].body
                            latestMessageTimeStamp = messages.items[totalMessagesCountOnChannel-1].dateCreated
                        }
                        return({channel, secondUserDetail, messages, latestMessage, latestMessageTimeStamp})
                    })
                    ).then((results) => {
                        // console.log("results", results)
                        return results.filter(result => result.status === "fulfilled")
                    })
                }
                catch(error){
                    console.log("In getChannelWithOtherDetails catch", error)
                }
            }
        },
        
        get setCurrentChannelAndMessages(){
            return async (channelDetail) => {
                try{
                    const channel = channelDetail.channel;
                    await chatClient.attachHandlersToChannel(channel);

                    const lastMessageIndex = get(channelDetail, "channel.lastMessage.index", 0);
                    await channel.updateLastReadMessageIndex(lastMessageIndex);

                    dispatch({
                        type: twilioChatNamespacer('SET_TC_CURRENT_CHANNEL'),
                        tcCurrentChannel: channel,
                    })
                    dispatch({
                        type: twilioChatNamespacer('SET_SECOND_USER_DATA'),
                        secondUserData: channelDetail.secondUserDetail,
                    })
                    dispatch({
                        type: twilioChatNamespacer('SET_MESSAGES'),
                        messages: channelDetail.messages.items,
                    })
                }
                catch(error){
                    console.log("In setCurrentChannelAndMessages catch", error)
                }
            }
        },
        get getSecondUserDetail(){
            return async (secondUserId, setReduxState) => {
                try{
                    let response  = await getSecondUserDetailApi(secondUserId);
                    if(response.status===200){
                        if(setReduxState){
                            dispatch({
                                type: twilioChatNamespacer('SET_SECOND_USER_DATA'),
                                secondUserData: response.data.data,
                            })
                        }
                        else{
                            return response.data.data;
                        }
                    } 
                }
                catch(error){
                    console.log(error)
                }
            }
            
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Messages);

