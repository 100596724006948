import React from "react";
import logo from "../../commons/images/logo.svg";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const FeasibilityModal = (props) => {
    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} className="aboutUs-modal privacy-policy-modal">
            <ModalHeader toggle={props.toggle} className="modal-head">
                <img src={logo} alt="Gradshack" />
            </ModalHeader>
            <ModalBody className="about-details privacy-policy-details">
                <div className="content-in">
                    <ul>
                        <li>
                            <h5>What is a Feasibility Study?</h5>
                            <div>
                                <p>
                                    A feasibility study is a good first step when exploring the viability of a new
                                    business idea. If you’re enlisting someone to run a feasibility study on your idea,
                                    ask them to spend a couple of weeks researching the most basic questions –
                                    addressable market, competitive landscape, business model, key risks, etc. – and
                                    then produce a brief write-up summarizing their findings.
                                </p>
                            </div>
                        </li>
                        <li>
                            <h5>How much should I pay someone to run my feasibility study?</h5>
                            <div>
                                <p>
                                    The amount is completely up to you and may depend on how in-depth your feasibility
                                    study goes. We generally recommend something in the range of $500-$1,000.
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default FeasibilityModal;
