import { connect } from 'react-redux';
import { getUniversityList } from '../api/userForms/YourInfoForm/getUniversityList';
import { createNamespacer } from '../utils/reducers';
import { SCHOOLSMODAL } from '../constants/namespacer';
import { setEmailSubscriptionApi } from '../api/setEmailSubscription';
import SchoolsModal from '../components/schoolsModal/schoolsModal';
import store from '../store';

const schoolsModalNamespacer = createNamespacer(SCHOOLSMODAL);

const maptStateToProps = (state) => {
    return{
        schoolList: state.schoolsModal.schoolList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        getSchoolList: async () => {
            try {
                let pageCount = store.getState().schoolsModal.pageCount;
                let response = await getUniversityList(pageCount);
                let schoolList = response.data.data;
                if (response.status === 200) {
                    dispatch({
                        type: schoolsModalNamespacer('SET_SCHOOL_LIST'),
                        payload: {
                            results: schoolList,
                            pageCount: pageCount + 1
                        }
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        setEmailSubscription: async(email) => {
            try {
                let bodyFormData = new FormData();
                bodyFormData.append('email_subscription[email]', email );
                bodyFormData.append('email_subscription[active?]',true);
                await setEmailSubscriptionApi(bodyFormData);   
            }
            catch(error){
                console.log(error)
            }
        }
    }
}

export default connect(maptStateToProps, mapDispatchToProps)(SchoolsModal);


