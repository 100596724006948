import Pushy from 'pushy-sdk-web';
import store from '../store';
import { createNamespacer } from '../utils/reducers';
import { HOMEPAGE, PUSHY } from '../constants/namespacer';

const pushyNamespacer = createNamespacer(PUSHY);
const namespacer = createNamespacer(HOMEPAGE);

class PushyClient {
    constructor() {
        this.deviceToken = null;
    }

    async initialize(appId) {
        // Register visitor's browser for push notifications
        Pushy.register({ appId: appId }).then(deviceToken => {
            // Print device token to console
            console.log('Pushy device token: ' + deviceToken);
            localStorage.setItem("deviceToken", deviceToken);
            store.dispatch({
                type: pushyNamespacer('DEVICE_TOKEN'),
                deviceToken: deviceToken,
            });
        }).catch(err => {
            // Handle registration errors
            console.error(err);
        });
    try{
        // Handle push notifications (only when web page is open)
        Pushy.setNotificationListener(function (data) {
            // Print notification payload data
            console.log('Received notification: ' + JSON.stringify(data));

            store.dispatch({
                type: namespacer('ADD_NOTIFICATION'),
                data: data,
            });

            // Attempt to extract the "message" property from the payload: {"message":"Hello World!"}
            // let message = data.message || 'Test notification';

            // Display an alert with message sent from server
            // alert('Received notification: ' + message);
        });
    }
    catch{

    }
    }
}

export default new PushyClient();
