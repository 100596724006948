import { createReducer, createNamespacer } from '../utils/reducers';
import { LOGIN } from '../constants/namespacer';

const initialState = {
    authCode: '',
    isTokenValid: false,
    userId: '',
    userData: '',
    userStatus: '',
    url: '',
}

const namespacer = createNamespacer(LOGIN);

const handlers = {
    [namespacer('SET_IS_AUTHENTICATED')]: (state, action) => {
        return{
            ...state,
            isTokenValid: action.isTokenValid
        }
    },
    [namespacer('SET_AUTH_CODE')]: (state, action) => {
        return{
            ...state,
            authCode: action.authCode,
        }
    },
    [namespacer('SET_USER_ID')]: (state, action) => {
        return{
            ...state,
            userId: action.userId,
        }
    },
    [namespacer('SET_USER_DATA')]: (state, action) => {
        return{
            ...state,
            userData: action.userData,
        }
    },
    [namespacer('SET_USER_STATUS')]: (state, action) => {
        return{
            ...state,
            userStatus: action.userStatus,
        }
    },
    [namespacer('SET_REDIRECT_URL')]: (state, action) => {
        return{
            ...state,
            url: action.url,
        }
    }
}

const linkedinLoginReducer = createReducer(initialState, handlers, [LOGIN]);

export default linkedinLoginReducer;