import { Client } from "@twilio/conversations";
import store from '../store';
import { createNamespacer } from '../utils/reducers';
import { TWILIOCHAT } from '../constants/namespacer';
import { getMessageCount } from '../constants/fixedValues'

const twilioChatNamespacer = createNamespacer(TWILIOCHAT);

class ChatClient {
    constructor(){
        this.client = null;
    }

    async initialize(token) {
        // console.log("In ChatClient", this.client?._configuration?.userIdentity)
        if(!this.client){
            try{
                this.client = await new Client(token);

                // console.log("In ChatClient try", this.client?._configuration?.userIdentity)

                this.client.on('initialized', () => {
                  console.log('In initialized')
                  // console.log('In initialized', this.client?._configuration?.userIdentity)
                });

                this.client.on("connectionStateChanged", (state) => {
                  if (state === "connecting") {
                    console.log("Connecting to Twilio…")
                  }

                  if (state === "connected") {
                    console.log("You are connected.")
                  }
                  
                  if (state === "disconnecting") {
                    console.log("Disconnecting from Twilio…")
                  }
                  
                  if (state === "disconnected") {
                    console.log("Disconnected.")
                    this.client = null;
                  }
                  
                  if (state === "denied") {
                    console.log("Failed to connect.")
                  }
                });

                this.client.on("tokenAboutToExpire", async () => {
                    console.log("In tokenAboutToExpire")

                    const newToken = await window.refreshToken();

                    this.client = await this.client.updateToken(newToken);
                });

                this.client.on("tokenExpired", async () => {
                    console.log("In tokenExpired")

                    const newToken = await window.refreshToken();

                    this.client = await new Client(newToken);
                });

                return this.client;
            }
            catch(error){
                console.log(error)
            }
        } else {
          // console.log("In chatclient else", this.client?._configuration?.userIdentity)
          console.log("In chatclient else")
        }

        return this.client; 
    }

    attachHandlersToChannel = async (channel) => {
        this.client.on('messageAdded', async (msg) => {
            const newMessageOnChannel = msg.conversation;
            try{
                const messages = await newMessageOnChannel.getMessages(getMessageCount);
                store.dispatch({
                    type: 'TWILIOCHAT:SET_MESSAGES',
                    messages: messages.items
                })

                const subscribedChannels = store.getState().twilioChat.subscribedChannels;

                let CurrentChannelIndexArr;
                subscribedChannels && subscribedChannels.forEach((channelDetail, index) => {
                    if(channelDetail.channel && channelDetail.channel.sid === newMessageOnChannel.sid){
                        CurrentChannelIndexArr = index
                        return CurrentChannelIndexArr;
                    }
                });

                store.dispatch({
                    type: twilioChatNamespacer('SET_SUBSCRIBED_CHANNEL'),
                    subscribedChannels: CurrentChannelIndexArr!== undefined ? [
                        ...subscribedChannels.slice(0,CurrentChannelIndexArr),
                        {
                            ...subscribedChannels[CurrentChannelIndexArr],
                            messages: messages,
                            latestMessage: messages.items.length>0 ? messages.items[messages.items.length-1].body : '',
                            latestMessageTimeStamp: new Date(),
                        },
                        ...subscribedChannels.slice(CurrentChannelIndexArr+1)
                    ] : [...subscribedChannels],
                })

                store.dispatch({
                    type: twilioChatNamespacer('SET_SECOND_USER_DATA'),
                    secondUserData: subscribedChannels[CurrentChannelIndexArr] && subscribedChannels[CurrentChannelIndexArr].secondUserDetail,
                })

                let sortedsubscribedChannels = store.getState().twilioChat.subscribedChannels;
                sortedsubscribedChannels.sort((a, b) => b.latestMessageTimeStamp - a.latestMessageTimeStamp);
                    
                store.dispatch({
                    type: twilioChatNamespacer('SET_SUBSCRIBED_CHANNEL'),
                    subscribedChannels: sortedsubscribedChannels,
                })

                store.dispatch({
                    type: twilioChatNamespacer('SET_ACTIVE_USER_CHAT_INDEX'),
                    activeUserChatIndex: 0,
                })
            }
            catch(error){
                console.log(error)
            }
        })
    }
}

export default new ChatClient();