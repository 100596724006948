import { createReducer, createNamespacer } from "../../utils/reducers";
import { SIGNUP } from "../../constants/namespacer";

const initialState = {
  userEmail: "",
  userPassword: "",
  confirmPassword: "",
  captchaToken: "",
  redirectUrl: null,
};

const userSignUpNamespacer = createNamespacer(SIGNUP);

const handlers = {
  [userSignUpNamespacer("SET_USER_EMAIL")]: (state, action) => {
    return {
      ...state,
      userEmail: action.userEmail,
    };
  },
  [userSignUpNamespacer("SET_USER_PASSWORD")]: (state, action) => {
    return {
      ...state,
      userPassword: action.userPassword,
    };
  },
  [userSignUpNamespacer("SET_USER_CONFIRM_PASSWORD")]: (state, action) => {
    return {
      ...state,
      confirmPassword: action.confirmPassword,
    };
  },
  [userSignUpNamespacer("SET_USER_CAPTCHA_TOKEN")]: (state, action) => {
    return {
      ...state,
      captchaToken: action.captchaToken,
    };
  },
  [userSignUpNamespacer("SET_REDIRECT_URL")]: (state, action) => {
    return {
      ...state,
      redirectUrl: action.redirectUrl,
    };
  },
};

const signUp = createReducer(initialState, handlers, [SIGNUP]);

export default signUp;
