import axiosInstance from '.';

export const getFavorite = () => {
    const axios = axiosInstance();
    
    return axios({
        method: 'GET',
        url: `/favourites`,
    })
}

export const postSetFavorite = (data) => {
    const axios = axiosInstance();
    
    return axios({
        method: 'POST',
        url: `/favourites`,
        data,
    })
}

export const putSetUnfavorite = (data, favoriteId) => {
    const axios = axiosInstance();

    return axios({
        method: 'PUT',
        url: `/favourites/${favoriteId}`,
        data,
    })
}


