import React, { Component } from "react";
import { Form, FormGroup, Label } from "reactstrap";
import { needTodayList } from '../../../constants/needTodayList';
import { investorAdvisorList } from '../../../constants/investorAdvisorList';
import { venturesList } from '../../../constants/venturesList';
import NeedsItem from '../../../containers/userForms/NeedsItem';

class EditVentureForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tempVenture: '',
            tempInvestorType: '',
            tempFeasibilityStudyAmount: null,
            needsList: []
        };
    }

    componentWillReceiveProps(nextProps) {
        let feasibilityStudyValue = '';
        let investorTypeValue = '';
        if (nextProps.needs) {
            this.setState({ needsList: [ ...nextProps.needs ] });
    
            feasibilityStudyValue = nextProps.needs.find(item => {
                return item.includes('|') && item.split('|')[0] === 'feasibility_study';
            });
        }
        if (feasibilityStudyValue && typeof feasibilityStudyValue !== 'undefined' && !this.state.tempFeasibilityStudyAmount) {
            const feasibilityStudyAmount = feasibilityStudyValue.split('|')[1];
            this.setState({ tempFeasibilityStudyAmount: feasibilityStudyAmount });
            this.props.setFeasibilityStudyAmount(feasibilityStudyAmount);
        }
        if (nextProps.needs) {
            investorTypeValue = nextProps.needs.find(item => {
                return item.includes('|') && item.split('|')[0] === 'advisor';
            });
        }
        if (typeof investorTypeValue !== 'undefined' && !this.state.tempInvestorType) {
            const tempInvestorType = investorTypeValue.split('|')[1];
            this.setState({ tempInvestorType: tempInvestorType });
            this.props.setInvestorType(tempInvestorType);
        }
    }

    handleConceptChange = e => {
        this.props.setConcept(e.target.value);
    };

    handleVentureChange = value => {
        this.setState({ tempVenture: value });
        this.props.setVenture(value);
    };

    handleInvestorTypeSet = value => {
        this.setState({ tempInvestorType: value });
        this.props.setInvestorType(value);
    };

    handleFeasibilityStudyAmount = e => {
        this.setState({ tempFeasibilityStudyAmount: e.target.value });
        this.props.setFeasibilityStudyAmount(e.target.value);
    };

    handleNeedsChanged = e => {
        let needId = e.target.id;
        const isNeedChecked = e.target.checked;

        if (isNeedChecked) {
            this.setState(state => {

                if (needId === 'feasibility_study') {
                    needId += '|' + this.state.tempFeasibilityStudyAmount;
                }

                // if (needId === 'advisor') {
                //     needId += '|' + this.state.tempInvestorType;
                // }

                const needsList = state.needsList.concat(needId);
                this.props.setNeeds(needsList);

                return {
                    needsList,
                    tempVenture: state.tempVenture,
                };
            });
            return;
        }

        const index = this.state.needsList.findIndex(item => {
            return item.includes('|')
                ? item.split('|')[0] === needId
                : item === needId
        });
        if (!isNeedChecked && index > -1) {
            this.setState(state => {
                const needsList = state.needsList;
                needsList.splice(index, 1);
                this.props.setNeeds(needsList);

                return {
                    needsList,
                    tempVenture: state.tempVenture,
                };
            });
            return;
        }
    };

    isNeedsItemChecked = need => {
        const index = this.state.needsList
            ? this.state.needsList.findIndex(item => item.includes('|') && item.split('|')[0] === need.attributes.id)
            : -1;
        if (index > -1) {
            return this.state.needsList[index].split('|')[0] === need.attributes.id;
        } else {
            return this.state.needsList.includes(need.attributes.id);
        }
    };

    isFeasibilityStudySelected = need => {
        return this.state.needsList.findIndex(item => {
            return item.includes('|') && item.split('|')[0] === need.attributes.id;
        }) > -1;
    }

    render() {
        const { concept, venture } = this.props;
        return (
            <div className="form-wrapper edit-form-wrapper">
                <Form className="form-container">
                    <FormGroup>
                        <Label>My Concept</Label>
                        <textarea
                            type="textarea"
                            name="text"
                            placeholder="Write here"
                            rows="4"
                            cols="50"
                            maxLength="90"
                            value={concept}
                            onChange={this.handleConceptChange}
                        />
                        <span className="note-text">Maximum 90 Characters</span>
                    </FormGroup>
                    <FormGroup>
                        <Label>This venture is currently</Label>
                        <ul className="venture-options-wrap">
                            {
                                venturesList.map((ventureItem, index) => (
                                    <li
                                        key={index}
                                        className={`venture-option ${
                                            (this.state.tempVenture === ventureItem.attributes.id || venture === ventureItem.attributes.id)
                                                ? "venture-selected"
                                                : ""
                                            }`}
                                        onClick={() => this.handleVentureChange(ventureItem.attributes.id)}
                                    >
                                        <img src={ventureItem.attributes.image} alt="no_image" />
                                        <span>{ ventureItem.attributes.name }</span>
                                    </li>
                                ))
                            }
                        </ul>
                    </FormGroup>
                    <FormGroup className="multi-select ">
                        <Label>What I need today</Label>
                        <ul>
                            {
                                needTodayList.map((need, index) => (
                                    <NeedsItem
                                        key={index}
                                        index={index}
                                        need={need}
                                        checked={this.isNeedsItemChecked(need)}
                                        isFeasibilityStudySelected={this.isFeasibilityStudySelected(need)}
                                        feasibilityStudyAmount={this.state.tempFeasibilityStudyAmount}
                                        handleFeasibilityStudyAmount={this.handleFeasibilityStudyAmount}
                                        handleNeedsChanged={this.handleNeedsChanged}
                                    />
                                ))
                            }
                        </ul>
                    </FormGroup>
                    <FormGroup>
                        {
                            this.state.needsList.findIndex(item => item.includes('|') && item.split('|')[0] === 'advisor') > -1 &&
                            <ul className="venture-options-wrap funding-wrap">
                                {
                                    investorAdvisorList.map((investorType, index) => (
                                        <li
                                            key={index}
                                            className={`venture-option ${
                                                this.state.tempInvestorType === investorType.attributes.name
                                                    ? "venture-selected"
                                                    : ""
                                                }`}
                                            onClick={() => this.handleInvestorTypeSet(investorType.attributes.name)}
                                        >
                                            <span>{ investorType.attributes.name }</span>
                                        </li>
                                    ))
                                }
                            </ul>
                        }
                    </FormGroup>

                </Form>
            </div>
        );
    }
}

export default EditVentureForm;