import React, { Component } from "react";
import { Nav, NavItem, NavLink, Navbar, Button } from "reactstrap";
import { NavLink as RouterNavLink } from 'react-router-dom';
import NewPostModal from '../../newPostModal/NewPostModal';

class MobileMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
          activeTab: '',
          isNewPostModalOpen: false,
        };
    
        this.toggle = this.toggle.bind(this);
        this.newPostModalToggle = this.newPostModalToggle.bind(this);
    }
    
    toggle(tab) {
        this.setState({
            activeTab: tab
        });
        if(tab==='4'){
            this.props.clearNewMessageFromCount();
        }
    }
    componentDidMount() {
        this.props.checkInitialHomePageSettings();
    }


    setActive = (value) => {
        this.props.setActiveScreen(value, this.props.profilePicBase64Copy);
    };

    newPostModalToggle() {
        this.setState({
            isNewPostModalOpen: !this.state.isNewPostModalOpen
        });
    }

    handleClickAddPost() {
        this.newPostModalToggle();
    }

    render() {
        const { activeTab } = this.state;
        const {
            newMessageFromCount,
            getStreamApiKey,
            getStreamAppId,
            getStreamApiToken,
            getStreamFeedName,
            getStreamNotificationFeedName,
        } = this.props;
        return (
            <Navbar className="custom-container-wrapper mobile-menu-container">
                <Nav tabs>
                    <NavItem>
                        <NavLink 
                            to="/newsfeed" 
                            tag={RouterNavLink}
                            onClick={() => { this.toggle('1'); }}
                            className={`${activeTab==='1' ? 'active' : ''}`}
                        > 
                            <span className="menu-icon"></span>
                            <span>News</span>
                        </NavLink>
                    </NavItem>
					<NavItem>
                        <NavLink 
                            to="/homepage" 
                            tag={RouterNavLink}
                            onClick={() => { this.toggle('2'); }}
                            className={`${activeTab==='2' ? 'active' : ''}`}
                        > 
                            <span className="menu-icon"></span>
                            <span>Members</span>
                        </NavLink>
                    </NavItem>
                    <NavItem className="new-post-nav-item">
						<NavLink
                            className={`${activeTab === '2' ? 'active' : ''}`}
							onClick={() => { this.handleClickAddPost() }}
                        >
                            <span className="menu-icon"></span>
                            <span>New Post</span>
						</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink 
                            to="/userMessage" 
                            tag={RouterNavLink}
                            onClick={() => { this.toggle('5'); }}
                            className={`${activeTab==='5' ? 'active' : ''}`}
                        >
                            <span className="menu-icon"></span>
                            <span>Messages</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink 
                            to="/match" 
                            tag={RouterNavLink}
                            onClick={() => { this.toggle('4'); }}
                            className={`${activeTab==='4' ? 'active' : ''}`}
                        >
                            <span className="menu-icon"></span>
                            <span>
								Call Us
                            </span>
                        </NavLink>
                    </NavItem>

                </Nav>
                <NewPostModal
                    isOpen={this.state.isNewPostModalOpen}
                    toggle={this.newPostModalToggle}
                    apiKey={getStreamApiKey}
                    appId={getStreamAppId}
                    token={getStreamApiToken}
                    userId={getStreamFeedName}
                    notificationFeedName={getStreamNotificationFeedName}
                />
            </Navbar>
        );
    }
}

export default MobileMenu;