import { connect } from 'react-redux';
import { getUserDataById } from '../api/commonApi/getUserDataById';
import { createNamespacer } from '../utils/reducers';
import { HOMEPAGE, NEWSIGNUPUSERCARD } from '../constants/namespacer';
import NewSignUpUserCard from "../components/newsFeed/newSignUpUserCard/NewSignUpUserCard";
import { getFavorite } from "../api/favorites";

const newsSignUpUserCardNamespacer = createNamespacer(NEWSIGNUPUSERCARD);
const favUserNamespacer = createNamespacer(HOMEPAGE);

const mapStateToProps = (state) => {
    return {
        users: state.newSignUpUserCard.users,
        favoriteUsersList: state.favourites.favoriteUsersList,
        favoriteUsersListLength: state.favourites.favoriteUsersListLength,
        favRefreshRequired: state.filterUsers.favRefreshRequired,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setfavRefreshRequired: () => {
            dispatch({
                type: favUserNamespacer('SET_FAV_REFRESH_REQ'),
                favRefreshRequired: false
            })
        },
        getNewSignUpUser: async(id) => {
            try {
                let response  = await getUserDataById(id);

                dispatch({
                    type: newsSignUpUserCardNamespacer('GET_NEW_SIGN_UP_USER'),
                    user: response.data.data
                });
            }
            catch(error) {
                console.error("In getNewSignUpUser catch", error, error?.body);
            }
        },
        getFavoriteList: async () => {
            try {
                let response = await getFavorite();
                let favoriteUsersList = response.data.data.map((favoriteUser) => {
                    return{
                        userId: favoriteUser.relationships.favourite.data.id,
                        favouriteId: favoriteUser.id,
                        notes: favoriteUser.attributes.notes,
                    }
                })
                dispatch({
                    type: favUserNamespacer('SET_FAVORITE_USER_LIST'),
                    favoriteUsersList: favoriteUsersList,
                })
            }
            catch (error) {
                console.error(error)
            }
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewSignUpUserCard);
