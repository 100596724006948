export const LOGIN = "LOGIN";
export const USERTYPE = "USERTYPE";
export const PROFILEUSER = "PROFILEUSER";
export const ADDITIONALDETAILSTAB = "ADDITIONALDETAILSTAB";
export const USERFORMDATA = "USERFORMDATA";
export const USERFORMTAB = "USERFORMTAB";
export const HOMEPAGE = "HOMEPAGE";
export const FILTERS = "FILTERS";
export const ADMINLOGIN = "ADMINLOGIN";
export const ADMINHOMEPAGE = "ADMINHOMEPAGE";
export const TWILIOCHAT = "TWILIOCHAT";
export const SCHOOLSMODAL = "SCHOOLSMODAL";
export const VALIDATIONERROR = "VALIDATIONERROR";
export const PAGINATION = "PAGINATION";
export const SIGNUP = "SIGNUP";
export const USERLOGIN = "USERLOGIN";
export const NEWSFEED = "NEWSFEED";
export const USERNEWSFEED = "USERNEWSFEED";
export const NEWSIGNUPUSERCARD = "NEWSIGNUPUSERCARD";
export const FAVOURITETIMELINEITEM = "FAVOURITETIMELINEITEM";
export const NOTIFICATIONSFORM = "NOTIFICATIONSFORM";
export const PUSHY = "PUSHY";
export const LOGINDETAILS = "LOGINDETAILS";