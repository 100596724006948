import { connect } from "react-redux";
import { createNamespacer } from "../../utils/reducers";
import { TWILIOCHAT } from "../../constants/namespacer";
import MessageBlockChatSection from "../../components/messages/messageBlock/messageBlockChatSection/MessageBlockChatSection";
import store from "../../store";
import { notifyUserAboutUnreadMessages } from "../../utils/twilio";

const twilioChatNamespacer = createNamespacer(TWILIOCHAT);

const mapStateToProps = (state) => {
    return {
        twilioToken: state.twilioChat.twilioToken,
        messages: state.twilioChat.messages,
        sendMessageText: state.twilioChat.sendMessageText,
        tcCurrentChannel: state.twilioChat.tcCurrentChannel,
        subscribedChannels: state.twilioChat.subscribedChannels,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSendMessageText: (value) => {
            dispatch({
                type: twilioChatNamespacer("SET_SEND_MSG_TEXT"),
                sendMessageText: value,
            });
        },
        get sendMessageToChannel() {
            return async (channelssss, msg) => {
                let channel = store.getState().twilioChat.tcCurrentChannel;
                try {
                    await channel.sendMessage(msg);

                    const lastMessageIndex = channel.lastMessage.index;
                    await channel.updateLastReadMessageIndex(lastMessageIndex);

                    notifyUserAboutUnreadMessages(channel, msg);

                    const subscribedChannels = store.getState().twilioChat.subscribedChannels;
                    let CurrentChannelIndexArr;
                    subscribedChannels.forEach((channelDetail, index) => {
                        if (channelDetail.channel.sid === channel.sid) {
                            CurrentChannelIndexArr = index;
                            return CurrentChannelIndexArr;
                        }
                    });

                    dispatch({
                        type: twilioChatNamespacer("SET_SUBSCRIBED_CHANNEL"),
                        subscribedChannels: [
                            ...subscribedChannels.slice(0, CurrentChannelIndexArr),
                            {
                                ...subscribedChannels[CurrentChannelIndexArr],
                                latestMessage: msg,
                                latestMessageTimeStamp: new Date(),
                            },
                            ...subscribedChannels.slice(CurrentChannelIndexArr + 1),
                        ],
                    });

                    let sortedsubscribedChannels = store.getState().twilioChat.subscribedChannels;
                    sortedsubscribedChannels.sort(
                        (a, b) => b.latestMessageTimeStamp - a.latestMessageTimeStamp
                    );

                    dispatch({
                        type: twilioChatNamespacer("SET_SUBSCRIBED_CHANNEL"),
                        subscribedChannels: sortedsubscribedChannels,
                    });

                    dispatch({
                        type: twilioChatNamespacer("SET_ACTIVE_USER_CHAT_INDEX"),
                        activeUserChatIndex: 0,
                    });
                } catch (error) {
                    console.error(
                        "In Message block sendMessageToChannel() catch",
                        error,
                        error?.body
                    );
                }
            };
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageBlockChatSection);
