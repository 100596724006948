import React from 'react';
import { connect } from 'react-redux';
import "rc-pagination/assets/index.css";

import RCPagination from 'rc-pagination';

const Pagination = (props) => {
    const { current, total, pageSize, onChange, totalRecords, recordsPerPage } = props;
    return(
        <RCPagination
            current={current}
            total={total || totalRecords}
            pageSize={pageSize || recordsPerPage}
            onChange={onChange}
        />
    );
}

const mapStateToProps = (state) => {
    return{
        totalRecords: state.pagination.totalRecords,
        recordsPerPage: state.pagination.recordsPerPage,
    }
}

export default connect(mapStateToProps)(Pagination);