import React, { Component } from "react";
import logo from "../../commons/images/logo.svg";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

class AboutUsModal extends Component {
  state = {};
  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className="aboutUs-modal"
      >
        <ModalHeader toggle={this.props.toggle} className="modal-head">
          <img src={logo} alt="Gradshack" />
        </ModalHeader>
        <ModalBody className="about-details">
          <div className="faq-wrapper screen-height">
            <div className="custom-profile-container faq-container">
              <div className="faq-content">
                <p className="faq-desc">
                  GradShack leverages the community around your alma mater or
                  current school to help you find:
                </p>
              </div>

              <div className="faq-content">
                <p className="faq-desc">
                  - Someone to run with your business idea that you’re too busy
                  to pursue, or an early hire or advisor for your startup
                </p>
                <p className="faq-desc">
                  - Someone with a business idea or startup you’d be suitable to
                  cofound or advise
                </p>
                <p className="faq-desc">
                  - Referrals/advice to accelerate your launch (e.g. “Looking
                  for a cost-effective developer to build me an MVP-lite”)
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default AboutUsModal;
