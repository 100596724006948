import React from "react";
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import page_not_found from "../../commons/images/page_not_found.svg";
import oval_1 from "../../commons/images/oval_1.svg";
import oval_2 from "../../commons/images/oval_2.svg";

const PageNotFound = () => {
  return (
      <div className="page-not-found-container">
        <div className="sub-container">
          <img src={page_not_found} alt="no_image" className="page-not-found-image"/>
          <span className="page-not-found-label">Page not found</span>
        </div>
        <img src={oval_1} alt="no_image" className="oval_1"/>
        <img src={oval_2} alt="no_image" className="oval_2"/>
        <Link  to="/">
        <Button className="page-button">Go back home</Button>
        </Link>
           
      </div>
  );
};
export default PageNotFound;
