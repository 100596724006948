import React, { Component } from "react";
import { Redirect } from "react-router";
// import { Link } from "react-router-dom";
import { Form, Button } from "reactstrap";
import { loginUser } from "../../../api/userForms/loginUser";
import { putUserData } from "../../../api/userForms/putUserData";
import FormInput from "../../commonComponents/FormGroupInputBox";
import { InputGroup, InputGroupAddon } from 'reactstrap';
import view from '../../../commons/images/view.png';
import hide from '../../../commons/images/hide.png';

class LoginDetailsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidPassword: true,
      isValidEmail: true,
      redirectTo: "",
      show: false
    };
  }

  emailValidate = (email) => {
    if (/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g.test(email)) {
      this.setState({ isValidEmail: true });
    } else {
      this.setState({ isValidEmail: false });
    }
  };

  passwordValidate = (password) => {
    if (/^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])[\w!@#$%^&*]{8,}$/.test(password)) {
      this.setState({ isValidPassword: true });
    } else {
      this.setState({ isValidPassword: false });
    }
  };

  handlePasswordChange = (event) => {
    const value = event.target.value;
    this.passwordValidate(value);
    this.props.setPassword(value);
  };

  handleEmailChange = (event) => {
    const value = event.target.value;
    this.emailValidate(value);
    this.props.setEmail(value);
  };

  loginDetailsHandler = async () => {
    const { email, password } = this.props;
    try {
      const bodyFormData = new FormData();
      bodyFormData.append("user[email]", email);
      bodyFormData.append("user[password]", password);

      await putUserData(bodyFormData);
      const loginResp = await loginUser(bodyFormData);

      if (loginResp.status === 200) {
        localStorage.setItem("id", loginResp.data.user.id);
        localStorage.setItem("auth_code", loginResp.data.auth_token);
        localStorage.setItem("isLogin", true);
        localStorage.setItem("isAdmin", false);
        localStorage.setItem("deviceToken", loginResp.data.user.pushy_token);
        localStorage.setItem("from", "Login");
        if (loginResp.data.user.newsfeed) {
          localStorage.setItem(
            "getStreamApiKey",
            loginResp.data.user.newsfeed.api_key
          );
          localStorage.setItem(
            "getStreamAppId",
            loginResp.data.user.newsfeed.app_id
          );
          localStorage.setItem(
            "getStreamApiToken",
            loginResp.data.user.newsfeed.newsfeed_token
          );
          localStorage.setItem(
            "getStreamFeedName",
            loginResp.data.user.newsfeed.feed_name
          );
          localStorage.setItem(
            "getStreamNotificationFeedName",
            loginResp.data.user.newsfeed.notification_feed_name
          );
        }
        this.props.setTokenValid();
        this.setState({
            redirectTo: "/linkedin/auth",
        })       
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessages = error.response.data.error;
        if (errorMessages) {
          alert(errorMessages);
        }
      }
    }
  };

  componentDidMount() {
    this.props.setEmail(this.props.registeredEmail);
  }

  onEyeIconClick = () => {
    this.setState({
      inputType: !this.state.inputType
    })
  }

  render() {
    const { redirectTo, inputType } = this.state;
    const { email, password } = this.props;

    return (
      <div className="form-wrapper">
        <h3 className="login-form-header">
          {" "}
          Set Email/Password for your GradShack account
        </h3>
        <Form className="form-container signup-form">
          <FormInput
            type="email"
            placeholder="Enter Email"
            value={this.props.email}
            isValid={this.state.isValidEmail}
            onChange={this.handleEmailChange}
            invalidMessage="Invalid email."
          />
          <InputGroup style={{ display: 'inline' }}>
            <InputGroupAddon addonType="prepend">
              <img
                src={inputType ? hide : view}
                alt='view'
                onClick={this.onEyeIconClick}
                style={{ width: '20px', height: '20px', position: 'absolute', right: '10px', top: '20px', zIndex: 999 }}
                />
            </InputGroupAddon>
            <FormInput
              type={inputType ? 'text' : 'password'}
              placeholder="Create Password"
              value={this.props.password}
              isValid={this.state.isValidPassword}
              onChange={this.handlePasswordChange}
              invalidMessage="Password must be at least eight characters long and must contain at least one number, one upper case letter and one lower case letter."
            />
          </InputGroup>

          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              className="landing-btn w-100"
              disabled={!email || !password}
              style={{
                cursor: !email || !password ? "not-allowed" : "pointer",
              }}
              onClick={this.loginDetailsHandler}
            >
              Finish
            </Button>
          </div>
        </Form>
        {redirectTo ? <Redirect to={redirectTo} /> : null}
      </div>
    );
  }
}

export default LoginDetailsTab;
