import { createReducer, createNamespacer } from '../../utils/reducers';
import { USERFORMDATA } from '../../constants/namespacer';

const initialState = {
    concept: '',
    venture: '',
    needs: '',
    feasibilityStudyAmount: 0,
    investorType: '',
    additionalDetails: '',
}

const namespacer = createNamespacer(USERFORMDATA);

const handlers = {
    [namespacer('SET_CONCEPT_VALUE')]: (state, action) => {
        return{
            ...state,
            concept: action.concept,
        }
    },
    [namespacer('SET_VENTURE_VALUE')]: (state, action) => {
        return{
            ...state,
            venture: action.venture,
        }
    },
    [namespacer('SET_NEEDS_VALUE')]: (state, action) => {
        return{
            ...state,
            needs: action.needs,
        }
    },
    [namespacer('SET_FEASIBILITY_STUDY_AMOUNT_VALUE')]: (state, action) => {
        return{
            ...state,
            feasibilityStudyAmount: action.feasibilityStudyAmount,
        }
    },
    [namespacer('SET_INVESTOR_VALUE')]: (state, action) => {
        return{
            ...state,
            investorType: action.investorType,
        }
    },
    [namespacer('SET_ADDITIONAL_DETAILS_VALUE')]: (state, action) => {
        return{
            ...state,
            additionalDetails: action.additionalDetails,
        }
    },
}

const userFormDataReducer = createReducer(initialState, handlers, [USERFORMDATA]);

export default userFormDataReducer;