const now = new Date();
const currentYear = now.getFullYear();
const maxYear = currentYear+4;
const minYear = currentYear-50;

let temp = [];

for(let i=maxYear, j=0; i>=minYear && j<50; i--, j++){
    temp[j] = {
        "attributes" : { id: i, name: i},
    }
}

export const graduationYearList = temp;