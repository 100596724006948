import { connect } from 'react-redux';
import { getAdminNotificationListAPI, markAdminNotificationReadAPI } from '../../api/adminNotifications'
import AdminNotifications from '../../components/adminNotifications/AdminNotifications';
import { createNamespacer } from '../../utils/reducers';
import { ADMINLOGIN, PAGINATION } from '../../constants/namespacer';
import store from '../../store';

const adminLoginNamespacer = createNamespacer(ADMINLOGIN);
const paginationNamespacer = createNamespacer(PAGINATION);

const mapStateToProps = (state) => {
    return {
        adminNotificationList: state.desktopHeader.adminNotificationList,
        totalRecords: state.pagination.totalRecords,
        recordsPerPage: state.pagination.recordsPerPage,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        getAdminNotifications: async(page) => {
            try {
                let response  = await getAdminNotificationListAPI(page);
                if(response.status===200){
                    dispatch({
                        type: adminLoginNamespacer('SET_ADMIN_NOTIFICATION'),
                        adminNotificationList: response.data.data,
                    })
                    dispatch({
                        type: paginationNamespacer('SET_TOTAL_RECORDS'),
                        totalRecords: response.data.meta['total-records']
                    })
                } 
            }
            catch(error){
                console.log(error);
            }            
        },
        markAdminNotificationRead: async(notificationId, notificationIndexInList = 0) => {
            try{
                await markAdminNotificationReadAPI(notificationId);

                const adminNotificationList = store.getState().desktopHeader.adminNotificationList;
                const updatedAdminNotificationList = [
                    ...adminNotificationList.slice(0, notificationIndexInList),
                    {
                        ...adminNotificationList[notificationIndexInList],
                        attributes: {
                            ...adminNotificationList[notificationIndexInList].attributes,
                            msg_read: true
                        }
                    },
                    ...adminNotificationList.slice(notificationIndexInList+1)
                ]
                dispatch({
                    type: adminLoginNamespacer('SET_ADMIN_NOTIFICATION'),
                    adminNotificationList: updatedAdminNotificationList
                })
            }
            catch(error){
                console.log(error);
            }
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminNotifications);

