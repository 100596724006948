import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Filters from "../../containers/Filters";

class FilterModal extends Component {
    render() {
        return (
            <div>
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="filter-modal">
                <ModalHeader toggle={this.props.toggle} className="modal-head"></ModalHeader>
                <ModalBody className="mobile-filter-options">
                    <Filters/>
                </ModalBody>
                
            </Modal>
        </div>
        );
    }
}

export default FilterModal;