import { createReducer, createNamespacer } from '../../utils/reducers';
import { ADMINLOGIN } from '../../constants/namespacer';

const initialState = {
    adminEmail: '',
    adminPassword: '',
    isAdmin: false,
}

const adminLoginNamespacer = createNamespacer(ADMINLOGIN);

const handlers = {
    [adminLoginNamespacer('SET_ADMIN_EMAIL')]: (state, action) => {
        return{
            ...state,
            adminEmail: action.adminEmail,
        }
    },
    [adminLoginNamespacer('SET_ADMIN_PASSWORD')]: (state, action) => {
        return{
            ...state,
            adminPassword: action.adminPassword,
        }
    },
    [adminLoginNamespacer('SET_IS_ADMIN_FLAG')]: (state, action) => {
        return{
            ...state,
            isAdmin: action.isAdmin,
        }
    }
    
}

const adminLoginReducer = createReducer(initialState, handlers, [ADMINLOGIN]);

export default adminLoginReducer;