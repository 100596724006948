import { createReducer, createNamespacer } from '../../utils/reducers';
import { NOTIFICATIONSFORM } from '../../constants/namespacer';

const initialState = {
    notificationOptions: {
        general: {},
        sector: {},
        typePosts: {},
    },
    notificationRules: {},
};

const namespacer = createNamespacer(NOTIFICATIONSFORM);

const handlers = {
    [namespacer('SET_NOTIFICATION_OPTIONS')]: (state, action) => {
        return{
            ...state,
            notificationOptions: action.notificationOptions,
        }
    },
};

const notificationsFormReducer = createReducer(initialState, handlers, [NOTIFICATIONSFORM]);

export default notificationsFormReducer;
