import { createReducer, createNamespacer } from '../utils/reducers';
import { PAGINATION } from '../constants/namespacer';

const initialState = {
    totalRecords: 0,
    recordsPerPage: 20,
}

const paginationNamespacer = createNamespacer(PAGINATION);

const handlers = {
    [paginationNamespacer('SET_TOTAL_RECORDS')]: (state, action) => {
        return{
            ...state,
            totalRecords: action.totalRecords,
        }
    },
    [paginationNamespacer('SET_RECORD_PER_PAGE')]: (state, action) => {
        return{
            ...state,
            recordsPerPage: action.recordsPerPage,
        }
    }
}

const paginationReducer = createReducer(initialState, handlers, [PAGINATION]);

export default paginationReducer;