import React, {Component} from 'react';
import DesktopHeader from '../../containers/homePage/DesktopHeader';
import MessageList from './messageList/MessageList';
import MessageBlock from './messageBlock/MessageBlock';
import MobileMenu from '../../containers/homePage/MobileMenu';
import BlockingLoader from '../commonComponents/Loader';
import chatClient from "../../services/twilioConversation";

class Messages extends Component {
    state={
        viewChat: false,
        twilioToken: "",
        loading: false,
    }
    toggleViewChat = () => {
        this.setState({
            viewChat: !this.state.viewChat,
        })
    }

    async componentDidMount(){
        const { msgFromIdentity, twilioToken } = this.props;
        this.props.clrStates();
        this.props.getUserSubscribedChannels(msgFromIdentity);
        if(!msgFromIdentity || !twilioToken){
            this.setState({ loading: true })
            let token = await this.props.getTwilioToken();
            await chatClient.initialize(token);
            this.setState({ loading: false })
        }
        this.props.getUserSubscribedChannels(msgFromIdentity || localStorage.getItem('id'));
    }
    static getDerivedStateFromProps(props, state){
        const { subscribedChannels, messages, secondUserData } = props;
        if(subscribedChannels && subscribedChannels[0] && messages.length===0 && secondUserData===null){
            const channelDetail = props.subscribedChannels[0];
            props.setActiveUserChatIndex(0);
            props.setCurrentChannelAndMessages(channelDetail);
        }
        return {}
    }
    render() {
        const { subscribedChannels, messages, secondUserData, twilioToken, loading } = this.props;
        return (
            <React.Fragment>
                <DesktopHeader/>
                {loading || this.state.loading ? <BlockingLoader/> : null}
                {
                subscribedChannels && subscribedChannels.length > 0 ?
                <div className="custom-container-wrapper message-wrapper screen-height chat-histroy-wrapper">
                    {/* Required later
                    <div className="pagination-container">
                        <span className="custom-pagination">Showing 10 of 300</span>
                    </div> */}
                    <div className={`messages-members-container ${this.state.viewChat?'message-member':''}`}>
                        <span className="mobile-message-header">Messages</span>
                        <MessageList
                            subscribedChannels={subscribedChannels}
                            setCurrentChannelAndMessages={this.props.setCurrentChannelAndMessages}
                            setActiveUserChatIndex={this.props.setActiveUserChatIndex}
                            activeUserChatIndex={this.props.activeUserChatIndex}
                            twilioToken={twilioToken}
                            viewChat={this.state.viewChat}
                            toggleViewChat={this.toggleViewChat}
                            setSendMessageText={this.props.setSendMessageText}
                        />
                        <MessageBlock
                            messages={messages}
                            secondUserData={secondUserData}
                            viewChat={this.state.viewChat}
                            toggleViewChat={this.toggleViewChat}
                            setSendMessageText={this.props.setSendMessageText}
                        />
                    </div>
                </div>
                : <div className="loading-btn"> 
                    <div className="pagination-container pagination-container-admin-messages">
                        {!(loading || this.state.loading) && <span className="custom-pagination">No Messages</span>}
                    </div>
                </div>
                }
                <MobileMenu />
            </React.Fragment>
        );
    }
}

export default Messages;