import axiosInstance from '.';

export const getUserListForAdminApi = (userStatus, userType, page=1, limit=20) => {
    return axiosInstance()({
        method: 'GET',
        url: `/admin/users?status=${userStatus}&type=${userType}&page=${page}&per_page=${limit}`,
    })
}

export const putApproveUser = (data, pendingUserId) => {
    return axiosInstance()({
        method: 'PUT',
        url: `/admin/users/${pendingUserId}`,
        data,
    })
}

export const putRejectUser = (data, pendingUserId) => {
    return axiosInstance()({
        method: 'PUT',
        url: `/admin/users/${pendingUserId}`,
        data,
    })
}

export const getPendingUserData = (pendingUserId) => {
    return axiosInstance()({
        method: 'GET',
        url:`/users/${pendingUserId}`
    })
}

export const getTwilioTokenForUserApi = (userEmail) => {
    return axiosInstance()({
        method: 'POST',
        url: `/chats?email=${userEmail}`
    })
}

export const getFilterExistingUserListApi = (userStatus, userType, colOrUnivId, page=1, limit=20) => {
    return axiosInstance()({
        method: 'GET',
        url: `/admin/users?status=${userStatus}&type=${userType}&university_id=${colOrUnivId}&page=${page}&per_page=${limit}`
    })
}