import React from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default class Spinner extends React.Component {
  render() {
    return (
      <div className="spinner">
        <Loader
          type="TailSpin"
          color="#F8434E"
          height={100}
          width={100}
          timeout={700}
        />
      </div>
    );
  }
}
