import chatClient from "../services/twilioConversation";
import { notifyUserToTriggerMail } from "../api/twilioChat";

export const notifyUserAboutUnreadMessages = (conversation, message) => {
    const memberValuesArray = Array.from(conversation._participants.values());

    const receiver = memberValuesArray.filter(
        (p) => p.identity !== chatClient.client.user.identity
    )[0];

    if (!receiver.lastReadTimestamp || timeDifferenceInMinutes(conversation, receiver) > 10) {
        const params = {
            s_email: chatClient.client.user.identity,
            r_email: receiver.identity,
            message,
        };

        notifyUserToTriggerMail(params);
    }
};

const timeDifferenceInMinutes = (conversation, user) => {
    return (conversation.lastMessage.dateCreated - user.lastReadTimestamp) / 60000;
};
