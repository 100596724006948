import { connect } from "react-redux";
import LoginDetailsTab from "../../components/userForms/loginDetails/LoginDetails";
import { LOGIN, LOGINDETAILS } from "../../constants/namespacer";
import { createNamespacer } from "../../utils/reducers";

const loginDetailsTabNamespacer = createNamespacer(LOGINDETAILS);
const loginNamespacer = createNamespacer(LOGIN);

const mapStateToProps = (state) => {
  return {
    email: state.loginDetails.email,
    password: state.loginDetails.password,
    isTokenValid: state.redirectAfterLogin.isTokenValid,
    registeredEmail: state.yourInfoForm.email
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setEmail: (value) => {
      dispatch({
        type: loginDetailsTabNamespacer("SET_EMAIL_VALUE"),
        email: value,
      });
    },
    setPassword: (value) => {
        dispatch({
          type: loginDetailsTabNamespacer("SET_PASSWORD_VALUE"),
          password: value,
        });
      },

      setTokenValid: async(bodyFormData) => {
        dispatch({
            type: loginNamespacer('SET_IS_AUTHENTICATED'),
            isTokenValid: true
        })
    },

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginDetailsTab);
