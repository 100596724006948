import React, { Component, Fragment } from "react";
import { Button, Spinner } from 'reactstrap';
import NoUsers from '../noUsers/NoUsers';
import profilePic from "../../commons/images/profile_pic.svg"
import { Redirect, withRouter } from 'react-router-dom'
import StudentAlumniTabs from './studentAlumniTabs';
import cx from 'classnames';
import advisorBanner from "../../commons/images/advisor_ribbon.svg";

const formatUniversityName = (university) => {
    return university.nickname || university.name;
}

const UserCard = ({ userType, userDetail }) => {
    return (
        <>
            <div className="basic-details-top-admin">
                <div className="left-user-profile">
                    <div className="user-profile">
                        <div className="profile-picture">
                            <img
                                src={userDetail.attributes['image-url'] ? userDetail.attributes['image-url'] : profilePic}
                                alt="no_image"
								onError={(e)=>{e.target.onerror = null; e.target.src=profilePic}}
                            />
                            {userType === "advisor" || userDetail.attributes['is-investor'] ? <img src={advisorBanner} alt="Advisor" className="advisor-banner"/> : null}
                        </div>
                        <div className="profile-details">
                            <span>{`${userDetail.attributes['first-name']} ${userDetail.attributes['last-name']}`}</span>
                            {
                                userDetail.attributes.university ?
                                <span>{formatUniversityName(userDetail.attributes.university)} {userDetail.attributes['mba-program']} {userDetail.attributes['grad-year']}</span>
                                : ''
                            }
                            {
                                userDetail.attributes.location ?
                                <span>{userDetail.attributes.location.name}, {userDetail.attributes.location.code}</span>
                                : ''
                            }
                        </div>
                    </div>
                    {
                        userDetail.attributes['profile-url'] ?
                        <div className="user-contact-details">
                            <a
                                href={userDetail.attributes['profile-url']}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="linked-link">LinkedIn Profile
                            </a>
                        </div> : ''
                    }
                </div>
                <div className="right-user-profile">
            {/* {
                userType==='advisor' ?
                <div className="card-wrapper">
                    <span className="card-key">Wants to disrupt...</span>
                    {
                        userDetail.attributes.interests.length>0 ?
                        (
                            userDetail.attributes.interests.map((studentInterest, index) => (
                                <span key={index} className="card-value">{studentInterest.name}</span>
                            ))
                        ) : null
                    }
                    {
                        userDetail.attributes['interest-desc'] ?
                            <span className="italic-text">
                                {`"${userDetail.attributes['interest-desc']}"`}
                            </span> : ''
                    }
                </div> : ''
            } */}
            {
                (userType==='alumni' || userType==='advisor') && userDetail.attributes['alumni-detail'] ?

                    <Fragment>
                        {
                            <div className="card-wrapper">
                                <span className="card-key">My concept...</span>
                                <span className="card-value">{userDetail.attributes['alumni-detail'].concept}</span>
                            </div>
                        }
                        {
                            <div className="card-wrapper">
                                <span className="card-key">Status of venture...</span>
                                <span className="card-value">{
                                    `${userDetail?.attributes['alumni-detail']?.state?.split('_').join(' ').charAt(0).toUpperCase()}${userDetail.attributes['alumni-detail'].state.split('_').join(' ').substring(1)}`
                                }</span>
                            </div>
                        }
                        {
                            <div className="card-wrapper">
                                <span className="card-key">What I need today...</span>
                                {
                                    userDetail.attributes['alumni-detail']['need-today'].length > 0 ?
                                        (
                                            userDetail.attributes['alumni-detail']['need-today'].map((alumniNeedToday, index) => (
                                                <span className="card-value" key={index}>
                                        { alumniNeedToday.match(/feasibility/,"i") && alumniNeedToday.match(/\|/, "i") ?
                                            (`Feasibility Study ($${alumniNeedToday.split('|')[1]})`)
                                            :
                                            (
                                                `${alumniNeedToday.split('_').join(' ').charAt(0).toUpperCase()}${alumniNeedToday.split('_').join(' ').substring(1)}`
                                            )}</span>
                                            ))
                                        ) : null
                                }
                            </div>
                        }
                    </Fragment>
                : ''
            }
            {
                userDetail.attributes.interests ? 
                <div className="card-wrapper">
                    <span className="card-key">Wants to disrupt...</span>
                    {
                        userDetail.attributes.interests.length>0 ?
                        (
                            userDetail.attributes.interests.map((studentInterest, index) => (
                                <span key={index} className="card-value">{studentInterest.name}</span>
                            ))
                        ) : null
                    }
                    {
                        (userType==='student' || userType==='advisor') && userDetail.attributes['interest-desc'] ? 
                        <span className="italic-text">
                                {`"${userDetail.attributes['interest-desc']}"`}
                            </span> : ''
                    }
                </div> : null
            }
            
            </div>
            </div>

            {
                <div className="card-wrapper card-wrapper-email">
                    <span className="card-key">Email: </span>
                    {
                        userDetail.attributes.email
                    }
                </div>
            }
        </>
    )
};

class ExistingUserCards extends Component {

    state = {
        redirectTo: null,
        userId: null,
        userEmail: null,
        userName: null,
        userType: null,
    }

    handleViewConversation = async(userDetail) => {
        const userId = userDetail.id
        const userEmail = userDetail.attributes.email;
        const userName = `${userDetail.attributes['first-name']} ${userDetail.attributes['last-name']}`
        const userType = userDetail.attributes['user-type'];
        this.setState({
            redirectTo: '/admin/userMessages',
            userId,
            userEmail,
            userName,
            userType,
        })
    }

    showSpinner = () => {
        return !this.props.showLoader ? <Spinner /> : <NoUsers for={this.props.existingUserType} />
    }

    render() {
        const { getExistingUserList, existingUserList, existingUserType, noUserFor, filteredExistingUserList, setColOrUniv, setExistingUserType, currentPage, totalRecords, recordsPerPage } = this.props;
        const { redirectTo, userId, userEmail, userName, userType } = this.state;
        let existingUserListArr = filteredExistingUserList.length>0 ?
                                filteredExistingUserList.map((userDetail, index) => (
                                    <div key={index} className="filter-user-container selected-filter-user">
                                        <div className="filter-user-basic-deatils filter-user-basic-deatils-admin">
                                            <UserCard
                                                userType={userDetail.attributes['user-type']}
                                                userDetail={userDetail}
                                            />
                                        </div>
                                        <Button
                                            className="message-btn"
                                            onClick={()=> {this.handleViewConversation(userDetail)}}
                                        >View Messages</Button>
                                    </div>
                                )) : []
        return (
            <React.Fragment>
                <div className="filter-user-wrapper text-center align-middle filter-user-wrapper-admin">
                    <div className="pagination-container home-page-wrapper">
					    {
                            existingUserListArr.length > 0 &&
                            <>
                                <span className='custom-pagination'>
                                    Showing {(currentPage-1)*recordsPerPage+1} - {(currentPage*recordsPerPage<totalRecords) ? (currentPage*recordsPerPage) : totalRecords} of {totalRecords}
                                </span>
                                <Button className="filter-btn" onClick={this.toggle}>Filters</Button>
                            </>

					    }
                    </div>
                    <StudentAlumniTabs
                        getExistingUserList={getExistingUserList}
                        existingUserType={existingUserType}
                        setColOrUniv={setColOrUniv}
                        setExistingUserType={setExistingUserType}
                        setCurrentPage={this.props.setCurrentPage}
                    />                    
                    {
                        existingUserListArr.length===0 ?
                            this.showSpinner() :
                            <div className={cx("filter-user-section", {'filter-user-section-only-two' : existingUserListArr.length <= 2})}>{existingUserListArr}</div>
                    }
                </div>
                {redirectTo ? <Redirect push to={{
                                            pathname: redirectTo,
                                            state: {userId, userEmail, userName, userType}
                            }} /> : null}
            </React.Fragment>
        );
    }
}

export default withRouter(ExistingUserCards);