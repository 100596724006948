import React, { Component } from "react";
import { Button } from 'reactstrap';
import DesktopHeader from '../../containers/homePage/DesktopHeader';
import { Link } from 'react-router-dom';
import ApproveModal from '../../containers/admin/ApproveModal'
import profilePic from "../../commons/images/profile_pic.svg"
import { needTodayList } from '../../constants/needTodayList';

class AdminMoreUserDetail extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            redirectTo: null,
            modal: false,
            approvalModal: false,
            messageBlock: false,
            userType: '',
            pendingUserId: '',
            pendingUserName: '',
            approveRejectLabel: '',
            isReject: '',
            pendingUserType: ''
        }
        this.toggle = this.toggle.bind(this);
        this.approveModalToggle = this.approveModalToggle.bind(this);
    }

    componentDidMount(){
        this.setState({
            pendingUserId: this.props.match.params.pendingUserId
        })
        this.props.getPendingUserData(this.props.match.params.pendingUserId);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        })
    }

    approveModalToggle(pendingUserId, pendingUserName, approveRejectLabel, pendingUserType, isReject){
        this.setState({
            pendingUserId,
            pendingUserName,
            approveRejectLabel,
            isReject,
            approvalModal: !this.state.approvalModal,
            pendingUserType,
        })
    }
    
    render() {
        const { attributes } = this.props.pendingUserData;
        const { pendingUserId, pendingUserName, approveRejectLabel, isReject, pendingUserType }  = this.state;
        
        let userType, profilePicUrl, name, lastName, email, linkedinProfileUrl, mbaProgram, colOrUniv, graduationYear, currentWorkLocation, concept, venture, needs, sectors, additionalDetails;
        if(attributes){
            userType = attributes['user-type'];
            profilePicUrl = attributes['image-url'];
            name = attributes['first-name'];
            lastName = attributes['last-name'];
            email = attributes['email'];
            linkedinProfileUrl = attributes['profile-url'];

            mbaProgram = attributes['mba-program'];
            if(attributes['university']){
                colOrUniv = attributes['university'].name;
            }
            graduationYear = attributes['grad-year'];
            if(attributes['location']){
                currentWorkLocation = `${attributes['location'].name}, ${attributes['location'].code}`;
            }
            if(attributes['alumni-detail']){
                concept = attributes['alumni-detail'].concept;
                venture = attributes['alumni-detail'].state;
                needs = attributes['alumni-detail']['need-today'];
            }
            sectors = attributes['interests'];
            additionalDetails = attributes['interest-desc'];
        }

        let ventureUpperCase, needsToShow, feasibilityStudyAmount, investorType;
        if(userType==='alumni'){
            ventureUpperCase = venture.split('_').join(' ');
            ventureUpperCase = `${ventureUpperCase.charAt(0).toUpperCase()}${ventureUpperCase.substring(1)}`

            needsToShow = needs[0] ? needs.map(need => {
                const result = need.includes('|') ? null : need;
                return needTodayList.find(item => item && item.attributes && item.attributes.id === result);
            }) : [''];

            if (needs[0]) {
                const feasibilityStudyValue = needs.find(item => item.includes('|') && item.split('|')[0] === 'feasibility_study');
                if (feasibilityStudyValue) {
                    feasibilityStudyAmount = feasibilityStudyValue.split('|')[1];
                }

                const investorTypeValue = needs.find(item => item.includes('|') && item.split('|')[0] === 'advisor');
                if (investorTypeValue) {
                    investorType = investorTypeValue.split('|')[1];
                }
            }
        }

        const stringColOrUniv = typeof(colOrUniv)==='string' ? colOrUniv : '';
        const stringCurrentWorkLocation = typeof(currentWorkLocation)==='string' ? currentWorkLocation : '';
        const labelSetcorOrInterest = (userType==='alumni')
                                    ? 'Sectors' 
                                    : (userType==='student' || userType === 'advisor'
                                        ? 'My Interests' 
                                        : ''
                                    );

        let universityName = stringColOrUniv;
        universityName = universityName.split(' ').filter(word => {
            return !word.includes('University') && !word.includes('of')
        })
        universityName = universityName.join(' ');
        universityName = `${universityName.charAt(0).toUpperCase()}${universityName.substring(1)}`;
        
        return (
            <React.Fragment>
                <DesktopHeader/>
                <div className="screen-height">
                    <div className="edit-details-container">
                        <span>
                            <Link 
                                to={
                                    userType==='alumni' ? '/admin/pendingAlumni' 
                                    : userType==='student' ? '/admin/pendingStudents' : ''
                                }
                                className="home-button"
                            >Home 
                            </Link> > Profile
                        </span>
                        <div>
                            <Button 
                                className="edit-btn"
                                onClick={() => {this.approveModalToggle(pendingUserId, `${name} ${lastName}`, 'You are Approving', userType)}}
                            >Approve</Button>
                            <Button 
                                className="edit-btn"
                                onClick={() => {this.approveModalToggle(pendingUserId, `${name} ${lastName}`, 'You are Rejecting', userType, true)}}
                            >Reject</Button>
                        </div>
                    </div>
                    <div className="profile-wrapper custom-profile-container">
                        <div className="profile-basic-info">
                            <div className="profile-picture">
                                <img 
                                    src={profilePicUrl ? profilePicUrl : profilePic}
                                    alt="no_image"
									onError={(e)=>{e.target.onerror = null; e.target.src=profilePic}}
                                />
                            </div>
                            <span className="profile-name">{name} {lastName}</span>
                            <div className="profile-address">
                                <span>{universityName} {mbaProgram} {graduationYear}</span>
                                <span>{stringCurrentWorkLocation}</span>
                            </div>
                            <span className="profile-email">{email}</span>
                            {
                                linkedinProfileUrl ? 
                                <div className="user-contact-details">
                                    <a 
                                        href={linkedinProfileUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="linked-link">LinkedIn Profile
                                    </a>
                                </div> : ''
                            }
                        </div>
                        {
                            userType==='alumni' && 
                            <div className="venture-details">
                                <span className="venture-title">My Venture</span>
                                <div className="venture-desc">
                                    <span>My Concept</span>
                                    <p>{concept}</p>
                                </div>
                                <div className="venture-desc">
                                    <span>This Venture is Currently</span>
                                    <p>{ventureUpperCase}</p>
                                </div>
                                <div className="venture-desc">
                                    <span>What I Need Today</span>
                                        {
										needsToShow.map((need) => (
                                            <p>
                                                {
                                                    need && need.attributes && need.attributes.name
                                                        ? need.attributes.name
                                                        : ''
                                                }
                                            </p>
                                        ))
										}
										{
											feasibilityStudyAmount &&
											<p>Feasibility Study: (${feasibilityStudyAmount})</p>
										}
										{
											investorType &&
											<p>Investor Type: {investorType}</p>
										}
                                </div>
                            </div>
                        }
                        <div className={`venture-details ${(userType==='alumni' || userType==='advisor') ? 'sector-details' : ''}`}>
                            <span className="venture-title">{labelSetcorOrInterest}</span>
                            <div className="venture-desc sector-desc">
                                {
                                    sectors && 
                                    sectors.map((sector, i) => (
                                        <span key={i}>{sector.name}</span>
                                    ))
                                }
                            </div>
                        </div>
                        {
                            (userType==='student') ?
                            <div className="venture-details sector-details">
                                <span className="venture-title">Additional Details</span>
                                <div className="venture-desc sector-desc">
                                    <span>{additionalDetails}</span>
                                </div>
                            </div> : ''
                        }
                    </div>
                </div>
                <ApproveModal 
                    isReject={isReject}
                    pendingUserId={pendingUserId}
                    pendingUserName={pendingUserName}
                    approveRejectLabel={approveRejectLabel}
                    isOpen={this.state.approvalModal} 
                    toggle={this.approveModalToggle}
                    approveModalToggle={this.approveModalToggle}
                    history={this.props.history}
                    pendingUserType={pendingUserType}
                />
            </React.Fragment>
           
        );
    }
}

export default AdminMoreUserDetail;