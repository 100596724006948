import axiosInstance from '../indexWithoutApiInBaseUrl';

export const loginUser = (data) => {
    window.firstLoad = true
    return axiosInstance()({
        method: 'POST',
        url: `/users/login`,
        data
    })
}

export const loginUserInfo = (id) => {
    return axiosInstance()({
        method: 'GET',
        url: `/users/login_info`,
        params: {id},
    })
}
