import React, { Component } from 'react';
import logo from '../../commons/images/logo.svg';
import HeaderBeforeLogin from '../headerBeforeLogin/headerBeforeLogin';
import { landingPageUrl } from '../../constants/config';
import { Button, Form } from 'reactstrap';
import { Link } from 'react-router-dom';
import BackIcon from '../../commons/images/back-icon.svg';
import FormInput from '../commonComponents/FormGroupInputBox';

export class ForgotPassword extends Component {
    constructor(props) {
		super(props);
		this.state = {
			modal: false,
			authURL: landingPageUrl,
            userEmail: '',
			resetLinkSent: false,
			isValidEmail: true,
		};
	}
	
	handleUserEmail = (event) => {
		this.props.setUserEmail(event.target.value);
		this.emailValidate(event.target.value);
	};

	emailValidate = (email) => {
        if (/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g.test(email)) {
            this.setState({ isValidEmail: true })
        } else {
            this.setState({ isValidEmail: false })
        }
    }

	handleUserPassword = (event) => {
		this.props.setUserPassword(event.target.value);
	};

	handleForgotPassword = async (event) => {
		const { userEmail } = this.props;
		if (userEmail) {
			let response = await this.props.emailResetLink(userEmail);
            this.setState({
                resetLinkSent: response,
                userEmail: userEmail
            });
		} else {
			alert('Please enter Email');
		}
	};

	render() {
		const { authURL } = this.state;
		let { isTokenValid } = this.props;
		return (
			<React.Fragment>
				<div className="login-wrapper d-flex flex-column flex-md-row">
					<div className="login-banner-container" />
					<div className="login-right-container d-flex">
						<div className="login-inner-container ">
							{
								!this.state.resetLinkSent && (
									<div className="back-to-login">
										<Link to="/login">
											<span>
												<img src={BackIcon} />
											</span>
											Back
										</Link>
									</div>
								)
							}
							<HeaderBeforeLogin styles={{height: "auto", paddingTop: "25px"}}/>
							<img src={logo} alt="Gradshack" />
							<h4 className="title">
                Alumni connections for venture formation
              </h4>
							<h4 className="title">🔑 {' '}Forgot Password</h4>
							<Form className="form-container login-form">
                            {
                            !this.state.resetLinkSent ? 
                            <>
                                <p className="recover-password-text text-center">To recover your account, please enter your registered e-mail address below. Password reset instructions will be sent 
								via e-mail.</p>
								<FormInput
									type="email"
									isValid = {this.state.isValidEmail}
									placeholder="Enter Your E-mail Address"
									className="form-control"
									onChange={this.handleUserEmail}
									invalidMessage = "Invalid email."
								/>

								<Button 
									onClick={this.handleForgotPassword}
									className="landing-btn w-100"
									disabled={
										(
											!(this.state.isValidEmail && this.props.userEmail)
										)
									}
									style={{ cursor: !(this.state.isValidEmail && this.props.userEmail) ? 'not-allowed' : 'pointer' }}
								>
									Submit
								</Button>
								</> : 
							
                            <>
                            <p className="password-text mt-4">Password reset instructions sent to your e-mail address 
                             <span className="highlight-text"> { this.state.userEmail } </span></p>
                            <p className="password-text mt-4"> 
                              <a 
                                href={'/login'}
                                className="highlight-text"> Click here 
                              </a>
                              &nbsp; to login to your account</p>
                            </>
                            }
                            </Form> 
							{/* <span className="comment-text">
								Have an account,{' '}
								<a href="/homepage">Login</a> here
							</span>
							<span className="comment-text signup">
								To create a new account,{' '}
								<a href="/userSignUp">Signup</a> here
							</span> */}
							<span className="comment-text">
								Comments? Questions? Email us at{' '}
								<a href="mailto:contact@gradshack.com">contact@gradshack.com</a>
							</span>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
