import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import LandingPage from "../../containers/landingPage";
import Usertype from "../../containers/UserType";
import UserFormMain from "../userForms/UserFormMain";
import RedirectAfterLogin from "../../containers/RedirectAfterLogin";
import ThankYou from "../../containers/Thankyou";
import HomePage from "../../containers/homePage/HomePage";
import UserProfile from "../../containers/UserProfile";
import EditProfile from "../../containers/editProfile/EditProfileMain";
import Profile from "../../containers/Profile";
import Match from "../../containers/match/Match";
import About from "../aboutUs/About";
import FAQPage from "../faqPage/FAQPage";
import NoUsers from "../noUsers/NoUsers";
import Messages from "../../containers/messages/Messages";
import MoreMenu from "../homePage/moreMenu/MoreMenu";
import Favourites from "../../containers/Favourites";
import AdminLogin from "../../containers/admin/AdminLogin";
import AdminHomePage from "../../containers/admin/AdminHomePage";
import AdminMoreUserDetail from "../../containers/admin/AdminMoreUserDetail";
import AdminExistingUsers from "../../containers/admin/AdminExistingUsers";
import AdminExistingUserMessages from "../../containers/admin/AdminExistingUserMessages";
import chatClient from "../../services/twilioConversation";
import { connect } from "react-redux";
import { getTwilioTokenApi } from "../../api/twilioChat";
import { TWILIOCHAT } from "../../constants/namespacer";
import { createNamespacer } from "../../utils/reducers";
import AdminNotifications from "../../containers/admin/AdminNotifications";
import { UserSignUp } from "../../containers/userForms/UserSignUp";
import { ResetPassword } from "../../containers/userForms/ResetPassword";
import { ForgotPassword } from "../../containers/userForms/ForgotPasswordForm";
import store from "../../store";
import { LOGIN } from "../../constants/namespacer";
import NewsFeed from "../../containers/NewsFeed";
import AddCommentModal from "../addCommentModal/AddCommentModal";
import PageNotFound from "../pageNotFound/pageNotFound";
import LoginOptions from "../LoginOptions/loginOptions";
import LinkedInSignupForm from "../linkedin-signup-form";
import TermsConditionsPage from "../staticPages/termsConditionsPage";
// import twilioConversationClient from "../../services/twilioConversation";

const componentWithoutApi = ["/thankYou"];

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
      authCode: "",
    };
    this.toggleMount = this.toggleMount.bind(this);
  }

  toggleMount() {
    this.setState({ isMounted: true });
  }

  componentDidMount() {
    this.toggleMount();
    const currentPath = this.props.location.pathname;
    if (currentPath && currentPath.includes(componentWithoutApi)) {
      this.props.getUserData();
    }
  }

  // componentDidUpdate(prevProps) {
  //     const currentPath = this.props.location.pathname
  //     if (currentPath !== prevProps.location.pathname
  //         && currentPath.includes(componentWithoutApi)) {
  //         this.props.getUserData()
  //     }
  // }

  render() {
    const { isTokenValid, isAdmin } = this.props;
    const { authCode } = this.state;
    let linkedinFlag = localStorage.getItem("loggedinWithLinkedin");
    return this.state.isMounted ? (
      <Switch>
        {
          // linkedinFlag
          !isTokenValid ? (
            commonRoutes(this.props)
          ) : isAdmin ? (
            adminRoutes(this.props)
          ) : (
            <PrivateRoutes />
          )
        }
      </Switch>
    ) : null;
  }
}

function commonRoutes(props) {
  return (
    <Switch>
      <Route exact path="/" component={LoginOptions} />
      <Route exact path="/login" component={LandingPage} />
      <Route exact path="/admin/login" component={AdminLogin} />
      <Route path="/linkedin/auth/" component={RedirectAfterLogin} />
      <Route path="/userSignUp" component={UserSignUp} />
      <Route path="/reset/password" component={ResetPassword} />
      <Route path="/forgotPassword" component={ForgotPassword} />
      <Route path="/termsAndConditions" component={TermsConditionsPage} />
      <Route
        render={(props) => {
          return <Redirect push to="/linkedin/auth/" />;
        }}
      />
    </Switch>
  );
}

class PrivateRoutesComponent extends React.Component {
  state = {
    tcClient: null,
  };

  refreshToken = async ()=>{
    console.log("Executing refresh token")
    const token = await this.props.getToken();
    return token
  }

  componentDidMount = async () => {
    const token = await this.refreshToken()
    const tcClient = await chatClient.initialize(token);
    
    // console.log("In Routes componentDidMount", tcClient?.client?.user?.state?.identity)

    this.setState({ tcClient });
    window.refreshToken = this.refreshToken
  };

  render() {
    return (
      <>
        <Switch>
          <Route path="/userType" component={Usertype} />
          <Route path="/userInfo" component={UserFormMain} />
          <Route path="/forgotPassword" component={ForgotPassword} />
          <Route path="/reset/password" component={ResetPassword} />
          <Route
            path="/userSignUp"
            render={() => <Redirect push to="/userInfo" />}
          />
          <Route path="/linkedin/auth" component={RedirectAfterLogin} />
          <Route path="/thankYou" component={ThankYou} />
          {localStorage.getItem("isAdmin") && (
            <Route
              path="/homepage"
              render={(routeProps) => (
                <HomePage {...routeProps} tcClient={this.state.tcClient} />
              )}
            />
          )}
          <Route path="/profile/:userId" component={Profile} />
          <Route path="/userProfile" component={UserProfile} />
          <Route path="/editProfile" component={EditProfile} />
          <Route path="/userMessage" component={Messages} />
          <Route path="/noUsers" component={NoUsers} />
          <Route path="/about" component={About} />
          <Route path="/faq" component={FAQPage} />
          <Route path="/moreMenu" component={MoreMenu} />
          <Route path="/favorites" component={Favourites} />
          <Route path="/match" component={Match} />
          <Route
            path="/newsFeed"
            exact
            render={(routeProps) => (
              <NewsFeed {...routeProps} tcClient={this.state.tcClient} />
            )}
          />
          <Route
            path="/newsFeed/:activityId/:userId"
            component={AddCommentModal}
          />
          <Route
            render={(props) => {
              return <Redirect push to="/linkedin/auth" />;
            }}
          />
          <Route exact path="/login" component={LandingPage} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </>
    );
  }
}

const twilioChatNamespacer = createNamespacer(TWILIOCHAT);

const PrivateRoutes = connect(null, (dispatch) => {
  return {
    getToken: async (params) => {
      try {
        let response = await getTwilioTokenApi();
        dispatch({
          type: twilioChatNamespacer("SET_MSG_FROM_IDENTITY"),
          msgFromIdentity: localStorage.getItem("id"),
        });
        dispatch({
          type: twilioChatNamespacer("SET_TWILIO_TOKEN"),
          twilioToken: response.data,
        });
        dispatch({
          type: twilioChatNamespacer("SET_MSG_FROM_AUTHOR"),
          msgFromAuthor: response.data.identity,
        });
        return response.data.token;
      } catch (error) {
        if (error.response.status === 500) {
          const loginNamespacer = createNamespacer(LOGIN);
          localStorage.clear();
          store.dispatch({
            type: loginNamespacer("SET_IS_AUTHENTICATED"),
            isTokenValid: false,
          });
        }
      }
    },
  };
})(PrivateRoutesComponent);

function adminRoutes(props) {
  return (
    <Switch>
      <Route path="/admin/pendingAlumni" component={AdminHomePage} />
      <Route path="/admin/pendingStudents" component={AdminHomePage} />
      <Route path="/admin/pendingAdvisors" component={AdminHomePage} />
      <Route path="/admin/userMessages" component={AdminExistingUserMessages} />
      <Route path="/admin/existingUsers" component={AdminExistingUsers} />
      <Route
        path="/admin/userdetail/:pendingUserId"
        component={AdminMoreUserDetail}
      />
      <Route path="/admin/notifications" component={AdminNotifications} />
      <Route
        render={(props) => {
          return <Redirect push to="/admin/pendingAlumni" />;
        }}
      />
    </Switch>
  );
}

export default withRouter(
  connect((state) => {
    return {
      isTokenValid: state.redirectAfterLogin.isTokenValid,
      isAdmin: state.adminLogin.isAdmin,
    };
  }, null)(Routes)
);
