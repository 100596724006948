import { connect } from 'react-redux';
import { postSetFavorite, putSetUnfavorite } from '../api/favorites';
import FavouriteModal from '../components/favoriteModal/FavouriteModal';
import { createNamespacer } from '../utils/reducers';
import { HOMEPAGE } from '../constants/namespacer';
import store from '../store'

const favUserNamespacer = createNamespacer(HOMEPAGE)

const mapStateToProps = (state) => {
    return {
        isTokenValid: state.redirectAfterLogin.isTokenValid,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setFavorite: async (favoriteUserId, favouriteNote) => {
            try {
                let bodyFormData = new FormData();
                bodyFormData.append('favourite[favourite_id]', favoriteUserId );
                bodyFormData.append('favourite[notes]', favouriteNote);
                bodyFormData.append('favourite[favourite?]',true);
                
                let response = await postSetFavorite(bodyFormData);
                if(response.status===200){
                    dispatch({
                        type: favUserNamespacer('SET_FAV_REFRESH_REQ'),
                        favRefreshRequired: true
                    })
                }
            }
            catch (error) {
                console.log(error);
            }
        },
        setUnfavorite: async(favoriteId) => {
            try{
                let bodyFormData = new FormData();
                bodyFormData.append('favourite[favourite?]', false);

                let response = await putSetUnfavorite(bodyFormData, favoriteId);
                if(response.status===200){
                    dispatch({
                        type: favUserNamespacer('SET_FAV_REFRESH_REQ'),
                        favRefreshRequired: true
                    })
                    const favoriteUsersListLength = store.getState().favourites.favoriteUsersListLength;
                    dispatch({
                        type: favUserNamespacer('SET_FAVORITE_USER_LEN'),
                        favoriteUsersListLength: favoriteUsersListLength-1,
                    })
                }
            }
            catch(error){
                console.log(error);
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FavouriteModal);

