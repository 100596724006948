import {connect} from 'react-redux'
import {createNamespacer} from '../../utils/reducers'
import {SIGNUP, VALIDATIONERROR, LOGIN} from '../../constants/namespacer'
import {resetPassword} from '../../api/resetPassword'
import { withRouter } from 'react-router';
import {ResetPassword as ResetPasswordComponent} from '../../components/resetPassword/ResetPassword'

const signUp = createNamespacer(SIGNUP)

const mapStateToProps = (state) => {
  return {
    userPassword: state.signUp.userPassword,
    confirmPassword: state.signUp.confirmPassword,
    reset_password_token: state.reset_password_token,
    redirectUrl: state.signUp.redirectUrl,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUserPassword: (value) => {
      dispatch({
        type: signUp('SET_USER_PASSWORD'),
        userPassword: value,
      })
    },
    setUserConfirmPassword: (value) => {
      dispatch({
        type: signUp('SET_USER_CONFIRM_PASSWORD'),
        confirmPassword: value,
      })
    },
    resetPassword: async (userPassword, reset_password_token) => {
      try {
        let formData = {}
        formData["user"] = { 
            "password": userPassword,
            "reset_password_token": reset_password_token
        }

        let response = await resetPassword(formData)
        if (response && response.status === 200) {
            console.log("successful password chage");
            return true;
        }

      }catch(error) {
        console.log(error)
        return false;
      }
    },
  }
}

export const ResetPassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ResetPasswordComponent))
