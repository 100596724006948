// export const investorAdvisorList = [
//   {
//     attributes: { id: "angel", name: "Angel" },
//   },
//   {
//     attributes: { id: "pre_seed", name: "Pre-Seed" },
//   },
//   {
//     attributes: { id: "seed", name: "Seed" },
//   },
//   {
//     attributes: { id: "post_seed", name: "Post-Seed" },
//   },
// ];

export const investorAdvisorList = [];
