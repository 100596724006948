import React, { Component } from 'react';
import {
    Form,
    Button,
} from 'reactstrap'
import logo from '../../commons/images/logo.svg'
import FormInput from '../commonComponents/FormGroupInputBox';


export class ResetPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isValidPassword: true,
            isConfirm: true,
            passwordReset: false,
            reset_password_token: this.props.match.params.reset_password_token || null
        }
    }

    componentDidMount() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let token = params.get('reset_password_token');
        this.setState({
            reset_password_token: token
        })
        // this.props.getPendingUserData(this.props.match.params.pendingUserId);
    }

    handlerUserPassword = (event) => {
        this.props.setUserPassword(event.target.value)
        if (!this.passwordValidate(event.target.value)) {
            this.setState({ isValidPassword: false })
        } else {
            this.setState({ isValidPassword: true })
        }
        if (this.props.confirmPassword !== "") {
            if (event.target.value !== this.props.confirmPassword) {
                this.setState({ isConfirm: false })
            } else {
                this.setState({ isConfirm: true })
            }
        }
    }


    handleResetPassword = async () => {
        const { userPassword } = this.props
        const {
            isConfirm,
        } = this.state;
        if (isConfirm) {
            console.log(this.state.reset_password_token)
            let response = await this.props.resetPassword(userPassword, this.state.reset_password_token);
            this.setState({
                passwordReset: response
            });
        } else {
            alert('Enter Email and Password')
        }
    };

    passwordValidate(p) {
        return /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])[\w!@#$%^&*]{8,}$/.test(p)
    }

    handleConfirmPassword = (event) => {
        this.props.setUserConfirmPassword(event.target.value)
        if (event.target.value !== this.props.userPassword) {
            this.setState({ isConfirm: false })
        } else {
            this.setState({ isConfirm: true })
        }
    }



    render() {
        return (
            <React.Fragment>
                <div className="signup-wrapper d-flex flex-column flex-md-row">
                    <div className="signup-container d-flex">
                        <div className="signup-inner-container">
                            {
                                !this.state.passwordReset ?
                                    <>
                                        <img src={logo} alt="Gradshack" />

                                        <h4 className="title">
                                            🔑 Reset password
                                        </h4>
                                        <Form className="form-container signup-form">
                                        <p className="text-left">Please enter your new password below.</p>
                                            <p style={{fontSize: 14}} className="recover-password-text mt-4">Enter New Password* (At-least 8 characters)</p>
                                            <FormInput
                                                type="password"
                                                placeholder="Enter New Password"
                                                isValid={this.state.isValidPassword}
                                                onChange={this.handlerUserPassword}
                                                invalidMessage=" Password must be at least eight characters long and must contain at least one number, one upper case letter and one lower case letter."
                                            />

                                            <p style={{fontSize: 14}} className="recover-password-text mt-4">Confirm Password*</p>
                                            <FormInput
                                                type="password"
                                                placeholder="Confirm Password"
                                                isValid={this.state.isConfirm}
                                                onChange={this.handleConfirmPassword}
                                                invalidMessage="Passwords do not match."
                                            />

                                            <Button
                                                className="landing-btn w-100"
                                                onClick={this.handleResetPassword}
                                                disabled={
                                                    (
                                                        !(this.state.isValidPassword && this.props.userPassword) ||
                                                        !(this.state.isConfirm && this.props.confirmPassword)
                                                    )
                                                }
                                                style={{
                                                    cursor: !(this.state.isValidPassword && this.props.userPassword) ||
                                                    !(this.state.isConfirm && this.props.confirmPassword) ? 'not-allowed' : 'pointer'
                                                }}
                                            >
                                                Reset Password
                                            </Button>
                                        </Form>
                                        <span className="comment-text">
                                            Already have an account?
                                            <a href="/login"> Log In</a>
                                        </span>
                                    </> :
                                    <>
                                        <h4 className="title">
                                            Password Updated!
                                        </h4>
                                        <Form className="form-container signup-form">
                                            <p className="recover-password-text">Your password has been reset successfully. Click below to Login.</p>

                                            <Button
                                                className="landing-btn w-100"
                                                onClick={() => {
                                                    this.props.history.push('/');
                                                }}
                                            >
                                                Login to my Account
                                            </Button>
                                        </Form>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
