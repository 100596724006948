import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import MobileMenu from '../../containers/homePage/MobileMenu';
import DesktopHeader from '../../containers/homePage/DesktopHeader';
import EditProfiletabs from './editProfiletabs/EditProfiletabs';
import BackIcon from '../../commons/images/back-icon.svg';
import profilePic from "../../commons/images/profile_pic.svg"
import ToastErrorMsg from '../toastErrorMsg/ToastErrorMsg';
import camera from "../../commons/images/camera.svg";
import UserFormModal from '../userForms/userFormButtons/UserFormModal';
import BlockingLoader from "../commonComponents/Loader";

class EditProfileMain extends Component {

    // state = {
    //     redirectTo: null,
    //     isDisabled: true,
    // }

    constructor(props){
        super(props);
        this.state= {
            redirectTo: null,
            isDisabled: true,
            userFormModal: false,

        };
        this.userFormModalToggle = this.userFormModalToggle.bind(this);
    }
    userFormModalToggle() {
        this.setState({
			userFormModal: !this.state.userFormModal
		});
    }

    componentDidMount() {
        this.props.getUserData();
    }

    static getDerivedStateFromProps(props, state) {
        const { userType } = props;
        const { name, email, mbaProgram, colOrUniv, graduationYear, currentWorkLocation, lastName, sectors } = props;

        if (userType === 'alumni' || userType === 'student' || userType === 'advisor') {
            if (name && lastName && email && colOrUniv && mbaProgram && graduationYear && currentWorkLocation && sectors.length > 0 && sectors.length < 4) {
                props.setFieldsMissing(undefined);
            }
            if (!name) {
                props.setFieldsMissing('Name');
            }
            else if (!lastName) {
                props.setFieldsMissing('Last Name');
            }
            else if (!email) {
                props.setFieldsMissing('Email');
            }
            else if (!colOrUniv) {
                props.setFieldsMissing('Program Name');
            }
            else if (!mbaProgram) {
                props.setFieldsMissing('Program Type');
            }
            else if (!graduationYear) {
                props.setFieldsMissing('Graduation Year');
            }
            else if (!currentWorkLocation) {
                props.setFieldsMissing('Location');
            }
            else if (sectors.length === 0) {
                props.setFieldsMissing('Sector(s) - Three sectors maximum');
            }
        }
        else if (userType === 'alumni') {
            const { concept, venture, needs } = props;
            if (concept && venture && needs) {
                props.setFieldsMissing(undefined);
            }
            else if (!concept) {
                props.setFieldsMissing('My Concept');
            }
            else if (!venture) {
                props.setFieldsMissing('This venture is currently');
            }
            else if (!needs) {
                props.setFieldsMissing('What I need today');
            }
        }

        if (userType === 'alumni') {
            const { concept, venture, needs } = props;
            return {
                isDisabled: !(
                    name &&
                    lastName &&
                    email &&
                    colOrUniv &&
                    mbaProgram &&
                    graduationYear &&
                    currentWorkLocation &&
                    concept && venture && needs &&
                    sectors.length > 0 && sectors.length < 4
                )
            }
        }
        else if (userType === 'student') {
            return {
                isDisabled: !(
                    name &&
                    lastName &&
                    email &&
                    colOrUniv &&
                    mbaProgram &&
                    graduationYear &&
                    currentWorkLocation &&
                    sectors.length > 0 && sectors.length < 4
                )
            }
        }
        else if (userType === 'advisor') {
            return {
                isDisabled: !(
                    name &&
                    lastName &&
                    email &&
                    colOrUniv &&
                    mbaProgram &&
                    graduationYear &&
                    currentWorkLocation &&
                    sectors.length > 0 && sectors.length < 4
                )
            }
        }
    }

    handleSaveEditProfile = async (params) => {
        console.log('params', params)
       await this.props.saveEditProfileData(params);
        if(!this.props.loading){
            this.setState({ redirectTo: '/userProfile' })
        }
    }


    render() {
        const { profilePicUrl, name, lastName, userType, fieldsMissing, profilePicBase64 } = this.props;
        const { redirectTo, isDisabled } = this.state;
        return (
            <React.Fragment>
                {this.props.loading && <BlockingLoader/>}
                <DesktopHeader />
                <ToastErrorMsg fieldsMissing={fieldsMissing} />
                <div className="edit-userInfo-wrapper screen-height">
                    <div className="edit-details-container edit-profile-subheader">
                        <Link to="/userProfile" className="btn-backicon">
                            <img src={BackIcon} alt="Back" />
                        </Link>
                        <span>Edit Profile</span>
                        <Button
                            disabled={isDisabled}
                            className="edit-btn edit-save ml-auto"
                            onClick={() => this.handleSaveEditProfile(this.props)}
                        >Save</Button>
                    </div>
                    <div className="edit-profile-wrapper custom-profile-container">
                        <div className="profile-basic-info">
                            <div className="profile-picture">
                                <img
                                    src={profilePicBase64 ? profilePicBase64 : profilePic} 
                                    alt="no_image"
									onError={(e)=>{e.target.onerror = null; e.target.src=profilePic}}
                                />
                                <img src={camera} alt="camera" className="camera" onClick={this.userFormModalToggle} />
                            </div>
                            <UserFormModal
                                isOpen={this.state.userFormModal}
                                toggle={this.userFormModalToggle}
                                profilePic={profilePicUrl}
                                handleProfilePicChange={this.props.setProfilePicBase64}
                                setProfilePic={this.props.setProfilePic}
                            />
                            <span className="profile-name">{name} {lastName}</span>
                            <EditProfiletabs
                                userType={userType}
                            />
                        </div>
                    </div>
                    <div className="edit-details-container">
                        <Button
                            disabled={isDisabled}
                            className="edit-btn edit-save ml-auto"
                            onClick={() => this.handleSaveEditProfile(this.props)}
                        >Save</Button>
                    </div>
                </div>
                <MobileMenu />
                {redirectTo ? <Redirect to={redirectTo} /> : null}
            </React.Fragment>

        );
    }
}

export default EditProfileMain;