import { createReducer, createNamespacer } from '../utils/reducers';
import { HOMEPAGE } from '../constants/namespacer';

const initialState = {
    timelineFeedFilteredList: '',
    timelineFeedFilteredListLength: 0,
    timelineFeedFilteredListCurrentPage: 1,
    timelineFeedFilteredListTotalPages: 0,

    favRefreshRequired: false,
    selectAll: true,
};

const namespacer = createNamespacer(HOMEPAGE);

const handlers = {
    [namespacer('SET_FAV_REFRESH_REQ')]: (state, action) => {
        return{
            ...state,
            favRefreshRequired: action.favRefreshRequired,
        }
    },
    [namespacer('SET_ALUMNI_FEED_FILTERED_LIST')]: (state, action) => {
        return{
            ...state,
            timelineFeedFilteredList: action.timelineFeedFilteredList,
        }
    },
    [namespacer('SET_TIMELINE_FEED_FILTERED_LEN')]: (state, action) => {
        return{
            ...state,
            timelineFeedFilteredListLength: action.timelineFeedFilteredListLength,
        }
    },
    [namespacer('SET_TIMELINE_FEED_FILTERED_CURRENT_PAGE')]: (state, action) => {
        return {
            ...state,
            timelineFeedFilteredListCurrentPage: action.timelineFeedFilteredListCurrentPage,
        }
    },
    [namespacer('SET_TIMELINE_FEED_FILTERED_TOTAL_PAGES')]: (state, action) => {
        return {
            ...state,
            timelineFeedFilteredListTotalPages: action.timelineFeedFilteredListTotalPages,
        }
    },
};

const filterUsersReducer = createReducer(initialState, handlers, [HOMEPAGE]);

export default filterUsersReducer;