import { createReducer, createNamespacer } from "../../utils/reducers";
import { LOGINDETAILS } from "../../constants/namespacer";

const initialState = {
  email: "",
  password: "",
};

const namespacer = createNamespacer(LOGINDETAILS);

const handlers = {
  [namespacer("SET_EMAIL_VALUE")]: (state, action) => {
    return {
      ...state,
      email: action.email,
    };
  },

  [namespacer("SET_PASSWORD_VALUE")]: (state, action) => {
    return {
      ...state,
      password: action.password,
    };
  },
};

const loginDetailsTabReducer = createReducer(initialState, handlers, [
    LOGINDETAILS,
]);

export default loginDetailsTabReducer;
