import React, { Component } from 'react';
import profilePic from "../../../commons/images/profile_pic.svg"
import get from "lodash/get";

import moment  from 'moment'
 
const getTimeOfMsg = (timestamp) => {
    return moment(timestamp).format('MM/DD/YYYY');
}

class MessageList extends Component {
    handleUseChannel = (channelDetail, viewUserIndex) => {
        this.setState({viewUserIndex})
        this.props.setSendMessageText("")
        this.props.setActiveUserChatIndex(viewUserIndex);
        this.props.setCurrentChannelAndMessages(channelDetail);
        this.props.toggleViewChat();
    }
    
    render() {
        const { subscribedChannels, activeUserChatIndex } = this.props;
        return (
            <ul className="message-list-wrapper">
                {
                    subscribedChannels.length>0 ? 
                    subscribedChannels.map((channelDetail, index) => (
                        <li 
                            key={index} 
                            className={`message-block unseen-notification 
                            ${ activeUserChatIndex===index ? 'view-user' : ''}`}
                        >
                            <div 
                                onClick={() => {this.handleUseChannel(channelDetail, index)}}
                                className="msg-details-section"
                            >
                                <div className="msg-profile">
                                    <img 
                                        src={channelDetail.secondUserDetail ? 
                                            channelDetail.secondUserDetail.attributes['image-url'] ? 
                                            channelDetail.secondUserDetail.attributes['image-url'] : profilePic
                                            : profilePic
                                        }
                                        alt="no_image"
                                    /> 
                                </div>
                                <div className="msg-details">
                                    {
                                        channelDetail.secondUserDetail ? 
                                        <span>{channelDetail.secondUserDetail.attributes['first-name']} {channelDetail.secondUserDetail.attributes['last-name']}</span> : ''
                                    }
                                    <span>{channelDetail.latestMessage}</span>
                                </div>
                            </div>
                            {
                                (
                                    channelDetail.channel &&
                                    channelDetail.channel.lastMessage && 
                                    (channelDetail.channel.lastMessage.index - channelDetail.channel.lastConsumedMessageIndex) > 0
                                ) ?
                                <div className="msg-notification">
                                    <span>
                                        {
                                            channelDetail.channel.lastMessage.index - channelDetail.channel.lastConsumedMessageIndex < 9 ?
                                            channelDetail.channel.lastMessage.index - channelDetail.channel.lastConsumedMessageIndex : '9+' 
                                        }
                                    </span>
                                    {/* <span>{getTimeOfMsg(get(channelDetail, "messages.items", "").toString())}</span> */}
                                    <span>{getTimeOfMsg((channelDetail.messages.items[channelDetail.messages.items.length - 1]).dateUpdated.toString())}</span>
                                </div> 
                                : <span>{getTimeOfMsg((channelDetail.messages.items[channelDetail.messages.items.length - 1]).dateUpdated.toString())}</span>
                            }
                        </li>
                    )) : ''
                }
            </ul>
        );
    }
}

export default MessageList;