import { createReducer, createNamespacer } from '../../utils/reducers';
import { HOMEPAGE, ADMINLOGIN } from '../../constants/namespacer';

const initialState = {
    activeScreen: 'newsFeed',
    newNotification: false,
    adminNotificationList: [],
    pushyNotifications: [],
}

const namespacer = createNamespacer(HOMEPAGE);
const adminLoginNamespacer = createNamespacer(ADMINLOGIN);

const handlers = {
    [namespacer('SET_ACTIVE_SCREEN_VALUE')]: (state, action) => {
        return {
            ...state,
            activeScreen: action.activeScreen,
        }
    },
    [namespacer('SET_NEW_NOTIFICATION_VALUE')]: (state, action) => {
        return {
            ...state,
            newNotification: action.newNotification,
        }
    },
    [adminLoginNamespacer('SET_ADMIN_NOTIFICATION')]: (state, action) => {
        return {
            ...state,
            adminNotificationList: action.adminNotificationList,
        }
    },
    [namespacer('ADD_NOTIFICATION')]: (state, action) => {
        return {
            ...state,
            pushyNotifications: [ ...state.pushyNotifications, { isRead: false, data: action.data } ]
        }
    },
    [namespacer('READ_NOTIFICATION')]: (state, action) => {
        state.pushyNotifications.forEach((notification) => {
            if (notification.data.id === action.notification.data.id) {
                notification.isRead = true;
            }
        });
        return {
            ...state,
            pushyNotifications: state.pushyNotifications
        }
    },
}

const activeScreenReducer = createReducer(initialState, handlers, [HOMEPAGE, ADMINLOGIN]);

export default activeScreenReducer;