export const HeaderRoutes = [
    {
        'toRoute': '/newsFeed',
        'activeScreenVal': 'newsfeed',
        'label': 'News',
    },
    {
        'toRoute': '/homepage',
        'activeScreenVal': 'homepage',
        'label': 'Members',
    },
    {
        'toRoute': '/userMessage',
        'activeScreenVal': 'messages',
        'label': 'Messages',
	},
	{
        'toRoute': '/match',
        'activeScreenVal': 'match',
        'label': 'Call Us',
    }
]