import React, { Component } from 'react';
import {
    Form,
    Button,
} from 'reactstrap'
import { Redirect, Link } from 'react-router-dom'
import logo from '../../commons/images/logo.svg'
import PrivacyModal from '../staticPages/privacyPolicy'
import TermsModal from '../staticPages/terms';
import FormInput from '../commonComponents/FormGroupInputBox';
import Reaptcha from 'reaptcha';
import HeaderBeforeLogin from '../headerBeforeLogin/headerBeforeLogin';
import LinkedInSignupForm from '../linkedin-signup-form';
import BackIcon from '../../commons/images/back-icon.svg';
import { landingPageUrl } from '../../constants/config';

export class UserSignUp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isValidPassword: true,
            isConfirm: true,
            isValidEmail: true,
            isValidAllValue: false,
            privacyModal: false,
            termsModal: false,
            acceptedPrivacy: false,
            acceptedTerms: false,
			recaptchaVerified: false,
        }

        this.privacyModalToggle = this.privacyModalToggle.bind(this)
        this.termsModalToggle = this.termsModalToggle.bind(this)
    }

    privacyModalToggle() {
        this.setState({
            privacyModal: !this.state.privacyModal
        })
    }

    termsModalToggle() {
        this.setState({
            termsModal: !this.state.termsModal
        })
    }

    handlerUserPassword = (event) => {
        this.props.setUserPassword(event.target.value)
        if (!this.passwordValidate(event.target.value)) {
            this.setState({ isValidPassword: false })
        } else {
            this.setState({ isValidPassword: true })
        }
        if(this.props.confirmPassword !== ""){
            if(event.target.value !== this.props.confirmPassword) {
                this.setState({ isConfirm: false })
            }else{
                this.setState({ isConfirm: true })
            }
        }
    }

    handlerUserEmail = (event) => {
        this.props.setUserEmail(event.target.value)
        this.emailValidate(event.target.value)
    }

    emailValidate = (email) => {
        if (/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g.test(email)) {
            this.setState({ isValidEmail: true })
        } else {
            this.setState({ isValidEmail: false })
        }
    }

    handleSignupButtonClick = () => {
        localStorage.setItem('userSignupDetails', JSON.stringify({password: this.props.userPassword, email: this.props.userEmail}))
    }

    handlerUserSignUp = async () => {
        localStorage.setItem('userSignupDetails', JSON.stringify({password: this.props.userPassword, email: this.props.userEmail}))
        const { userEmail, userPassword, confirmPassword, captchaToken } = this.props
        const {
            isValidEmail,
            isConfirm,
        } = this.state;
        
        if (isValidEmail && isConfirm) {
            this.setState({ isValidAllValue: true });
			// this.captcha.reset();
            if (window.hasOwnProperty('gtag')) {
                window.gtag('event', 'sign_up', {
                    method: 'Internal',
                    event_category: 'User',
                    event_label: 'Sign Up',
                    value: 'email: ' + userEmail,
                });
            }
            await this.props.userSignUp(userEmail, userPassword, confirmPassword, captchaToken );
        } else {
            alert('Enter Email and Password')
        }
    };

    passwordValidate(p) {
        return /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])[\w!@#$%^&*]{8,}$/.test(p)
    }

    handleConfirmPassword = (event) => {
        this.props.setUserConfirmPassword(event.target.value)
        if (event.target.value !== this.props.userPassword) {
            this.setState({ isConfirm: false })
        } else {
            this.setState({ isConfirm: true })
        }
    }

    handlerPrivacy = () => {
        this.setState(({ acceptedPrivacy }) => {
            const newacceptedPrivacy = !acceptedPrivacy
            return {
                acceptedPrivacy: newacceptedPrivacy
            }
        })
    }

    handlerTerms = () => {
        this.setState(({ acceptedTerms }) => {
            const newacceptedTerms = !acceptedTerms
            return {
                acceptedTerms: newacceptedTerms
            }
        })
    }

	onRecaptchaVerify= (response)=> {
		this.setState({
            recaptchaVerified: true
        })
        this.props.setUserCaptchaToken(response)
		console.log('verified',response)
	}

    render() {
        return (
            <React.Fragment>
                <div className="signup-wrapper d-flex flex-column flex-md-row">
                        <div className="signup-banner-container" />
                        <div className="signup-container d-flex">
                            <div className="signup-inner-container">
                                <HeaderBeforeLogin styles={{height: "auto"}}/>
							<div className="content-container">
                                <div className="back-to-login">
								<Link to="/">
									<span>
										<img src={BackIcon} />
									</span>
									Back
								</Link>
                                </div>
                                <img src={logo} alt="Gradshack" className='signup-logo'/>
                                <h4 className="title">
                                Alumni connections for venture formation
                                </h4>
                                </div>
                                <Form className="form-container signup-form">
                                    <FormInput 
                                        type = "email"
                                        placeholder = "Enter Email"
                                        isValid = {this.state.isValidEmail}
                                        onChange={this.handlerUserEmail}
                                        invalidMessage = "Invalid email."
                                    />

                                    <FormInput 
                                        type = "password"
                                        placeholder = "Enter Password"
                                        isValid = {this.state.isValidPassword}
                                        onChange = {this.handlerUserPassword}
                                        invalidMessage = "Password must be at least eight characters long and must contain at least one number, one upper case letter and one lower case letter."
                                    />

                                    <FormInput 
                                        type = "password"
                                        placeholder = "Confirm Password"
                                        isValid = {this.state.isConfirm}
                                        onChange = {this.handleConfirmPassword}
                                        invalidMessage = "Passwords do not match."
                                    />
                                    <div className="recaptcha">
                                        <Reaptcha sitekey="6LdVFW8aAAAAAPkKfm0TRKRTD7sgGRG5W0K2c8Qe" onVerify={this.onRecaptchaVerify} ref={e => (this.captcha = e)} />
                                    </div>

                                    <Button
                                        className="landing-btn mt-2 w-100"
                                        onClick={this.handlerUserSignUp}
                                        disabled={
                                            (
                                                !(this.state.isValidEmail && this.props.userEmail) ||
                                                !(this.state.isValidPassword && this.props.userPassword) ||
                                                !(this.state.isConfirm && this.props.confirmPassword) ||
                                                !this.state.recaptchaVerified
                                            )
                                        }
                                        style={{
                                            cursor: !(this.state.isValidEmail && this.props.userEmail) ||
                                            !(this.state.isValidPassword && this.props.userPassword) ||
                                            !(this.state.isConfirm && this.props.confirmPassword) ||
                                            !this.state.recaptchaVerified ? 'not-allowed' : 'pointer'
                                        }}
                                    >
                                       Manual Sign up
                                    </Button>
                                    <div className="buttons-wrapper justify-content-center w-100">
                                        <p>Already have an account? {' '}
                                        <span><Link to="/login" className="signup-text">Log In</Link></span></p>		
                                    </div>
                                </Form>
                                <div className="comment-text">
                                <div className='terms-and-privacy-text'>
                                    By signing up you agree to GradShack’s{' '}
                                    <span onClick={this.privacyModalToggle}>
                                        Privacy
                                    </span> 
                                    <strong> & </strong> 
                                    <span onClick={this.termsModalToggle}>Terms</span>
                                    
                                </div>
                                <div className='comments'>
                                    Comments? Questions? Email us at{' '}
                                    <a href="mailto:contact@gradshack.com">contact@gradshack.com</a>
                                </div>
                                </div>
                            </div>
                        </div>

                </div>
                <PrivacyModal
                    isOpen={this.state.privacyModal}
                    toggle={this.privacyModalToggle}
                />
                <TermsModal
                    isOpen={this.state.termsModal}
                    toggle={this.termsModalToggle}
                />
                {this.props.redirectUrl ? <Redirect to={this.props.redirectUrl} /> : null}
            </React.Fragment>
        )
    }
}
