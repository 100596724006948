import React, { Component } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import UserFormButtons from "../.././../containers/userForms/UserFormButtons";
import { needTodayList } from "../../../constants/needTodayList";
import { investorAdvisorList } from "../../../constants/investorAdvisorList";
import { venturesList } from "../../../constants/venturesList";
import NeedsItem from "../../../containers/userForms/NeedsItem";
// import ToastErrorMsg from '../../toastErrorMsg/ToastErrorMsg';

class YourVentureForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tempVenture: "",
            tempInvestorType: "",
            needsList: [],
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { currentFormTab, concept, venture, needs } = props;
        if (currentFormTab === "4") {
            if (concept && venture && needs) {
                props.setFieldsMissing(undefined);
            } else if (!concept) {
                props.setFieldsMissing("My Concept");
            } else if (!venture) {
                props.setFieldsMissing("This venture is currently");
            } else if (needs?.length === 0) {
                props.setFieldsMissing("What I need today");
            }
        }
        return {};
    }

    handleConceptChange = (e) => {
        this.props.setConcept(e.target.value);
    };

    handleVentureChange = (value) => {
        this.setState({ tempVenture: value });
        this.props.setVenture(value);
    };

    handleInvestorTypeSet = (value) => {
        this.setState({ tempInvestorType: value });
        this.props.setInvestorType(value);
    };

    handleFeasibilityStudyAmount = (e) => {
        this.props.setFeasibilityStudyAmount(e.target.value);
    };

    handleAdditionalDetailsSet = (e) => {
        this.props.setAdditionalDetails(e.target.value);
    };

    handleNeedsChanged = (e) => {
        const needId = e.target.id;
        const isNeedChecked = e.target.checked;
        let needsList = this.state.needsList;

        if (isNeedChecked === true) {
            if(!this.state?.needsList?.includes(needId)) {
                needsList = needsList?.concat(needId);
            } 
        }
        
        const index = this.state.needsList.indexOf(needId);
        
        if (isNeedChecked === false && index > -1) {
            needsList = needsList?.filter((item) => item !== needId);
            // needsList.splice(index, 1);
        }
        
        this.setState((state) => {
            this.props.setNeeds(needsList);
        
            return {
                needsList,
                tempVenture: state?.tempVenture,
            };
        });
    };

    render() {
        return (
            <div className="form-wrapper form-wrapper-user-info">
                {/* <ToastErrorMsg fieldsMissing={fieldsMissing} customClass="userinfo-toast"/> */}
                <Form className="form-container">
                    <FormGroup>
                        <Label>My Concept</Label>
                        <textarea
                            type="textarea"
                            name="text"
                            placeholder="Write here"
                            rows="4"
                            cols="50"
                            maxLength="90"
                            onChange={this.handleConceptChange}
                        />
                        <span className="note-text">Maximum 90 Characters</span>
                    </FormGroup>
                    <FormGroup>
                        <Label>This venture is currently</Label>
                        <ul className="venture-options-wrap">
                            {venturesList.map((venture, index) => (
                                <li
                                    key={index}
                                    className={`venture-option ${
                                        this.state.tempVenture === venture.attributes.id ? "venture-selected" : ""
                                    }`}
                                    onClick={() => this.handleVentureChange(venture.attributes.id)}
                                >
                                    <img src={venture.attributes.image} alt="no_image" />
                                    <span>{venture.attributes.name}</span>
                                </li>
                            ))}
                        </ul>
                    </FormGroup>
                    <FormGroup className="multi-select ">
                        <Label>What I need today</Label>
                        <ul>
                            {needTodayList.map((need, index) => (
                                <NeedsItem
                                    key={index}
                                    index={index}
                                    need={need}
                                    isFeasibilityStudySelected={
                                        this.state.needsList.findIndex(
                                            (selectedNeed) => selectedNeed === "feasibility_study"
                                        ) > -1
                                    }
									isInvestorAdvisorSelected={
                                        this.state.needsList.findIndex(
                                            (selectedNeed) => selectedNeed === "advisor"
                                        ) > -1
									}
                                    handleFeasibilityStudyAmount={this.handleFeasibilityStudyAmount}
                                    handleNeedsChanged={this.handleNeedsChanged}
                                />
                            ))}
                        </ul>
                    </FormGroup>
                    <FormGroup>
                        {/* {this.state.needsList.findIndex((selectedNeed) => selectedNeed === "advisor") > -1 && (
                            <ul className="venture-options-wrap funding-wrap">
                                {investorAdvisorList.map((investorType, index) => (
                                    <li
                                        key={index}
                                        className={`venture-option ${
                                            this.state.tempInvestorType === investorType.attributes.name
                                                ? "venture-selected"
                                                : ""
                                        }`}
                                        onClick={() => this.handleInvestorTypeSet(investorType.attributes.name)}
                                    >
                                        <span>{investorType.attributes.name}</span>
                                    </li>
                                ))}
                            </ul>
                        )} */}
                    </FormGroup>
                    {/* <FormGroup className="mt-5">
                        <Label>Additional Details</Label>
                        <Input
                            type="text"
                            name="additionaldetails"
                            maxLength="90"
                            placeholder="Write here"
                            onChange={this.handleAdditionalDetailsSet}
                        />
                        <span className="note-text">Maximum 90 characters</span>
                    </FormGroup> */}
                    <UserFormButtons 
                        history={this.props.history} 
                        setCurrentFormTab={this.props.setCurrentFormTab} 
                        showLinkedInDetails={this.props.showLinkedInDetails}
                        setShowLinkedInDetails={this.props.setShowLinkedInDetails} />
                </Form>
            </div>
        );
    }
}

export default YourVentureForm;
