import { connect } from 'react-redux';
import { createNamespacer } from '../utils/reducers';
import { USERTYPE } from '../constants/namespacer';
import { getUserData } from '../api/commonApi/getUserData'
import Usertype from '../components/userType/Usertype';

const userTypeNamespacer = createNamespacer(USERTYPE);

const mapStateToProps = (state) => {
    return {
        userType: state.userType.userType,
        isInvestor: state.userType.isInvestor,
        isTokenValid: state.redirectAfterLogin.isTokenValid,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        setUserType: (value) => {
            dispatch({
                type: userTypeNamespacer('SET_USERTYPE_VALUE'),
                userType: value
            })
        },
        setIsInvestor: (value) => {
            dispatch({
                type: userTypeNamespacer('SET_IS_INVESTOR_VALUE'),
                isInvestor: value,
            });
        },
        getUserData: async() => {
            try{
                let response  = await getUserData();
                if(response.status===200){
                    
                } 
            }
            catch(error){
                console.log(error);
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Usertype);
