import { connect } from 'react-redux';
import { getFavorite } from '../api/favorites';
import Favourites from '../components/favourites/Favourites';
import { createNamespacer } from '../utils/reducers';
import { HOMEPAGE} from '../constants/namespacer';

const homepageNamespacer = createNamespacer(HOMEPAGE);

const mapStateToProps = (state) => {
    return {
        isTokenValid: state.redirectAfterLogin.isTokenValid,
        favoriteUsersList: state.favourites.favoriteUsersList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getFavoriteList: async () => {
            try {
                let response = await getFavorite();
                let favoriteUsersList = response.data.data.map((favoriteUser) => {
                    return{
                        userId: favoriteUser.relationships.favourite.data.id,
                        favouriteId: favoriteUser.id,
                        notes: favoriteUser.attributes.notes,
                    }
                })
                dispatch({
                    type: homepageNamespacer('SET_FAVORITE_USER_LEN'),
                    favoriteUsersListLength: favoriteUsersList.length,
                })
                dispatch({
                    type: homepageNamespacer('SET_FAVORITE_USER_LIST'),
                    favoriteUsersList: favoriteUsersList,
                })
            }
            catch (error) {
                console.log(error)
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Favourites);

