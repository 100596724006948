import { createReducer, createNamespacer } from '../utils/reducers';
import { HOMEPAGE } from '../constants/namespacer';

const initialState = {
    favoriteUsersList: [],
    favoriteUsersListLength: 0,
}

const namespacer = createNamespacer(HOMEPAGE);

const handlers = {
    [namespacer('SET_FAVORITE_USER_LIST')]: (state, action) => {
        return{
            ...state,
            favoriteUsersList: action.favoriteUsersList,
        }
    },
    [namespacer('SET_FAVORITE_USER_LEN')]: (state, action) => {
        return{
            ...state,
            favoriteUsersListLength: action.favoriteUsersListLength,
        }
    },
}

const favoriteReducer = createReducer(initialState, handlers, [HOMEPAGE]);

export default favoriteReducer;