import React, { Component } from "react";
import DesktopHeader from "../../containers/homePage/DesktopHeader";
import MobileMenu from "../../containers/homePage/MobileMenu";

class FAQPage extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <DesktopHeader />
        <div className="faq-wrapper screen-height">
          <div className="edit-details-container about-us-subheader">
            <span>Who we are</span>
          </div>
          <div className="custom-profile-container faq-container">
            <div className="faq-content">
              <h4 className="faq-subtitle">Who runs GradShack?</h4>
              <p className="faq-desc">
                GradShack is managed by the principals of Collaborative Capital,
                LLC, a New York based investment firm. The activities of
                GradShack are separate from those of Collaborative, though
                Collaborative is not precluded from investing in ventures
                arising from the GradShack platform.
              </p>
            </div>
            <div className="faq-content">
              <h4 className="faq-subtitle">
                Are there any costs I should be aware of?
              </h4>
              <p className="faq-desc">
                GradShack does not charge fees or request ownership.
              </p>
            </div>
            <div className="faq-content">
              <h4 className="faq-subtitle">What’s in it for GradShack?</h4>
              <p className="faq-desc">
                Some combination of intellectual stimulation, network-building
                and perhaps investment opportunity. It is difficult to predict
                with much precision, but if we succeed in establishing an
                ecosystem that unearths entrepreneurial talent, fosters
                intergenerational collaboration, enhances alumni engagement and
                supports transformational ventures, we are confident that good
                things will come.
              </p>
            </div>
            <div className="faq-content">
              <h4 className="faq-subtitle">
                Is GradShack officially endorsed by the schools?
              </h4>
              <p className="faq-desc">
                We have not sought any official partnerships with schools,
                though we enjoy collaborating with them. GradShack is an
                independent platform.
              </p>
            </div>
          </div>
        </div>
        <MobileMenu />
      </React.Fragment>
    );
  }
}

export default FAQPage;
