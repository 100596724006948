export const newPostTypeList = [
    {
        "attributes" :{ id: 'fullTimeRoles', name: "Full-time role" }
    },
    {
        "attributes" :{ id: 'feasibilityStudy', name: "Feasibility study" }
    },
    {
        "attributes" :{ id: 'investingAdvising', name: "Advising" }
    },
    {
        "attributes" :{ id: 'mvp', name: "MVP" }
    },
    {
        "attributes" :{ id: 'betaTests', name: "Beta tests" }
    },
];