import React, { Component } from "react";
import Select from "react-select";
import cx from "classnames";

class Selector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
            isMulti: false,
            optionList: [],
            edit: false,
        };
        this.needOptionList = null;
    }

    componentWillReceiveProps(nextProps) {
        this.getOptionList(nextProps.options);
    }

    handleChange = selectedOption => {
        this.setState({ selectedOption });
        const {
            functionToBeUsed,
            setColOrUniv,
            setGraduationYear,
			setMbaProgram,
            setCurrentWorkLocation,
            setNeeds,
            setIsMulti
        } = this.props;

        if(setIsMulti){
            this.setState({isMulti: true})
        }

        functionToBeUsed === "setColOrUniv" &&
            setColOrUniv(selectedOption.value);
        functionToBeUsed === "setGraduationYear" &&
            setGraduationYear(selectedOption.value);
        functionToBeUsed === "setCurrentWorkLocation" &&
            setCurrentWorkLocation(selectedOption.value);
        functionToBeUsed === 'setNeeds' &&
            setNeeds(selectedOption.value);
		functionToBeUsed === 'setMbaProgram' &&
			setMbaProgram(selectedOption.value);
    };

    handleInputChange = (e) => {
        this.setState({edit: true})
        const { functionToBeUsed } = this.props;
        if(functionToBeUsed==='setColOrUniv') {
            this.props.getUniversityListPrediction(e);
            this.getOptionList(this.props.universityList)
        }
        else if(functionToBeUsed==='setCurrentWorkLocation'){
            this.props.getLocationListPrediction(e);
            this.getOptionList(this.props.workLocationList)
        }
    }

    getOptionList = (options, functionToBeUsed) => {
        if(options){
            let optionList = options.map(option => {
                const { id, name, code } = option.attributes;
                if(code){
                    return{
                        value: id,
                        label: `${name}, ${code}`,
                    }
                }
                else{
                    return{
                        value: id,
                        label: name,
                    }
                }
            })
            if(functionToBeUsed === 'setNeeds'){
                this.needOptionList = optionList;
            }
            else
            this.setState({optionList});
        }
    }

    render() {
        const { selectedOption, edit } = this.state;
        const { functionToBeUsed, defaultValue } = this.props;

        if(functionToBeUsed==='setNeeds'){
            this.getOptionList(this.props.options, 'setNeeds');
        }

        const optionsList = functionToBeUsed === 'setNeeds' ? this.needOptionList : this.state.optionList;

        let defaultSelectValue;

        if(defaultValue){
            if(functionToBeUsed==='setColOrUniv'){
                defaultSelectValue={
                    value: defaultValue.id,
                    label: defaultValue.name
                }
            }
            else if(functionToBeUsed==='setGraduationYear'){
                defaultSelectValue={
                    value: defaultValue,
                    label: defaultValue
                }
            }
            else if(functionToBeUsed==='setCurrentWorkLocation'){
                defaultSelectValue={
                    value: defaultValue.id,
                    label: `${defaultValue.name}, ${defaultValue.code}`
                }
            }
            else if(functionToBeUsed==='setNeeds'){
                let labelUppercase = defaultValue.length ? defaultValue[0].split('_').join(' ') : '';
                labelUppercase = defaultValue.length ? `${labelUppercase.charAt(0).toUpperCase()}${labelUppercase.substring(1)}` : ''
                defaultSelectValue = {
                    value: defaultValue,
                    label: labelUppercase
                }
            }
			else if(functionToBeUsed==='setMbaProgram'){
                defaultSelectValue={
                    value: defaultValue,
                    label: defaultValue
                }
            }
        }
        else{
            defaultSelectValue='';
        }

        let selectedValue = edit && selectedOption ? selectedOption : defaultSelectValue
        const { className } = this.props

        if(functionToBeUsed==='setColOrUniv' && this.props.selectorFor==='ExistingUserFilters'){
            const { colOrUniv } = this.props;
            if(!colOrUniv){
                selectedValue= null
            }
        }

        return (
            <div>
                <Select
                    classNamePrefix="select-dropdown"
                    onChange={this.handleChange}
                    options={optionsList}
                    isMulti={this.state.isMulti}
                    onInputChange={this.handleInputChange}
                    value={selectedValue}
                    className={cx(className)}
                />
            </div>
        );
    }
}

export default Selector;
