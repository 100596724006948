import { createReducer, createNamespacer } from '../utils/reducers';
import { HOMEPAGE, FILTERS } from '../constants/namespacer';

const initialState = {
    selectedFilterList: [],
    selectAll: true,
};

const namespacer = createNamespacer(HOMEPAGE);
const filterUsersNamespacer = createNamespacer(FILTERS);

const handlers = {
    [namespacer('SET_SELECTED_FILTER_LIST')]: (state, action) => {
        return{
            ...state,
            selectedFilterList: action.selectedFilterList,
        }
    },
    [filterUsersNamespacer('SET_SELECT_ALL')]: (state, action) => {
        return{
            ...state,
            selectAll: action.selectAll,
        }
    },
};

const filtersReducer = createReducer(initialState, handlers, [HOMEPAGE, FILTERS]);

export default filtersReducer;