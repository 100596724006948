import React, { Component } from "react";
import logo from "../../commons/images/logo.svg";
import { Form, FormGroup, Label, Input, Button} from "reactstrap";
import { Redirect } from 'react-router-dom'

class AdminLogin extends Component {

    handleAdminEmail = (event) => {
        this.props.setAdminEmail(event.target.value);
    }
    
    handleAdminPassword = (event) => {
        this.props.setAdminPassword(event.target.value);
    }
    
    handleAdminLogin = (event) => {
        const { adminEmail, adminPassword } = this.props;
        if(adminEmail && adminPassword){
            this.props.adminLogin(adminEmail, adminPassword);
        }
        else{
            alert('Enter Email and Password')
        }
    }
    
    render() {
        const { isTokenValid, isAdmin } = this.props;
        return (
            <div className="admin-login-page">
                <div className="admin-login-wrapper">
                    <img src={logo} alt="no_image"/>
                    <div className="admin-login-container">  
                        <Form className="form-container">
                            <FormGroup>
                                <Label>Enter Email</Label>
                                <Input
                                    type="email"
                                    name="email"
                                    onChange={this.handleAdminEmail}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Password</Label>
                                <Input
                                    type="password"
                                    name="password"
                                    onChange={this.handleAdminPassword}
                                />
                            </FormGroup>
                            <Button
                                className="landing-btn"
                                onClick={this.handleAdminLogin}
                            >Login</Button>
                        </Form>
                    </div>
                </div>
                { isAdmin && isTokenValid ? <Redirect to='/admin/pendingAlumni' /> : null}
            </div>
        );
    }
}

export default AdminLogin;