import React, { Component } from "react";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import NumberFormat from 'react-number-format';
import FeasibilityModal from "../../../staticPages/feasibilityModal";
import questionMark from '../../../../commons/images/Icon-round-question_mark.svg'

class NeedsItem extends Component {
  constructor(props) {
    super(props);
	this.state = {
		feasibilityModal: false
	}
	this.feasibilityModalToggle = this.feasibilityModalToggle.bind(this);
  }
  feasibilityModalToggle() {
	this.setState({
		feasibilityModal: !this.state.feasibilityModal,
	});
  }

  render() {
    const {
      index,
      need,
      isFeasibilityStudySelected,
	  isInvestorAdvisorSelected,
      checked,
      feasibilityStudyAmount,
    } = this.props;
    return (
      <li className="needs-item">
        <div
          className={`additional-information privacy-link-in-user justify-content-start ${
            index === 0 ? "mt-0" : ""
          }`}
        >
          <Input
            type="checkbox"
            id={need.attributes.id}
            checked={checked}
            onClick={this.props.handleNeedsChanged}
          />
          <label>{need.attributes.name}</label>
        </div>
        {isFeasibilityStudySelected &&
          need.attributes.id === "feasibility_study" && (
            <div>
              <div className="mt-4 what-i-need-sub-box">
                <label
                  htmlFor="feasibilityStudyAmount"
                  className="d-flex justify-content-between"
                >
                  <span>Suggested compensation for feasibility study:
					<span className="feasibility-modal" onClick={this.feasibilityModalToggle}><img src={questionMark} /></span> 
				  </span>
                </label>
                <InputGroup size="lg">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>$</InputGroupText>
                  <NumberFormat
                    id="feasibilityStudyAmount"
                    className="feasibility-study-amount"
                    placeholder="Amount"
                    type="tel"
                    value={feasibilityStudyAmount}
					thousandSeparator={','}
					isNumericString={true}
                    onChange={this.props.handleFeasibilityStudyAmount}
                    max={9999999999}
                    maxLength={12}
                  />
                    </InputGroupAddon>
                </InputGroup>
				<FeasibilityModal isOpen={this.state.feasibilityModal} toggle={this.feasibilityModalToggle} />
              </div>
            </div>
          )}
        {/* {isInvestorAdvisorSelected &&
          need.attributes.id === "advisor" && (
            <div>
              <div className="mt-4 what-i-need-sub-box">
                <label
                  className="d-flex justify-content-between"
                >
                  <span>Funding stage:</span>
                </label>
              </div>
            </div>
          )} */}
      </li>
    );
  }
}

export default NeedsItem;
