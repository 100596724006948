import axiosInstance from '.';

export const registerPushyDeviceToken = (token) => {
    return axiosInstance()({
        method: 'GET',
        url: `/device/${token}`,
    })
};

export const onAddCommentSuccess = (params) => {
    return axiosInstance()({
        method: 'GET',
        url: `/comments/notify_user`,
        params,
    })
}
